import React from 'react';
import EventBus from 'eventbusjs';

import ProductModal from './ProductModal';
import TestKitModal from './TestKitModal';
import UserModal from './UserModal';
import RoleModal from './RoleModal';
import CustomerTestModal from './CustomerTestModal';
import TagModal from './TagModal';
import ConfirmationModal from './ConfirmationModal';
import AddPromoBundleOrderModal from './AddPromoBundleOrderModal';
import AddPromoKitsOrderModal from './AddPromoKitsOrderModal';
import EnterEnvDataModal from './EnterEnvDataModal';
import EnterSubscriptionDataModal from './EnterSubscriptionDataModal';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import ReassignTestKitModal from './ReassignTestKitModal';
import TiktokUrlModal from './TiktokUrlModal';
import ExportOptionsModal from './ExportOptionsModal';

import {
  MODAL_TRANSITION_DURATION, DISPLAY_PRODUCT_MODAL, DISPLAY_TEST_KIT_MODAL,
  DISPLAY_USER_MODAL, DISPLAY_CUSTOMER_TEST_MODAL, DISPLAY_TAG_MODAL,
  DISPLAY_CONFIRMATION_MODAL, DISPLAY_ADD_PROMO_BUNDLE_ORDER_MODAL,
  DISPLAY_ADD_PROMO_KITS_ORDER_MODAL,
  DISPLAY_ENTER_ENV_DATA_MODAL, DISPLAY_ENTER_SUBSCRIPTION_DATA_MODAL,
  DISPLAY_CANCEL_SUBSCRIPTION_MODAL, DISPLAY_ROLE_MODAL, DISPLAY_REASSIGN_TEST_KIT_MODAL,
  DISPLAY_TIKTOK_URL_MODAL, DISPLAY_EXPORT_OPTIONS_MODAL,
} from '../../constants';

const defaultState = {
  shouldRenderProductModal: false,
  shouldRenderTestKitModal: false,
  shouldRenderReassignTestKitModal: false,
  shouldRenderUserModal: false,
  shouldRenderRoleModal: false,
  shouldRenderCustomerTestModal: false,
  shouldRenderTagModal: false,
  shouldRenderConfirmationModal: false,
  shouldRenderAddPromoBundleOrderModal: false,
  shouldRenderAddPromoKitsOrderModal: false,
  shouldRenderEnterEnvDataModal: false,
  shouldRenderEnterSubscriptionDataModal: false,
  shouldRenderCancelSubscriptionModal: false,
  shouldRenderTiktokUrlModal: false,
  shouldRenderExportOptionsModal: false,
  question: '',
  additionalData: {},
};

class ModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.displayProductModal = this.displayProductModal.bind(this);
    this.processCloseProductModal = this.processCloseProductModal.bind(this);

    this.displayTestKitModal = this.displayTestKitModal.bind(this);
    this.processCloseTestKitModal = this.processCloseTestKitModal.bind(this);

    this.displayUserModal = this.displayUserModal.bind(this);
    this.processCloseUserModal = this.processCloseUserModal.bind(this);

    this.displayRoleModal = this.displayRoleModal.bind(this);
    this.processCloseRoleModal = this.processCloseRoleModal.bind(this);

    this.displayCustomerTestModal = this.displayCustomerTestModal.bind(this);
    this.processCloseCustomerTestModal = this.processCloseCustomerTestModal.bind(this);

    this.modalToggle = this.modalToggle.bind(this);
    this.stateToDefault = this.stateToDefault.bind(this);

    this.displayTagModal = this.displayTagModal.bind(this);
    this.processCloseTagModal = this.processCloseTagModal.bind(this);

    this.displayConfirmationModal = this.displayConfirmationModal.bind(this);
    this.processCloseConfirmationModal = this.processCloseConfirmationModal.bind(this);

    this.displayAddPromoBundleOrdernModal = this.displayAddPromoBundleOrdernModal.bind(this);
    this.processCloseAddPromoBundleOrderModal = this.processCloseAddPromoBundleOrderModal.bind(this);

    this.displayAddPromoKitsOrdernModal = this.displayAddPromoKitsOrdernModal.bind(this);
    this.processCloseAddPromoKitsOrderModal = this.processCloseAddPromoKitsOrderModal.bind(this);

    this.displayEnterEnvDataModal = this.displayEnterEnvDataModal.bind(this);
    this.processCloseEnterEnvDataModal = this.processCloseEnterEnvDataModal.bind(this);

    this.displayEnterSubscriptionDataModal = this.displayEnterSubscriptionDataModal.bind(this);
    this.processCloseEnterSubscriptionDataModal = this.processCloseEnterSubscriptionDataModal.bind(this);

    this.displayCancelSubscriptionModal = this.displayCancelSubscriptionModal.bind(this);
    this.processCloseCancelSubscriptionModal = this.processCloseCancelSubscriptionModal.bind(this);

    this.displayReassignTestKitModal = this.displayReassignTestKitModal.bind(this);
    this.processCloseReassignTestKitModal = this.processCloseReassignTestKitModal.bind(this);

    this.displayTiktokUrlModal = this.displayTiktokUrlModal.bind(this);
    this.processCloseTiktokUrlModal = this.processCloseTiktokUrlModal.bind(this);

    this.displayExportOptionsModal = this.displayExportOptionsModal.bind(this);
    this.processCloseExportOptionsModal = this.processCloseExportOptionsModal.bind(this);

    this.state = defaultState;
  }

  // Did Mount
  componentDidMount() {
    EventBus.addEventListener(
      DISPLAY_PRODUCT_MODAL,
      this.displayProductModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_TEST_KIT_MODAL,
      this.displayTestKitModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_REASSIGN_TEST_KIT_MODAL,
      this.displayReassignTestKitModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_USER_MODAL,
      this.displayUserModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_ROLE_MODAL,
      this.displayRoleModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_CUSTOMER_TEST_MODAL,
      this.displayCustomerTestModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_TAG_MODAL,
      this.displayTagModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_CONFIRMATION_MODAL,
      this.displayConfirmationModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_ADD_PROMO_BUNDLE_ORDER_MODAL,
      this.displayAddPromoBundleOrdernModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_ADD_PROMO_KITS_ORDER_MODAL,
      this.displayAddPromoKitsOrdernModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_ENTER_ENV_DATA_MODAL,
      this.displayEnterEnvDataModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_ENTER_SUBSCRIPTION_DATA_MODAL,
      this.displayEnterSubscriptionDataModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_CANCEL_SUBSCRIPTION_MODAL,
      this.displayCancelSubscriptionModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_TIKTOK_URL_MODAL,
      this.displayTiktokUrlModal,
      this,
    );
    EventBus.addEventListener(
      DISPLAY_EXPORT_OPTIONS_MODAL,
      this.displayExportOptionsModal,
      this,
    );
  }

  // Unmount
  componentWillUnmount() {
    EventBus.removeEventListener(
      DISPLAY_PRODUCT_MODAL,
      this.displayProductModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_TEST_KIT_MODAL,
      this.displayTestKitModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_REASSIGN_TEST_KIT_MODAL,
      this.displayReassignTestKitModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_USER_MODAL,
      this.displayUserModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_ROLE_MODAL,
      this.displayRoleModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_CUSTOMER_TEST_MODAL,
      this.displayCustomerTestModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_TAG_MODAL,
      this.displayTagModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_CONFIRMATION_MODAL,
      this.displayConfirmationModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_ADD_PROMO_BUNDLE_ORDER_MODAL,
      this.displayAddPromoBundleOrdernModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_ADD_PROMO_KITS_ORDER_MODAL,
      this.displayAddPromoKitsOrdernModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_ENTER_ENV_DATA_MODAL,
      this.displayEnterEnvDataModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_ENTER_SUBSCRIPTION_DATA_MODAL,
      this.displayEnterSubscriptionDataModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_CANCEL_SUBSCRIPTION_MODAL,
      this.displayCancelSubscriptionModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_TIKTOK_URL_MODAL,
      this.displayTiktokUrlModal,
      this,
    );
    EventBus.removeEventListener(
      DISPLAY_EXPORT_OPTIONS_MODAL,
      this.displayExportOptionsModal,
      this,
    );
  }

  // Display
  displayProductModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderProductModal: true,
    }, () => this.modalToggle('#ProductModal'));
  }

  displayTestKitModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderTestKitModal: true,
    }, () => this.modalToggle('#TestKitModal'));
  }

  displayReassignTestKitModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderReassignTestKitModal: true,
    }, () => this.modalToggle('#ReassignTestKitModal'));
  }

  displayUserModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderUserModal: true,
    }, () => this.modalToggle('#UserModal'));
  }

  displayRoleModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderRoleModal: true,
    }, () => this.modalToggle('#RoleModal'));
  }

  displayCustomerTestModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderCustomerTestModal: true,
    }, () => this.modalToggle('#CustomerTestModal'));
  }

  displayTagModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderTagModal: true,
    }, () => this.modalToggle('#TagModal'));
  }

  displayConfirmationModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      question: data.question,
      additionalData: data.additionalData,
      shouldRenderConfirmationModal: true,
    }, () => this.modalToggle('#confirmationModal'));
  }

  displayAddPromoBundleOrdernModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderAddPromoBundleOrderModal: true,
    }, () => this.modalToggle('#addPromoBundleOrderModal'));
  }

  displayAddPromoKitsOrdernModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderAddPromoKitsOrderModal: true,
    }, () => this.modalToggle('#addPromoKitsOrderModal'));
  }

  displayEnterEnvDataModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderEnterEnvDataModal: true,
    }, () => this.modalToggle('#enterEnvDataModal'));
  }

  displayEnterSubscriptionDataModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderEnterSubscriptionDataModal: true,
    }, () => this.modalToggle('#enterSubscriptionDataModal'));
  }

  displayCancelSubscriptionModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderCancelSubscriptionModal: true,
    }, () => this.modalToggle('#cancelSubscriptionModal'));
  }

  displayTiktokUrlModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      additionalData: data.additionalData,
      shouldRenderTiktokUrlModal: true,
    }, () => this.modalToggle('#TiktokUrlModal'));
  }

  displayExportOptionsModal(target, data) {
    this.setState({
      handleSubmit: data.handleSubmit,
      shouldRenderExportOptionsModal: true,
    }, () => this.modalToggle('#exportOptionsModal'));
  }

  // After close action
  processCloseProductModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      title: e.target.elements.title.value,
      sku: e.target.elements.sku.value,
    };
    this.modalToggle('#ProductModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseTestKitModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      product_id: e.target.elements.productId.value,
      status: e.target.elements.status.value,
    };
    this.modalToggle('#TestKitModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseReassignTestKitModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      customer_id: e.target.customer_id.value,
      customer_test_status: e.target.customer_test_status.value,
    };
    this.modalToggle('#ReassignTestKitModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseUserModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      email: e.target.elements.email.value,
      first_name: e.target.elements.first_name.value,
      last_name: e.target.elements.last_name.value,
      title: e.target.elements.title.value,
      status: e.target.elements.status.value,
      role_id: e.target.elements.role_id && e.target.elements.role_id.value.length ? Number(e.target.elements.role_id.value) : undefined,
      password: e.target.elements.password ? e.target.elements.password.value : undefined,
    };
    this.modalToggle('#UserModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseRoleModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const permissions = $('#RoleModal input:checkbox:checked').map((i, item) => $(item).attr('name')).get();
    const data = {
      name: e.target.name.value,
      permissions: JSON.stringify(permissions),
    };
    this.modalToggle('#RoleModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseCustomerTestModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const images = e.target.elements.cuticle_images.value;
    const data = {
      test_kit_id: e.target.elements.testKitId.value,
      customer_id: e.target.elements.customerId.value,
      status: e.target.elements.status.value,
      result_avg_protein_loss: e.target.elements.result_avg_protein_loss.value,
      result_avg_hair_diam: e.target.elements.result_avg_hair_diam.value,
      result_avg_sample_weight: e.target.elements.result_avg_sample_weight.value,
      result_avg_microscopic_analysis_score: e.target.elements.result_avg_microscopic_analysis_score.value,
      cuticle_images: images ? images.split(',') : null,
    };
    this.modalToggle('#CustomerTestModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseTagModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      name: e.target.elements.name.value,
      tag_type: e.target.elements.tag_type.value,
    };
    this.modalToggle('#TagModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseConfirmationModal(data) {
    const { handleSubmit } = this.state;
    this.modalToggle('#confirmationModal');
    handleSubmit(data);
    this.stateToDefault();
  }

  processCloseAddPromoBundleOrderModal(e) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      sh_qty: e.target.qty.value,
      co_qty: e.target.qty.value,
      tk_qty: e.target.qty.value,
    };
    this.modalToggle('#addPromoBundleOrderModal');
    handleSubmit(data);
    this.stateToDefault();
  }

  processCloseAddPromoKitsOrderModal(e) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      sh_qty: '0',
      co_qty: '0',
      tk_qty: e.target.qty.value,
    };
    this.modalToggle('#addPromoKitsOrderModal');
    handleSubmit(data);
    this.stateToDefault();
  }

  processCloseEnterEnvDataModal(e) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      humidity: e.target.humidity.value,
      uv: e.target.uv.value,
      air_pollution: e.target.air_pollution.value,
      water_hardness: e.target.water_hardness.value,
    };
    this.modalToggle('#enterEnvDataModal');
    handleSubmit(data);
    this.stateToDefault();
  }

  processCloseEnterSubscriptionDataModal(e) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      sh_qty: e.target.sh_qty.value,
      co_qty: e.target.co_qty.value,
      discount: e.target.discount.value,
      period: e.target.period.value,
      reuse_pumps: e.target.reuse_pumps.checked,
      next_billing_date: new Date(e.target.next_billing_date.value),
    };
    this.modalToggle('#enterSubscriptionDataModal');
    handleSubmit(data);
    this.stateToDefault();
  }

  processCloseCancelSubscriptionModal(data) {
    const { handleSubmit } = this.state;
    this.modalToggle('#cancelSubscriptionModal');
    handleSubmit(data);
    this.stateToDefault();
  }

  processCloseTiktokUrlModal(e, additionalData) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      url: e.target.url.value,
      author_name: e.target.author_name.value,
      author_handle: e.target.author_handle.value,
      brand: e.target.brand.value,
    };
    this.modalToggle('#TiktokUrlModal');
    handleSubmit(data, additionalData.id);
    this.stateToDefault();
  }

  processCloseExportOptionsModal(e) {
    e.preventDefault();
    const { handleSubmit } = this.state;
    const data = {
      start_date: new Date(e.target.start_date.value),
      end_date: new Date(e.target.end_date.value),
    };
    this.modalToggle('#exportOptionsModal');
    handleSubmit(data);
    this.stateToDefault();
  }

  // other functions
  modalToggle(id) {
    $(id).modal('toggle');
  }

  stateToDefault() {
    setTimeout(() => {
      this.setState(defaultState);
    }, MODAL_TRANSITION_DURATION);
  }

  render() {
    const {
      additionalData, shouldRenderProductModal, shouldRenderTestKitModal, shouldRenderReassignTestKitModal,
      shouldRenderUserModal, shouldRenderRoleModal, shouldRenderCustomerTestModal, shouldRenderTagModal,
      shouldRenderConfirmationModal, shouldRenderAddPromoBundleOrderModal, shouldRenderEnterEnvDataModal,
      shouldRenderAddPromoKitsOrderModal,
      shouldRenderEnterSubscriptionDataModal, shouldRenderCancelSubscriptionModal, shouldRenderTiktokUrlModal,
      shouldRenderExportOptionsModal,
      question,
    } = this.state;

    return (
      <div data-testid="modal-container">
        {shouldRenderProductModal && (
          <ProductModal
            handleSubmit={this.processCloseProductModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderTestKitModal && (
          <TestKitModal
            handleSubmit={this.processCloseTestKitModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderReassignTestKitModal && (
          <ReassignTestKitModal
            handleSubmit={this.processCloseReassignTestKitModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderUserModal && (
          <UserModal
            handleSubmit={this.processCloseUserModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderRoleModal && (
          <RoleModal
            handleSubmit={this.processCloseRoleModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
            updateAdditionalData={(newData) => this.setState({ additionalData: newData })}
          />
        )}
        {shouldRenderCustomerTestModal && (
          <CustomerTestModal
            handleSubmit={this.processCloseCustomerTestModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderTagModal && (
          <TagModal
            handleSubmit={this.processCloseTagModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderConfirmationModal && (
          <ConfirmationModal
            handleSubmit={this.processCloseConfirmationModal}
            handleReject={this.stateToDefault}
            question={question}
            additionalData={additionalData}
          />
        )}
        {shouldRenderAddPromoBundleOrderModal && (
          <AddPromoBundleOrderModal
            handleSubmit={this.processCloseAddPromoBundleOrderModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderAddPromoKitsOrderModal && (
          <AddPromoKitsOrderModal
            handleSubmit={this.processCloseAddPromoKitsOrderModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderEnterEnvDataModal && (
          <EnterEnvDataModal
            handleSubmit={this.processCloseEnterEnvDataModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderEnterSubscriptionDataModal && (
          <EnterSubscriptionDataModal
            handleSubmit={this.processCloseEnterSubscriptionDataModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderCancelSubscriptionModal && (
          <CancelSubscriptionModal
            handleSubmit={this.processCloseCancelSubscriptionModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
          />
        )}
        {shouldRenderTiktokUrlModal && (
          <TiktokUrlModal
            handleSubmit={this.processCloseTiktokUrlModal}
            handleReject={this.stateToDefault}
            additionalData={additionalData}
            updateAdditionalData={(newData) => this.setState({ additionalData: newData })}
          />
        )}
        {shouldRenderExportOptionsModal && (
          <ExportOptionsModal
            handleSubmit={this.processCloseExportOptionsModal}
            handleReject={this.stateToDefault}
          />
        )}
      </div>
    );
  }
}

export default ModalContainer;
