import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import Pagination from '../customElements/Pagination';
import api from '../../services/api';

class RecordsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleFetchRecords = this.handleFetchRecords.bind(this);
    this.generateCsvData = this.generateCsvData.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      step: 1,
      records: props.records,
      currentRecord: null,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      filterIsWalmart: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).is_walmart : '',
      filterEmail: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).email : '',
      filterFirstName: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).first_name : '',
      filterLastName: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).last_name : '',
      filter: {},
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  getFields = () => [
    { name: 'id', text: 'ID' },
    { name: 'is_walmart', text: 'Is Walmart' },
    { name: 'email', text: 'Email' },
    { name: 'phone_number', text: 'Phone Number' },
    { name: 'first_name', text: 'First Name' },
    { name: 'last_name', text: 'Last Name' },
    { name: 'zip_code', text: 'Zip Code' },
    { name: 'has_subscribed', text: 'Has Subscribed' },
    { name: 'fragrance_irritation', text: 'Fragrance Irritation' },
    { name: 'fragrance_preference', text: 'Fragrance Free' },
    { name: 'same_fragrance', text: 'Same Fragrance for Lotion' },
    { name: 'wash_experience1', text: 'Body Wash Routine Experience 1' },
    { name: 'wash_experience2', text: 'Body Wash Routine Experience 2' },
    { name: 'wash_scent_type', text: 'Body Wash Scent Type' },
    { name: 'wash_time', text: 'Shower Time' },
    { name: 'lotion_experience1', text: 'Body Lotion Routine Experience 1' },
    { name: 'lotion_experience2', text: 'Body Lotion Routine Experience 2' },
    { name: 'lotion_scent_type', text: 'Body Lotion Scent Type' },
    { name: 'lotion_time', text: 'Lotion Time' },
    { name: 'products', text: 'Products' },
    { name: 'skin_type', text: 'Skin Type' },
    { name: 'skin_tone', text: 'Skin Tone' },
    { name: 'wrinkles', text: 'Wrinkles' },
    { name: 'uneven_texture', text: 'Uneven Texture' },
    { name: 'acne_breakouts', text: 'Acne Breakouts' },
    { name: 'hyperpigmentation', text: 'Hyperpigmentation' },
    { name: 'needs', text: 'Needs' },
    { name: 'skin_concerns', text: 'Skin Concerns' },
    { name: 'skin_conditions', text: 'Skin Conditions' },
    { name: 'skin_conditions_other', text: 'Skin Conditions (Other)' },
    { name: 'formulation_preferences', text: 'Formulation Preferences' },
    { name: 'age', text: 'Age' },
    { name: 'is_thl_interested', text: 'Interested in customized hair care' },
    { name: 'recommendations_satisfied', text: 'Satisfied with recommendations' },
    { name: 'heard_from', text: 'Heard from' },
    { name: 'heard_from_other', text: 'Heard from (other)' },
    { name: 'wash_routine', text: 'Recommended Wash Routine' },
    { name: 'lotion_routine', text: 'Recommended Lotion Routine' },
    { name: 'final_wash_routine', text: 'Final Wash Routine' },
    { name: 'final_lotion_routine', text: 'Final Lotion Routine' },
    { name: 'uuid', text: 'UUID' },
    { name: 'created_at', text: 'Created' },
    { name: 'updated_at', text: 'Updated' },
  ]

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchRecords(null, null, order);
  }

    handleChange = (name) => (event) => {
      this.setState({
        [name]: event.target.value,
      });
    };

    handleAddFilters(e) {
      if (e) e.preventDefault();
      const {
        filterIsWalmart, filterEmail, filterFirstName, filterLastName,
      } = this.state;
      const filter = {};

      if (filterIsWalmart) {
        filter.is_walmart = filterIsWalmart;
      }

      if (filterEmail) {
        filter.email = filterEmail;
      }

      if (filterFirstName) {
        filter.first_name = filterFirstName;
      }

      if (filterLastName) {
        filter.last_name = filterLastName;
      }

      this.setState({ filter }, () => this.handleFetchRecords(1));
    }

    clearFilters() {
      if (this.filtersForm) this.filtersForm.reset();
      this.setState({
        filter: {},
        filterIsWalmart: '',
        filterEmail: '',
        filterFirstName: '',
        filterLastName: '',
      }, () => this.handleFetchRecords(1));
    }

    generateCsvData() {
      const { records } = this.state;
      const csvHeaders = this.getFields().map((f) => f.text);

      return [
        csvHeaders,
        ...records.map((record) => {
          const array = [];
          this.getFields().map((field) => {
            if (Array.isArray(record[field.name])) return array.push(record[field.name].join(','));
            if (['created_at', 'updated_at'].includes(field.name)) return array.push(new Date(record[field.name]).toLocaleString());
            if (field.name === 'has_subscribed') return array.push(record[field.name] ? 'Yes' : 'No');
            if (field.name === 'is_walmart') return array.push(record[field.name] ? 'Yes' : 'No');
            if (field.name === 'is_thl_interested') return array.push(record[field.name] ? 'Yes' : 'No');
            if (field.name === 'recommendations_satisfied') {
              if (record[field.name] === true) array.push('Yes');
              else if (record[field.name] === false) array.push('No');
              return '';
            }
            return array.push(record[field.name]);
          });
          return array;
        }),
      ];
    }

    handleRecordSelect(record) {
      this.setState({ currentRecord: record }, this.handleStepChange);
    }

    handleFetchRecords(newPage, newTab, newOrder) {
      const {
        pagination, lastSortKey, filter,
      } = this.state;
      const page = newPage || (pagination?.currentPage || 1);
      const order = newOrder || lastSortKey;
      window.history.pushState({}, null, `?page=${page}&order=${order}&filter=${JSON.stringify(filter)}`);

      api.tblQuizRecords.fetchRecords(page, order, filter)
        .then((res) => this.setState({
          records: res.records,
          lastSortKey: order,
          pagination: {
            ...pagination,
            currentPage: page,
            isLastPage: res.is_last_page,
          },
        }));
    }

    render() {
      const { canExport } = this.props;
      const {
        step, records, pagination, currentRecord, filterIsWalmart, filterEmail, filterFirstName, filterLastName,
      } = this.state;

      return (
        <div className="card">
          <div className="card-body custom-form-block">
            <button
              type="button"
              id="custom-filters-toggle"
              className="btn btn-primary"
              onClick={() => $('#custom-filters').toggle(100)}
            >
              Toggle Filters
            </button>
            <div id="custom-filters" className="custom-filters-block">
              <div>Filters</div>
              <form
                onSubmit={this.handleAddFilters}
                ref={(form) => {
                  this.filtersForm = form;
                }}
              >
                <div className="form-group">
                  <label htmlFor="filterEmail">Email (startsWith)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="filterEmail"
                    placeholder="abc@gmail.com"
                    value={filterEmail}
                    onChange={this.handleChange('filterEmail')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="filterFirstName">First Name (startsWith)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="filterFirstName"
                    value={filterFirstName}
                    onChange={this.handleChange('filterFirstName')}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="filterLastName">Last Name (startsWith)</label>
                  <input
                    type="text"
                    className="form-control"
                    id="filterLastName"
                    placeholder=""
                    value={filterLastName}
                    onChange={this.handleChange('filterLastName')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="filterIsWalmart">Is Walmart</label>
                  <select
                    className="form-control"
                    id="filterIsWalmart"
                    value={filterIsWalmart}
                    onChange={this.handleChange('filterIsWalmart')}
                  >
                    <option value="">--</option>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </div>
                <div className="btn-group" role="group" aria-label="">
                  <button
                    id="filterSubmit"
                    type="submit"
                    className="btn btn-primary"
                  >
                    Add filters
                  </button>
                  <button
                    id="clearFilterSubmit"
                    type="button"
                    className="btn btn-primary"
                    onClick={this.clearFilters}
                  >
                    Clear filters
                  </button>
                </div>
              </form>
            </div>
            <div className="custom-info-block search-container">
              <div>The Body Lab Records</div>
            </div>
            <div className="custom-stepper">
              <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
                1. Choose record
              </span>
              <span className="custom-stepper-arrow">
                {'>'}
              </span>
              <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
                2. Record details
              </span>
            </div>
            {step === 1 && (
              <>
                {canExport && <CSVLink className="csv-download" data={this.generateCsvData()}>Export as CSV</CSVLink>}
                <div className="table-responsive">
                  <table className="table table-striped table-responsive-sm custom-table">
                    <thead>
                      <tr>
                        <th scope="col" onClick={() => this.handleSort('id')}>ID</th>
                        <th scope="col" style={{ minWidth: '12rem' }} onClick={() => this.handleSort('email')}>Email</th>
                        <th scope="col" style={{ minWidth: '12rem' }} onClick={() => this.handleSort('first_name')}>
                          First
                          Name
                        </th>
                        <th scope="col" style={{ minWidth: '12rem' }} onClick={() => this.handleSort('last_name')}>
                          Last
                          Name
                        </th>
                        <th scope="col" style={{ minWidth: '8rem' }} onClick={() => this.handleSort('zip_code')}>
                          Zip Code
                        </th>
                        <th scope="col" onClick={() => this.handleSort('has_subscribed')}>Has Subscribed</th>
                        <th scope="col" onClick={() => this.handleSort('is_walmart')}>Is Walmart</th>
                        <th
                          scope="col"
                          style={{ minWidth: '13rem' }}
                          onClick={() => this.handleSort('created_at')}
                        >
                          Created
                        </th>
                        <th
                          scope="col"
                          style={{ minWidth: '13rem' }}
                          onClick={() => this.handleSort('updated_at')}
                        >
                          Updated
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((item) => (
                        <tr
                          className="custom-tr-selectable"
                          key={item.id}
                          onClick={() => this.handleRecordSelect(item)}
                        >
                          <th scope="row">{item.id}</th>
                          <td>{item.email || '-'}</td>
                          <td>{item.first_name || '-'}</td>
                          <td>{item.last_name || '-'}</td>
                          <td>{item.zip_code || '-'}</td>
                          <td>{item.has_subscribed ? 'Yes' : 'No'}</td>
                          <td>{item.is_walmart ? 'Yes' : 'No'}</td>
                          <td>{new Date(item.created_at).toLocaleString()}</td>
                          <td>{new Date(item.updated_at).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={pagination.currentPage}
                    isLastPage={pagination.isLastPage}
                    handlePageChange={this.handleFetchRecords}
                  />
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className="info-header">Record info:</div>
                <table className="table-responsive-sm quiz-record-table">
                  <tbody>
                    {this.getFields().map((field) => {
                      if (Array.isArray(currentRecord[field.name])) {
                        return (
                          <tr>
                            <td className="info-label">{field.text}</td>
                            <td>
                              {currentRecord[field.name].length ? currentRecord[field.name].map((item) => (
                                <div>{item}</div>
                              )) : '-'}
                            </td>
                          </tr>
                        );
                      }
                      if (['created_at', 'updated_at'].includes(field.name)) {
                        return (
                          <tr>
                            <td className="info-label">{`${field.text}: `}</td>
                            <td>{new Date(currentRecord[field.name]).toLocaleString()}</td>
                          </tr>
                        );
                      }
                      if (field.name === 'has_subscribed') {
                        return (
                          <tr>
                            <td className="info-label">{`${field.text}: `}</td>
                            <td>{currentRecord[field.name] ? 'Yes' : 'No'}</td>
                          </tr>
                        );
                      }

                      if (field.name === 'is_walmart' || field.name === 'is_thl_interested') {
                        return (
                          <tr>
                            <td className="info-label">{`${field.text}: `}</td>
                            <td>{currentRecord[field.name] ? 'Yes' : 'No'}</td>
                          </tr>
                        );
                      }

                      if (field.name === 'recommendations_satisfied') {
                        return (
                          <tr>
                            <td className="info-label">{`${field.text}: `}</td>
                            {currentRecord[field.name] === true && <td>Yes</td>}
                            {currentRecord[field.name] === false && <td>No</td>}
                            {currentRecord[field.name] !== true && currentRecord[field.name] !== false && <td>-</td>}
                          </tr>
                        );
                      }

                      return (
                        <tr>
                          <td className="info-label">{`${field.text}: `}</td>
                          <td>{currentRecord[field.name] ? currentRecord[field.name] : '-'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-secondary btn-back"
                    onClick={() => this.handleStepChange(-1)}
                  >
                    Back
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
}

RecordsContainer.propTypes = {
  records: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
  canExport: PropTypes.bool,
};

RecordsContainer.defaultProps = {
  records: [],
  isLastPage: false,
  canExport: false,
};

export default RecordsContainer;
