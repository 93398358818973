import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_ROLE_MODAL, DISPLAY_CONFIRMATION_MODAL } from '../../constants';

class RolesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);

    this.handleRoleCreate = this.handleRoleCreate.bind(this);
    this.handleRoleDestroy = this.handleRoleDestroy.bind(this);
    this.handleRoleEdit = this.handleRoleEdit.bind(this);

    this.callRoleModal = this.callRoleModal.bind(this);

    this.state = {
      roles: props.roles,
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Add/edit/delete
  handleRoleCreate(data) {
    const { roles } = this.state;
    api.roles.create(data)
      .then((res) => this.setState({
        roles: [res, ...roles],
      }));
  }

  handleRoleEdit(data, id) {
    const { roles } = this.state;
    api.roles.update(data, id)
      .then((res) => this.setState({
        roles: roles.map((p) => (p.id === res.id ? res : p)),
      }));
  }

  handleRoleDestroy(id) {
    const { roles } = this.state;
    api.roles.destroy(id)
      .then((res) => {
        this.setState({
          roles: roles.filter((p) => p.id !== res.id),
        });
      });
  }

  // Modal
  callRoleModal(additionalData) {
    callEvent(
      DISPLAY_ROLE_MODAL,
      {
        handleSubmit: additionalData.type === 'create'
          ? this.handleRoleCreate : this.handleRoleEdit,
        additionalData,
      },
    );
  }

  render() {
    const { roles } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <div className="custom-table-header">
            <div className="custom-table-header-left">Roles</div>
            <div
              className="custom-table-header-right"
              onClick={() => this.callRoleModal({ type: 'create' })}
            >
              + Add new
            </div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col">Role</th>
                <th scope="col">Permissions</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {roles.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.name}</th>
                  <td>{JSON.parse(item.permissions).join(', ')}</td>
                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.callRoleModal({
                          type: 'update',
                          id: item.id,
                          name: item.name,
                          permissions: JSON.parse(item.permissions),
                        })}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleRoleDestroy(item.id))}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

RolesContainer.propTypes = {
  roles: PropTypes.instanceOf(Array),
};

RolesContainer.defaultProps = {
  roles: [],
};

export default RolesContainer;
