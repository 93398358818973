// Modals
export const DISPLAY_PRODUCT_MODAL = 'DISPLAY_PRODUCT_MODAL';
export const DISPLAY_TEST_KIT_MODAL = 'DISPLAY_TEST_KIT_MODAL';
export const DISPLAY_REASSIGN_TEST_KIT_MODAL = 'DISPLAY_REASSIGN_TEST_KIT_MODAL';
export const DISPLAY_USER_MODAL = 'DISPLAY_USER_MODAL';
export const DISPLAY_ROLE_MODAL = 'DISPLAY_ROLE_MODAL';
export const DISPLAY_CUSTOMER_TEST_MODAL = 'DISPLAY_CUSTOMER_TEST_MODAL';
export const DISPLAY_TAG_MODAL = 'DISPLAY_TAG_MODAL';
export const DISPLAY_CONFIRMATION_MODAL = 'DISPLAY_CONFIRMATION_MODAL';
export const DISPLAY_ADD_PROMO_BUNDLE_ORDER_MODAL = 'DISPLAY_ADD_PROMO_BUNDLE_ORDER_MODAL';
export const DISPLAY_ADD_PROMO_KITS_ORDER_MODAL = 'DISPLAY_ADD_PROMO_KITS_ORDER_MODAL';
export const DISPLAY_ENTER_ENV_DATA_MODAL = 'DISPLAY_ENTER_ENV_DATA_MODAL';
export const DISPLAY_ENTER_SUBSCRIPTION_DATA_MODAL = 'DISPLAY_ENTER_SUBSCRIPTION_DATA_MODAL';
export const DISPLAY_CANCEL_SUBSCRIPTION_MODAL = 'DISPLAY_CANCEL_SUBSCRIPTION_MODAL';
export const DISPLAY_TIKTOK_URL_MODAL = 'DISPLAY_TIKTOK_URL_MODAL';
export const DISPLAY_EXPORT_OPTIONS_MODAL = 'EXPORT_OPTIONS_MODAL';
export const MODAL_TRANSITION_DURATION = 300;

// Common
export const AUTO_FETCH_INTERVAL_TIMEOUT = 10000;

// Product Types
export const GetProductType = (p) => {
  if (!((p || {}).name || '').length) return '(null)';
  if (p.name.toLowerCase().includes('shampoo')) return 'shampoo';
  if (p.name.toLowerCase().includes('conditioner')) return 'conditioner';
  if (p.name.toLowerCase().includes('test kit')) return 'testkit';
  return '(unknown)';
};

// Customer Questionnaire
export const QUESTIONNAIRE = [
  {
    question: 'How old are you?',
    answers: ['<20', '20-25', '26-34', '35-44', '45-54', '55+'],
    id: 'age',
  },
  {
    question: 'What is your natural hair type?',
    answers: ['straight', 'wavy', 'curly', 'coily'],
    id: 'hair_type',
  },
  {
    question: 'How long is your hair?',
    answers: ['very_short', 'chin_length', 'shoulder_length', 'long'],
    id: 'hair_length',
  },
  {
    question: 'Rate your natural hair shine',
    answers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    id: 'slider_shine',
  },
  {
    question: 'Rate your hair frizz',
    answers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    id: 'slider_frizz',
  },
  {
    question: 'Rate the amount of split ends you notice on the tips of your hair',
    answers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    id: 'slider_split_ends',
  },
  {
    question: 'Rate the amount of breakage you experience',
    answers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    id: 'slider_breakage',
  },
  {
    question: 'How often do you cut/trim your hair?',
    answers: ['once_a_month', 'once_every_2-3_months', 'once_every_4-6_months', 'once_a_year_or_longer'],
    id: 'hair_cut',
  },
  {
    question: 'How often do you shampoo your hair?',
    answers: ['everyday', 'a_few_times_a_week', 'a_few_times_a_month', 'once_a_month', 'never'],
    id: 'shampoo',
  },
  {
    question: 'How often do you blow dry your hair?',
    answers: ['everyday', 'a_few_times_a_week', 'a_few_times_a_month', 'never'],
    id: 'blow_dry',
  },
  {
    question: 'How often do you flat iron or curl your hair?',
    answers: ['everyday', 'a_few_times_a_week', 'a_few_times_a_month', 'never'],
    id: 'flat_iron',
  },
  {
    question: 'Select all of the products that you use',
    answers: ['creams', 'dry_shampoo', 'gel', 'hair_butter', 'heat_protection_spray', 'hair_oil', 'hair_spray', 'paste_or_pomade', 'spray_moisturizer', 'mousse'],
    id: 'products',
  },
  {
    question: 'Do you use or plan to use any of these coloring treatments?',
    answers: ['semi-permanent_dyes', 'permanent_dyes', 'bleach', "i_don't_color_my_hair"],
    id: 'coloring_treatment',
  },
  {
    question: 'How often do you color your hair?',
    answers: ['every_month', 'every_1_to_3_months', 'a_few_times_a_year'],
    id: 'color',
  },
  {
    question: 'Do you use or plan to use any of these treatments?',
    answers: ['relaxing_or_smoothing', 'keratin', 'permanent_wave_or_curl', "i_don't_treat_my_hair"],
    id: 'treatments',
  },
  {
    question: 'How often do you treat your hair?',
    answers: ['every_month', 'every_1_to_3_months', 'a_few_times_a_year'],
    id: 'hair_treat',
  },
  {
    question: 'How often are you in the sun?',
    answers: ['less_than_1_hour_per_day', '1-5_hours_per_day', 'more_than_5_hours_per_day'],
    id: 'sun',
  },
  {
    question: 'How often do you swim in a chlorinated pool?',
    answers: ['everyday', 'a_few_times_a_week', 'a_few_times_a_month', 'never'],
    id: 'pool',
  },
  {
    question: 'How often do you swim in salt water?',
    answers: ['everyday', 'a_few_times_a_week', 'a_few_times_a_month', 'never'],
    id: 'salt_water',
  },
  {
    question: 'Open up your booklet to page 11-12 and choose the scent you like:',
    answers: ['fragrance_free', 'escape', 'serenity', 'timeless', 'elegance'],
    id: 'scent',
  },
  {
    question: 'What type of sample would you like to submit?',
    answers: ['freshly_shed_hair', 'old_shed_hair', 'trimmed_hair'],
    id: 'type_of_sample',
  },
  {
    question: 'Scalp Dandruff Test',
    answers: ['no_dandruff', 'slight_dandruff', 'high_dandruff'],
    id: 'scalp_dandruff_level',
  },
  {
    question: 'Scalp Sebum Test',
    answers: ['dry', 'normal', 'slightly_oily', 'oily'],
    id: 'scalp_oil_level',
  },
  {
    question: 'Composite score',
    answers: ['low', 'medium', 'high', 'very_high'],
    id: 'composite_score',
  },
  {
    question: 'Microscopy Score',
    answers: ['1', '2', '3', '4'],
    id: 'microscopy_score',
  },
];

// Role Permissions

export const USERS_READ = 'users_read';
export const USERS_CREATE = 'users_create';
export const USERS_UPDATE = 'users_update';
export const USERS_ROLE_UPDATE = 'users_role_update';
export const USERS_DELETE = 'users_delete';
export const ROLES_READ = 'roles_read';
export const ROLES_CREATE = 'roles_create';
export const ROLES_UPDATE = 'roles_update';
export const ROLES_DELETE = 'roles_delete';
export const QUIZ_RECORDS_READ = 'quiz_records_read';
export const QUIZ_RECORDS_EXPORT = 'quiz_records_export';
export const TBL_QUIZ_RECORDS_READ = 'tbl_quiz_records_read';
export const TBL_QUIZ_RECORDS_EXPORT = 'tbl_quiz_records_export';
export const QUIZ_DEVICE_IDS_READ = 'quiz_device_ids_read';
export const TEST_KITS_READ = 'test_kits_read';
export const TEST_KITS_CREATE = 'test_kits_create';
export const TEST_KITS_UPDATE = 'test_kits_update';
export const TEST_KITS_DELETE = 'test_kits_delete';
export const FORMULA_COCKTAILS_READ = 'formula_cocktails_read';
export const FORMULA_COCKTAILS_CREATE = 'formula_cocktails_create';
export const FORMULA_COCKTAILS_UPDATE = 'formula_cocktails_update';
export const FORMULA_COCKTAILS_DELETE = 'formula_cocktails_delete';
export const INGREDIENTS_READ = 'ingredients_read';
export const INGREDIENTS_CREATE = 'ingredients_create';
export const INGREDIENTS_UPDATE = 'ingredients_update';
export const INGREDIENTS_DELETE = 'ingredients_delete';
export const ORDERS_READ = 'orders_read';
export const ORDERS_PRODUCT_QUEUE_READ = 'orders_product_queue_read';
export const CUSTOMERS_READ = 'customers_read';
export const SETTINGS_READ = 'settings_read';
export const SETTINGS_UPDATE = 'customers_update';
export const SAMPLE_WEIGHT_READ = 'sample_weighing_read';
export const CUSTOMER_TEST_CREATE = 'customer_test_create';
export const CUSTOMER_SUBSCRIPTION_READ = 'customer_subscription_read';
export const CUSTOMER_SUBSCRIPTION_UPDATE = 'customer_subscription_update';
export const MICROBIAL_TESTING_READ = 'microbial_testing_read';
export const MICROBIAL_TESTING_CREATE = 'microbial_testing_create';
export const MICROBIAL_TESTING_UPDATE = 'microbial_testing_update';
export const PRODUCTS_FULFILLMENT_READ = 'products_fulfillment_read';
export const GENERIC_PRODUCTS_FULFILLMENT_READ = 'generic_products_fulfillment_read';
export const SHIPPING_LABELS_READ = 'shipping_labels_read';
export const ACTIVITY_LOG_READ = 'activity_log_read';
export const TIKTOK_URLS_READ = 'tiktok_urls_read';
export const TIKTOK_URLS_CREATE = 'tiktok_urls_create';
export const TIKTOK_URLS_UPDATE = 'tiktok_urls_update';
export const TIKTOK_URLS_DELETE = 'tiktok_urls_delete';
export const TIKTOK_COMMENTS_EXPORT = 'tiktok_comments_export';

export const ROLE_PERMISSIONS = [
  {
    group_name: 'Users',
    permissions: [
      {
        id: USERS_READ,
        name: 'Users read',
      },
      {
        id: USERS_CREATE,
        name: 'Users create',
      },
      {
        id: USERS_UPDATE,
        name: 'Users update',
      },
      {
        id: USERS_ROLE_UPDATE,
        name: 'Users role update',
      },
      {
        id: USERS_DELETE,
        name: 'Users delete',
      },
    ],
  },
  {
    group_name: 'THL Quiz Records',
    permissions: [
      {
        id: QUIZ_RECORDS_READ,
        name: 'THL Quiz Records read',
      },
      {
        id: QUIZ_RECORDS_EXPORT,
        name: 'THL Quiz Records export',
      },
    ],
  },
  {
    group_name: 'TBL Quiz Records',
    permissions: [
      {
        id: TBL_QUIZ_RECORDS_READ,
        name: 'TBL Quiz Records read',
      },
      {
        id: TBL_QUIZ_RECORDS_EXPORT,
        name: 'TBL Quiz Records export',
      },
    ],
  },
  {
    group_name: 'Quiz Device IDs',
    permissions: [
      {
        id: QUIZ_DEVICE_IDS_READ,
        name: 'Quiz Device IDs read',
      },
    ],
  },
  {
    group_name: 'Roles',
    permissions: [
      {
        id: ROLES_READ,
        name: 'Roles read',
      },
      {
        id: ROLES_CREATE,
        name: 'Roles create',
      },
      {
        id: ROLES_UPDATE,
        name: 'Roles update',
      },
      {
        id: ROLES_DELETE,
        name: 'Roles delete',
      },
    ],
  },
  {
    group_name: 'Test kits',
    permissions: [
      {
        id: TEST_KITS_READ,
        name: 'Test kits read',
      },
      {
        id: TEST_KITS_CREATE,
        name: 'Test kits create',
      },
      {
        id: TEST_KITS_UPDATE,
        name: 'Test kits update',
      },
      {
        id: TEST_KITS_DELETE,
        name: 'Test kits delete',
      },
    ],
  },
  {
    group_name: 'Formula cocktails',
    permissions: [
      {
        id: FORMULA_COCKTAILS_READ,
        name: 'Formula cocktails read',
      },
      {
        id: FORMULA_COCKTAILS_CREATE,
        name: 'Formula cocktails create',
      },
      {
        id: FORMULA_COCKTAILS_UPDATE,
        name: 'Formula cocktails update',
      },
      {
        id: FORMULA_COCKTAILS_DELETE,
        name: 'Formula cocktails delete',
      },
    ],
  },
  {
    group_name: 'Ingredients',
    permissions: [
      {
        id: INGREDIENTS_READ,
        name: 'Ingredients read',
      },
      {
        id: INGREDIENTS_CREATE,
        name: 'Ingredients create',
      },
      {
        id: INGREDIENTS_UPDATE,
        name: 'Ingredients update',
      },
      {
        id: INGREDIENTS_DELETE,
        name: 'Ingredients delete',
      },
    ],
  },
  {
    group_name: 'Orders',
    permissions: [
      {
        id: ORDERS_READ,
        name: 'Orders read',
      },
      {
        id: ORDERS_PRODUCT_QUEUE_READ,
        name: 'Orders product queue read',
      },
    ],
  },
  {
    group_name: 'Customers',
    permissions: [
      {
        id: CUSTOMERS_READ,
        name: 'Customers read',
      },
    ],
  },
  {
    group_name: 'Settings',
    permissions: [
      {
        id: SETTINGS_READ,
        name: 'Settings read',
      },
      {
        id: SETTINGS_UPDATE,
        name: 'Settings update',
      },
    ],
  },
  {
    group_name: 'Sample Weighing',
    permissions: [
      {
        id: SAMPLE_WEIGHT_READ,
        name: 'Sample Weighing',
      },
    ],
  },
  {
    group_name: 'Customer',
    permissions: [
      {
        id: CUSTOMER_TEST_CREATE,
        name: 'Customer test create',
      },
      {
        id: CUSTOMER_SUBSCRIPTION_READ,
        name: 'Customer subscription read',
      },
      {
        id: CUSTOMER_SUBSCRIPTION_UPDATE,
        name: 'Customer subscription update',
      },
    ],
  },
  {
    group_name: 'Microbial Testing',
    permissions: [
      {
        id: MICROBIAL_TESTING_READ,
        name: 'Microbial Testing read',
      },
      {
        id: MICROBIAL_TESTING_CREATE,
        name: 'Microbial Testing create',
      },
      {
        id: MICROBIAL_TESTING_UPDATE,
        name: 'Microbial Testing update',
      },
    ],
  },
  {
    group_name: 'Products',
    permissions: [
      {
        id: PRODUCTS_FULFILLMENT_READ,
        name: 'Products Fulfillment read',
      },
      {
        id: GENERIC_PRODUCTS_FULFILLMENT_READ,
        name: 'Generic Products Fulfillment read',
      },
    ],
  },
  {
    group_name: 'Labels',
    permissions: [
      {
        id: SHIPPING_LABELS_READ,
        name: 'Shipping labels read',
      },
    ],
  },
  {
    group_name: 'Activity Log',
    permissions: [
      {
        id: ACTIVITY_LOG_READ,
        name: 'Activity log read',
      },
    ],
  },
  {
    group_name: 'TikTok',
    permissions: [
      {
        id: TIKTOK_URLS_READ,
        name: 'TikTok urls read',
      },
      {
        id: TIKTOK_URLS_CREATE,
        name: 'TikTok urls create',
      },
      {
        id: TIKTOK_URLS_UPDATE,
        name: 'TikTok urls update',
      },
      {
        id: TIKTOK_URLS_DELETE,
        name: 'TikTok urls delete',
      },
      {
        id: TIKTOK_COMMENTS_EXPORT,
        name: 'TikTok comments export',
      },
    ],
  },
];
