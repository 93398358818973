import FileDownload from 'js-file-download';
import { errorToast } from './toast';

const apiFetch = (url, method, body, signal) => (
  fetch(url, {
    method,
    signal,
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  }).then((res) => (res.ok ? res.json() : res))
    .then((res) => {
      if (res.id || res.ok) return res;
      window.console.error(res);
      const msg = (res.error || []).length ? res.error.join('\n') : res.statusText;
      errorToast(msg, res.status || 500);
      throw res;
    })
);

const csvDownload = (url, body, filename) => (
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  }).then((res) => res.blob())
    .then((res) => FileDownload(res, `${filename}.csv`))
);

const logout = () => (
  fetch('/admin/users/sign_out', {
    method: 'DELETE',
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]')
        ? document.querySelector('meta[name=csrf-token]').content : '',
    },
  })
);

const get = (url, signal) => apiFetch(url, 'GET', undefined, signal);
const post = (url, data) => apiFetch(url, 'POST', data);
const put = (url, data) => apiFetch(url, 'PUT', data);
const destroy = (url) => apiFetch(url, 'DELETE');

const api = {
  shippingLabels: {
    fetch: (page, tab, order) => get(`/shipping_labels?page=${page}&tab=${tab}&order=${order}&fetch`),
    complete: (data) => post('/shipping_labels/complete', data),
  },
  settings: {
    save: (data) => post('settings', data),
  },
  auth: {
    logout,
    enable2FA: (data) => post('/two_factor_settings', data),
    disable2FA: () => destroy('/two_factor_settings'),
    sendCode: (data) => post('/two_factor_settings/send_code', data),
  },
  products: {
    getAll: () => get('products'),
    get: (id) => get(`products/${id}`),
    create: (data) => post('products', data),
    update: (data, id) => put(`products/${id}`, data),
    destroy: (id) => destroy(`products/${id}`),
  },
  activityLog: {
    getAll: (params) => get(`/activity_log${params}&fetch`),
    downloadCSV: () => post('/activity_log/download_csv'),
  },
  testKits: {
    getAll: () => get('/test_kits'),
    get: (id) => get(`/test_kits/${id}`),
    create: (data) => post('/test_kits', data),
    update: (data, id) => put(`/test_kits/${id}`, data),
    destroy: (id) => destroy(`/test_kits/${id}`),
    fetchTestKits: (params) => get(`/test_kits${params}&fetch`),
    reassign: (id, data) => post(`/test_kits/${id}/reassign`, data),
  },
  addTestKits: {
    create: (data) => post('/test_kits', data),
    generateCode: () => post('generate_code'),
  },
  environmentData: {
    create: (data) => post('/environment_data', data),
    fetchLatest: (data) => post('/environment_data/fetch_latest', data),
  },
  customers: {
    subscriptionDetails: (data) => post('/customers/subscription_details', data),
    resendInvite: (data) => post('/customers/resend_invite', data),
    updateSubscriptionData: (data) => post('/customers/update_subscription_data', data),
    cancelSubscription: (data) => post('/customers/cancel_subscription', data),
    fetchDataForQuestionaire: (data) => post('/customers/fetch_data_for_questionaire', data),
    fetchSubscribed: (page, order, search, status) => get(`/customers/subscriptions?page=${page}&order=${order}&search=${search}&status=${status}&fetch`),
    fetchCustomers: (page, order, filters, signal) => get(`/customers?page=${page}&order=${order}&filter=${JSON.stringify(filters)}&fetch`, signal),
    downloadCSV: (data) => post('/customers/download_subscriptions_csv', data),
    formulationNotes: {
      create: (customerId, text) => post(`/customers/${customerId}/formulation_notes`, { text }),
      update: (customerId, noteId, text) => put(`/customers/${customerId}/formulation_notes/${noteId}`, { text }),
      destroy: (customerId, noteId) => destroy(`/customers/${customerId}/formulation_notes/${noteId}`),
    },
  },
  users: {
    getAll: () => get('users'),
    get: (id) => get(`users/${id}`),
    create: (data) => post('users', data),
    update: (data, id) => put(`users/${id}`, data),
    destroy: (id) => destroy(`users/${id}`),
  },
  roles: {
    getAll: () => get('/roles?fetch'),
    get: (id) => get(`/roles/${id}`),
    create: (data) => post('/roles', data),
    update: (data, id) => put(`/roles/${id}`, data),
    destroy: (id) => destroy(`/roles/${id}`),
  },
  customerTests: {
    getAll: () => get('customer_tests'),
    get: (id) => get(`customer_tests/${id}`),
    create: (data) => post('customer_tests', data),
    update: (data, id) => put(`customer_tests/${id}`, data),
    destroy: (id) => destroy(`customer_tests/${id}`),
    fetchCustomerTests: (params) => get(`/customer_tests${params}&fetch`),
  },
  addCustomerTests: {
    save: (data) => post('/customer_tests/save', data),
    codeVerification: (data) => post('/customer_tests/code_verification', data),
    receive: (id) => post('/customer_tests/receive', id),
    fetchS3Image: (data) => post('/customer_tests/fetch_s3_image', data),
    publish: (id) => post('/customer_tests/publish', id),
    fetchTestKits: (page, tab, order, search) => get(`/customer_tests/add_customer_test?page=${page}&tab=${tab}&order=${order}&search=${search}&fetch`),
    fetchWeighing: () => get('/customer_tests/sample_weighing_queue?fetch'),
    fetchSeparation: () => get('/customer_tests/sample_separation_queue?fetch'),
  },
  orders: {
    get: (id) => get(`orders/${id}`),
    downloadCSV: (data, filename) => post('/orders/download_csv', data, filename),
    fetchProductIssue: (data) => post('/orders/fetch_product_issue', data),
    fetchOrders: (page, order, search) => get(`/orders?page=${page}&order=${order}&search=${search}&fetch`),
  },
  ordersFulfillment: {
    codeVerification: (data) => post('/test_kits/code_verification', data),
    generateShippingLabel: (data) => post('/orders/generate_shipping_label', data),
    fulfill: (data) => post('fulfill', data),
    fulfillmentCodeVerification: (data) => post('/orders/code_verification', data),
    fetchTestKitOrders: (page, tab, order, search) => get(`/orders/test_kits_fulfillment?page=${page}&tab=${tab}&order=${order}&search=${search}&fetch`),
    fetchProductQueueOrders: (page, tab, order, search) => get(`/orders/products_queue?page=${page}&tab=${tab}&order=${order}&search=${search}&fetch`),
    fetchProductOrders: (page, tab, order, search) => get(`/orders/products_fulfillment?page=${page}&tab=${tab}&order=${order}&search=${search}&fetch`),
    fetchGenericProductOrders: (page, tab, order, search) => get(`/orders/generic_products_fulfillment?page=${page}&tab=${tab}&order=${order}&search=${search}&fetch`),
    addPromoKitsOrder: (data) => post('/orders/add_promo_kits_order', data),
  },
  print: {
    label: (data) => post('/print/label', data),
    allLabels: (data) => post('/print/all_labels', data),
  },
  ingredients: {
    destroy: (id) => destroy(`ingredients/${id}`),
  },
  addIngredients: {
    create: (data) => post('/ingredients', data),
  },
  editIngredients: {
    fetch: () => get('/ingredients/edit_ingredients?fetch'),
    update: (data, id) => put(`/ingredients/${id}`, data),
  },
  tags: {
    getAll: () => get('tags'),
    get: (id) => get(`tags/${id}`),
    create: (data) => post('tags', data),
    update: (data, id) => put(`tags/${id}`, data),
    destroy: (id) => destroy(`tags/${id}`),
  },
  formulaCocktails: {
    destroy: (id) => destroy(`formula_cocktails/${id}`),
    generateCocktailBom: (data, filename) => csvDownload('/formula_cocktails/generate_cocktail_bom', data, filename),
    generateOrderBom: (data, filename) => csvDownload('/formula_cocktails/generate_order_bom', data, filename),
  },
  addFormulaCocktails: {
    create: (data) => post('/formula_cocktails', data),
  },
  editFormulaCocktails: {
    update: (data, id) => put(`/formula_cocktails/${id}`, data),
  },
  productFormulas: {
    destroy: (id) => destroy(`product_formulas/${id}`),
    create: (data) => post('/product_formulas', data),
    save: (data) => post('/product_formulas/save', data),
    fetchIngredients: (data) => post('/product_formulas/fetch_ingredients', data),
    publish: (data) => post('/product_formulas/publish', data),
    customerInfo: (id) => post('/product_formulas/customer_info', id),
    reportPreview: (id) => post('/product_formulas/report_preview', id),
    fetchProductFormulas: (params) => get(`/product_formulas${params}&fetch`),
  },
  microbialTestings: {
    create: (data) => post('/microbial_testings', data),
    update: (data, id) => put(`/microbial_testings/${id}`, data),
    retest: (data) => post('/microbial_testings/retest', data),
    codeVerification: (data) => post('/microbial_testings/code_verification', data),
    fetchQueueOrders: (page, tab, order) => get(`/microbial_testings/queue?page=${page}&tab=${tab}&order=${order}&fetch`),
  },
  discounts: {
    requestDiscount: (data) => post('/discounts/request_discount', data),
  },
  quizRecords: {
    fetchRecords: (tab, page, order, filter) => get(`/quiz_records?tab=${tab}&page=${page}&order=${order}&filter=${JSON.stringify(filter)}&fetch`),
    downloadCSV: (data, type) => post(`/quiz_records/download_csv?type=${type}`, data),
    downloadRecommendationsCSV: () => post('/quiz_records/download_recommendations_csv'),
  },
  tblQuizRecords: {
    fetchRecords: (page, order, filter) => get(`/tbl_quiz_records?page=${page}&order=${order}&filter=${JSON.stringify(filter)}&fetch`),
    downloadCSV: (data, type) => post(`/tbl_quiz_records/download_csv?type=${type}`, data),
    downloadRecommendationsCSV: () => post('/tbl_quiz_records/download_recommendations_csv'),
  },
  quizDeviceIds: {
    fetchRecords: (page, order, search) => get(`/quiz_device_ids?page=${page}&order=${order}&search=${search}&fetch`),
  },
  tiktokUrls: {
    getAll: () => get('/tiktok_urls?fetch'),
    create: (data) => post('/tiktok_urls', data),
    update: (data, id) => put(`/tiktok_urls/${id}`, data),
    destroy: (id) => destroy(`/tiktok_urls/${id}`),
    downloadCommentsCsv: (id) => post(`/tiktok_urls/${id}/download_comments_csv`),
  },
};

export default api;
