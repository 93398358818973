import { toast } from 'react-toastify';
import React from 'react';

function successToast(text) {
  toast.dismiss();
  toast.success(
    <h1>
      {text}
    </h1>, {
      autoClose: 3000,
      closeButton: false,
    },
  );
}

function errorToast(msg, status) {
  toast.dismiss();
  toast.error(
    <h1>
      {`Error ${status}: ${msg || 'Sorry, an unknown error occured. Please try again later.'}`}
    </h1>,
  );
}

export { successToast, errorToast };
