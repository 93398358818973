import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_CONFIRMATION_MODAL } from '../../constants';

class FormulaCocktailsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);

    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleFormulaCocktailDestroy = this.handleFormulaCocktailDestroy.bind(this);

    this.state = {
      formulaCocktails: props.formulaCocktails,
      filteredFormulaCocktails: props.formulaCocktails,
      lastSortKey: '',
      filterId: '',
      filterName: '',
      filterDescription: '',
      currentTab: 'formula_base',
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { filteredFormulaCocktails, lastSortKey } = this.state;
    const sortedFormulaCocktails = (sortKey === 'id')
      ? filteredFormulaCocktails.sort((a, b) => a[sortKey] - b[sortKey])
      : filteredFormulaCocktails.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      filteredFormulaCocktails: reverseSort ? sortedFormulaCocktails.reverse() : sortedFormulaCocktails,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const {
      formulaCocktails, filterId, filterName, filterDescription,
    } = this.state;
    let newFormulaCocktails = formulaCocktails;

    if (filterId) {
      newFormulaCocktails = newFormulaCocktails
        .filter((i) => i.id.toString().startsWith(filterId.toString()));
    }
    if (filterName) {
      newFormulaCocktails = newFormulaCocktails
        .filter((i) => i.name.toString().startsWith(filterName.toString()));
    }
    if (filterDescription) {
      newFormulaCocktails = newFormulaCocktails
        .filter((i) => i.description.toString().startsWith(filterName.toString()));
    }
    this.setState({ filteredFormulaCocktails: newFormulaCocktails });
  }

  clearFilters() {
    const { formulaCocktails } = this.state;
    this.filtersForm.reset();
    this.setState({
      filteredFormulaCocktails: formulaCocktails,
      filterId: '',
      filterName: '',
      filterDescription: '',
    });
  }

  // Tabs
  handleTabChange(type) {
    $('.custom-tabs > div').removeClass('active');
    $(`#tab-${type}`).addClass('active');
    this.setState({ currentTab: type });
  }

  // api
  handleFormulaCocktailDestroy(id) {
    const { formulaCocktails, filteredFormulaCocktails } = this.state;
    api.formulaCocktails.destroy(id)
      .then((res) => {
        this.setState({
          formulaCocktails: formulaCocktails.filter((i) => i.id !== res.id),
          filteredFormulaCocktails: filteredFormulaCocktails.filter((i) => i.id !== res.id),
        });
      });
  }

  render() {
    const { filteredFormulaCocktails, currentTab } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <button
            type="button"
            id="custom-filters-toggle"
            className="btn btn-primary"
            onClick={() => $('#custom-filters').toggle(100)}
          >
            Toggle Filters
          </button>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterId">ID (startsWith)</label>
                <input
                  type="number"
                  className="form-control"
                  id="filterId"
                  placeholder="123"
                  onChange={this.handleChange('filterId')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterName">Name (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterName"
                  placeholder="abc"
                  onChange={this.handleChange('filterName')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterDescription">Description (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterDescription"
                  placeholder="abc"
                  onChange={this.handleChange('filterDescription')}
                />
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Formula Cocktails</div>
          </div>
          <div className="custom-tabs">
            <div id="tab-formula_base" className="active" onClick={() => this.handleTabChange('formula_base')}>Base</div>
            <div id="tab-formula_treatment" onClick={() => this.handleTabChange('formula_treatment')}>Treatment</div>
            <div id="tab-formula_environment" onClick={() => this.handleTabChange('formula_environment')}>Environment</div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col" onClick={() => this.handleSort('id')}>Formula Cocktail id</th>
                <th scope="col" onClick={() => this.handleSort('part_number')}>Part Number</th>
                <th scope="col" onClick={() => this.handleSort('name')}>Name</th>
                <th scope="col" onClick={() => this.handleSort('description')}>Description</th>
                <th scope="col">Ingredients</th>
                <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {filteredFormulaCocktails.filter((i) => i.formula_type === currentTab).map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{item.part_number}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>
                    {item.ingredients.length > 0 ? (
                      item.ingredients.map((i, index) => <div key={i.ingredient_id}>{`#${index + 1}: ${i.ingredient.name}, ${i.mass_fraction}%;`}</div>)
                    ) : 'no ingredients'}
                  </td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                  <td>{new Date(item.updated_at).toLocaleString()}</td>
                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleFormulaCocktailDestroy(item.id))}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

FormulaCocktailsContainer.propTypes = {
  formulaCocktails: PropTypes.instanceOf(Array),
};

FormulaCocktailsContainer.defaultProps = {
  formulaCocktails: [],
};

export default FormulaCocktailsContainer;
