export const jsHumanize = (string) => string.trim().split(/\s+/).map((str) => str.replace(/_/g, ' ')
  .replace(/\s+/, ' ').trim()).join(' ')
  .toLowerCase()
  .replace(/^./, (m) => m.toUpperCase());

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
};
