import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';

import api from '../../services/api';
import { handleBeforePrint } from '../../services/print';
// import codeErrors from '../../services/codeValidators';
import LabelContainer from '../label/LabelContainer';
import { successToast } from '../../services/toast';

class AddTestKitForm extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleFirstStepSubmit = this.handleFirstStepSubmit.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.dateFormat = this.dateFormat.bind(this);
    this.generateCode = this.generateCode.bind(this);
    this.handleTestKitCreate = this.handleTestKitCreate.bind(this);
    this.onBarcodeMount = this.onBarcodeMount.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);

    this.defaultState = {
      step: 1,
      code: '',
      returnTrackingCode: '',
      confirmCode: '',
      label: null,
      productId: null,
      status: null,
      quantity: null,
      readyForPrint: false,
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const { label, code } = this.state;
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;

    this.setState({ step: newStep }, () => {
      if (newStep === 2) {
        handleBeforePrint(label, code, false);
      }
    });
  }

  // Step 1 - submit + barcode
  handleFirstStepSubmit(e) {
    e.preventDefault();
    const { code } = this.state;
    const postfix = 'TK';
    if (code === '') return alert('Generate code!');
    return api.print.label({ code: `${code}-${postfix}`, postfix, label_type: 'test_kit' })
      .then((res) => {
        this.setState({
          productId: this.mainForm.productId.value,
          status: this.mainForm.status.value,
          quantity: this.mainForm.quantity.value,
          label: res.label,
        }, () => this.handleStepChange());
        return res.label;
      })
      .then((label) => handleBeforePrint(label, code, false));
  }

  // Step change
  handleStepChange(e, val = 1) {
    const { label, code, step } = this.state;
    if (e) e.preventDefault();
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }), () => {
      if (val === -1 && step === 3) {
        handleBeforePrint(label, code, false);
      }
    });
  }

  // Date
  dateFormat() {
    const d = new Date();
    return `${[d.getMonth() + 1,
      d.getDate(),
      d.getFullYear()].join('/')} ${[d.getHours(),
      d.getMinutes(),
      d.getSeconds()].join(':')}`;
  }

  // Generate product-test_kit code
  generateCode() {
    api.addTestKits.generateCode()
      .then((res) => this.setState({
        code: `${res.code}`,
      }, () => {
        this.mainForm.code.value = `${res.code}`;
      }));
  }

  // Step 2 - Ready for print
  onBarcodeMount() {
    this.setState({ readyForPrint: true });
  }

  // Step 3 - Create
  handleTestKitCreate() {
    const {
      code, productId, status, quantity, returnTrackingCode,
    } = this.state;

    // const error = codeErrors(confirmCode);
    // if (error) return alert(error);
    // if (confirmCode.substr(0, 10) !== code.substr(0, 10)) return alert('Code mismatch');

    const data = {
      product_id: productId,
      status,
      quantity,
      code,
      return_tracking_code: returnTrackingCode,
    };

    return api.addTestKits.create(data).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState);
  }

  render() {
    const {
      step, readyForPrint, code, returnTrackingCode,
    } = this.state;
    const { product } = this.props;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            Add Test Kit
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Select Product Type
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Print Barcode
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 3 ? 'active' : ''}`}>
              3. Verify Barcode
            </span>
          </div>
          {step === 1 && (
            <form onSubmit={this.handleFirstStepSubmit} ref={(form) => { this.mainForm = form; }}>
              <div className="form-group">
                <label htmlFor="productId">
                  Product
                </label>
                <select
                  id="productId"
                  name="productId"
                  disabled
                >
                  <option
                    key={product.id}
                    label={`${product.title}`}
                  >
                    {product.id}
                  </option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select id="status" name="status" disabled>
                  <option>ReadyToShip</option>
                  <option>Shipped</option>
                  <option>Received</option>
                  <option>Completed</option>
                  <option>Discarded</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="title">
                  Date
                </label>
                <input
                  disabled
                  type="text"
                  name="created_at"
                  className="form-control"
                  defaultValue={this.dateFormat()}
                  id="created_at"
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">
                  Quantity
                </label>
                <input
                  disabled
                  type="text"
                  name="quantity"
                  className="form-control"
                  defaultValue={1}
                  id="quantity"
                />
              </div>
              <div className="form-group">
                <label htmlFor="return_tracking_code">
                  Return tracking code
                </label>
                <input
                  required
                  type="text"
                  name="return_tracking_code"
                  className="form-control"
                  id="return_tracking_code"
                  value={returnTrackingCode}
                  onChange={(e) => this.setState({ returnTrackingCode: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">
                  Code
                </label>
                <div className="custom-input-with-btn">
                  <input
                    disabled
                    required
                    defaultValue={code}
                    type="text"
                    name="code"
                    className="form-control custom-input-small"
                    id="code"
                  />
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.generateCode}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
              <div className="btn-group custom-btn-continue">
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Continue
                </button>
              </div>
            </form>
          )}
          {step === 2 && (
            <div>
              <LabelContainer
                onMount={this.onBarcodeMount}
                ref={(el) => { this.printContent = el; }}
              />
              <div className="btn-group custom-barcode-print-btn">
                {readyForPrint && (
                  <ReactToPrint
                    trigger={() => <button type="button" className="btn btn-secondary">Print</button>}
                    content={() => this.printContent}
                  />
                )}
              </div>
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={(e) => this.handleStepChange(e, -1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleTestKitCreate}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {/* {step === 3 && (
            <div>
              <div className="form-group">
                <label htmlFor="title">
                  Confirm barcode
                </label>
                <input
                  required
                  type="text"
                  name="confirm_barcode"
                  className="form-control"
                  id="confirm_barcode"
                  onChange={(e) => this.setState({ confirmCode: e.target.value })}
                />
              </div>
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={(e) => this.handleStepChange(e, -1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleTestKitCreate}
                >
                  Finish
                </button>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

AddTestKitForm.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
};

export default AddTestKitForm;
