import React from 'react';
import api from '../../services/api';
import { successToast } from '../../services/toast';

const buttons = [
  { label: 'Shampoo code', type: 'SH' },
  { label: 'Conditioner code', type: 'CO' },
  { label: 'Other code', type: 'OTHER' },
];

class GenerateCodes extends React.Component {
  constructor(props) {
    super(props);

    this.handleRequestDiscount = this.handleRequestDiscount.bind(this);

    this.state = {
      code: null,
      reformUrl: null,
    };
  }

  handleRequestDiscount(type) {
    api.discounts.requestDiscount({ type })
      .then((res) => {
        successToast('Done!');
        this.setState({ code: res.code, reformUrl: res.reform_url });
      });
  }

  render() {
    const renderButton = (button) => (
      <button
        key={button.type}
        type="button"
        className="btn btn-primary mr-5"
        onClick={() => this.handleRequestDiscount(button.type)}
      >
        {button.label}
      </button>
    );

    const renderCodeBlock = () => {
      const { code, reformUrl } = this.state;

      if (!code || !reformUrl) return <></>;

      return (
        <div className="discount-generated-code-block">
          <div>
            <span>Discount code:</span>
            <span>{code}</span>
          </div>
          <div>
            <span>Reformulation URL:</span>
            <a href={reformUrl} target="_blank" rel="noreferrer">{reformUrl}</a>
          </div>
        </div>
      );
    };

    return (
      <div className="card">
        <div className="card-body">
          {buttons.map(renderButton)}
          {renderCodeBlock()}
        </div>
      </div>
    );
  }
}

export default GenerateCodes;
