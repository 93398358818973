import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';

import LabelContainer from '../label/LabelContainer';
import api from '../../services/api';
import { handleBeforePrint } from '../../services/print';
import Pagination from '../customElements/Pagination';
import { successToast } from '../../services/toast';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

class MicrobialTestingQueue extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);
    this.fetchLabels = this.fetchLabels.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.handleOrderSort = this.handleOrderSort.bind(this);
    this.onLabelContainerMount = this.onLabelContainerMount.bind(this);
    this.handleMicrobialTestingCreate = this.handleMicrobialTestingCreate.bind(this);
    this.handleMicrobialTestingUpdate = this.handleMicrobialTestingUpdate.bind(this);
    this.handleMicrobialTestingRetest = this.handleMicrobialTestingRetest.bind(this);
    this.handleFetchOrders = this.handleFetchOrders.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.generateCsvData = this.generateCsvData.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.defaultState = {
      step: 1,
      labels: [],
      codeError: null,
      searchedOrder: null,
      selectedOrder: null,
      labelsReadyForPrint: false,
      currentTab: urlParams.get('tab') || 'Pending',
      lastSortKey: urlParams.get('order') || 'created_at desc',
      orders: props.orders,
      pagination: {
        pass: {
          currentPage: urlParams.get('tab') === 'Pass' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'Pass' && urlParams.get('page') ? props.isLastPage : null,
        },
        fail: {
          currentPage: urlParams.get('tab') === 'Fail' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'Fail' && urlParams.get('page') ? props.isLastPage : null,
        },
      },
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchOrders(null, null, order);
  }

  // Tabs
  handleTabChange(tab) {
    const { pagination } = this.state;
    this.setState({
      codeError: null,
      searchedOrder: null,
    }, () => $('input#code').val(''));
    const page = pagination[tab.toLowerCase()] && pagination[tab.toLowerCase()].currentPage ? pagination[tab.toLowerCase()].currentPage : 1;
    this.handleFetchOrders(page, tab);
  }

  // Step change
  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Step 1 - Order select
  handleOrderSelect(order) {
    this.setState({ selectedOrder: order }, () => this.fetchLabels());
  }

  // Step 1 - Fetch Labels
  fetchLabels() {
    const { selectedOrder } = this.state;

    api.print.allLabels({
      code: selectedOrder.code,
      label_type: 'order',
      postfix_array: ['ST', 'CT'],
    })
      .then((res) => this.setState({ labels: res.labels }, () => this.handleStepChange()));
  }

  // Step 1 - Check codes
  verifyCode(e) {
    const { currentTab } = this.state;
    const code = e.target.value;
    if (code.length !== 10) return this.setState({ codeError: 'Wrong length ' });
    // const error = codeErrors(code, ['-MS', '-PE']);

    return api.microbialTestings.codeVerification({
      code,
      status: currentTab,
      // postfix_array: ['-MS', '-PE'],
    })
      .then((res) => this.setState({
        codeError: res.err,
        searchedOrder: res.order_id ? res.order : null,
      }));
  }

  handleOrderSort(x, y) {
    const { searchedOrder } = this.state;

    if (x.id === searchedOrder) {
      return -1;
    }
    if (y.id === searchedOrder) {
      return 1;
    }
    return 0;
  }

  // Step 2 - Print
  onLabelContainerMount() {
    this.setState({ labelsReadyForPrint: true });
  }

  handleMicrobialTestingCreate() {
    const { selectedOrder } = this.state;

    api.microbialTestings.create({ id: selectedOrder.id }).then(this.handleSuccess);
  }

  handleMicrobialTestingUpdate(status) {
    const { selectedOrder } = this.state;

    api.microbialTestings.update({ status }, selectedOrder.microbial_testing.id).then(this.handleSuccess);
  }

  handleMicrobialTestingRetest() {
    const { selectedOrder } = this.state;

    api.microbialTestings.retest({ id: selectedOrder.microbial_testing.id }).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetchOrders);
  }

  handleFetchOrders(newPage, newTab, newOrder) {
    const { currentTab, pagination, lastSortKey } = this.state;
    const tab = newTab || currentTab;
    const page = newPage || (pagination[currentTab.toLowerCase()] ? pagination[currentTab.toLowerCase()].currentPage : 1);
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?tab=${tab}&page=${page}&order=${order}`);

    api.microbialTestings.fetchQueueOrders(page, tab, order)
      .then((res) => this.setState({
        orders: res.orders,
        currentTab: tab,
        lastSortKey: order,
        pagination: {
          ...pagination,
          [tab.toLowerCase()]: {
            ...pagination[tab.toLowerCase()],
            currentPage: page,
            isLastPage: res.is_last_page,
          },
        },
      }));
  }

  generateCsvData() {
    const { orders } = this.state;

    return [
      ['Order', 'Email', 'First Name', 'Last Name', 'Status', 'Label Code', 'Created', 'Updated', 'Status Changed', 'Age'],
      ...orders.map((item) => [`#${item.shopify_number}`, (item.customer || {}).email, (item.customer || {}).first_name, (item.customer || {}).last_name, item.status, item.code, new Date(item.created_at).toLocaleString(), new Date(item.updated_at).toLocaleString(), new Date(item.status_changed_at).toLocaleString(), item.age]),
    ];
  }

  render() {
    const {
      step, labelsReadyForPrint, selectedOrder, orders, currentTab,
      searchedOrder, codeError, labels, pagination,
    } = this.state;
    const parsedOrders = searchedOrder ? [searchedOrder, ...orders.filter((i) => i.id !== searchedOrder.id)] : orders;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            {selectedOrder
              ? (
                <>
                  {'Microbial Testing for Order '}
                  <ShopifyOrderLink shopifyId={selectedOrder.shopify_id}>{`#${selectedOrder.shopify_number}`}</ShopifyOrderLink>
                </>
              ) : 'Microbial Testing'}
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose Order
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Print labels / Change status
            </span>
          </div>
          {/* Tabs */}
          <div className={step === 1 ? 'custom-tabs' : 'disabled custom-tabs'}>
            <div
              id="tab-Pending"
              onClick={() => this.handleTabChange('Pending')}
              className={currentTab === 'Pending' ? 'active' : ''}
            >
              Pending
            </div>
            <div
              id="tab-InProgress"
              onClick={() => this.handleTabChange('InProgress')}
              className={currentTab === 'InProgress' ? 'active' : ''}
            >
              InProgress
            </div>
            <div
              id="tab-Pass"
              onClick={() => this.handleTabChange('Pass')}
              className={currentTab === 'Pass' ? 'active' : ''}
            >
              Pass
            </div>
            <div
              id="tab-Fail"
              onClick={() => this.handleTabChange('Fail')}
              className={currentTab === 'Fail' ? 'active' : ''}
            >
              Fail
            </div>
          </div>
          {step === 1 && (
            <div>
              <div className="form-group input-medium">
                <label htmlFor="code">Code</label>
                <input
                  id="code"
                  name="code"
                  className="form-control"
                  onChange={this.verifyCode}
                />
                {codeError && (
                  <div data-testid="code-error" className="input-error">{codeError}</div>
                )}
              </div>
              <div className="mt-5">
                <CSVLink className="csv-download" data={this.generateCsvData()}>Export as CSV</CSVLink>
                <table className="table table-striped table-responsive-sm custom-table">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => this.handleSort('shopify_number')}>Order</th>
                      <th scope="col" onClick={() => this.handleSort('customer_id')}>Customer</th>
                      <th scope="col" onClick={() => this.handleSort('status')}>Status</th>
                      <th scope="col" onClick={() => this.handleSort('code')}>Label Code</th>
                      <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                      <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                      <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Status Changed</th>
                      <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Age</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parsedOrders.sort(this.handleOrderSort).map((item) => (
                      <tr
                        key={item.id}
                        className={`custom-tr-selectable ${searchedOrder && item.id === searchedOrder.id ? 'highlighted' : ''}`}
                        onClick={() => this.handleOrderSelect(item)}
                      >
                        <th scope="row"><ShopifyOrderLink shopifyId={item.shopify_id}>{`#${item.shopify_number}`}</ShopifyOrderLink></th>
                        <td>{item.customer && `[${item.customer.id}] ${item.customer.first_name} ${item.customer.last_name}\n ${item.customer.email}`}</td>
                        <td>{item.status}</td>
                        <td>{item.code}</td>
                        <td>{new Date(item.created_at).toLocaleString()}</td>
                        <td>{new Date(item.updated_at).toLocaleString()}</td>
                        <td>{new Date(item.status_changed_at).toLocaleString()}</td>
                        <td>{item.age}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {!['Pending', 'InProgress'].includes(currentTab) && (
                <Pagination
                  currentPage={pagination[currentTab.toLowerCase()].currentPage}
                  isLastPage={pagination[currentTab.toLowerCase()].isLastPage}
                  handlePageChange={this.handleFetchOrders}
                />
              )}
            </div>
          )}
          {step === 2 && (
            <div>
              {/* Hidden Labels */}
              <div className="custom-hidden">
                <LabelContainer
                  onMount={this.onLabelContainerMount}
                  ref={(el) => { this.labelsContainer = el; }}
                />
                {labelsReadyForPrint && (
                  <ReactToPrint
                    trigger={() => (
                      <button
                        type="button"
                        className="btn btn-secondary"
                        id="print-trigger"
                      />
                    )}
                    content={() => this.labelsContainer}
                    onAfterPrint={() => $('style#barcodePrint').text('')}
                  />
                )}
              </div>

              {/* Label Buttons */}
              <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {labels.map((label) => (
                  <button
                    type="button"
                    key={label.code}
                    className="btn btn-secondary"
                    onClick={() => handleBeforePrint(label, label.code)}
                  >
                    {label.name}
                  </button>
                ))}
              </div>

              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={() => this.handleStepChange(-1)}
                >
                  Back
                </button>
                {currentTab === 'Pending' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleMicrobialTestingCreate}
                  >
                    Start Testing
                  </button>
                )}
                {currentTab === 'InProgress' && (
                  <div className="btn-group custom-btn-continue ml-2" style={{ width: '100%' }}>
                    <button
                      type="button"
                      className="btn btn-danger btn-back"
                      onClick={() => this.handleMicrobialTestingUpdate('Fail')}
                    >
                      Fail
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => this.handleMicrobialTestingUpdate('Pass')}
                    >
                      Pass
                    </button>
                  </div>
                )}
                {currentTab === 'Pass' && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.handleMicrobialTestingRetest}
                  >
                    Retest
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

MicrobialTestingQueue.propTypes = {
  orders: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

MicrobialTestingQueue.defaultProps = {
  orders: [],
  isLastPage: false,
};

export default MicrobialTestingQueue;
