import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../customElements/Pagination';
import api from '../../services/api';

// function renderAction(activity) {
//   if (activity.key.endsWith('.create')) return `${activity.trackable_type} #${activity.trackable_id} created`;
//   if (activity.key.endsWith('.update')) return `${activity.trackable_type} #${activity.trackable_id} updated`;
//   if (activity.key.endsWith('.destroy')) return `${activity.trackable_type} #${activity.trackable_id} deleted`;
//   if (activity.key === 'user.request_orders_export') return 'Orders export requested';
//   if (activity.key === 'user.request_quiz_export') return 'Web quiz records export requested';
//   if (activity.key === 'user.request_scanner_export') return 'Scanner records export requested';
//   if (activity.key === 'user.request_subscriptions_export') return 'Subscriptions export requested';
//   return activity.key;
// }

class ActivityLogContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFetchActivityLog = this.handleFetchActivityLog.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      activities: props.activities,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      filterEmail: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).email : '',
      filter: {},
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
    };
  }

  // events

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchActivityLog(null, order);
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const { filterEmail } = this.state;
    const filter = {};

    if (filterEmail) {
      filter.email = filterEmail;
    }

    this.setState({ filter }, () => this.handleFetchActivityLog(1));
  }

  clearFilters() {
    if (this.filtersForm) this.filtersForm.reset();
    this.setState({
      filter: {},
      filterEmail: '',
    }, () => this.handleFetchActivityLog(1));
  }

  handleFetchActivityLog(newPage, newOrder) {
    const { pagination, lastSortKey, filter } = this.state;
    const page = newPage || pagination.currentPage;
    const order = newOrder || lastSortKey;
    const params = `?page=${page}&order=${order}&filter=${JSON.stringify(filter)}`;
    window.history.pushState({}, null, params);

    api.activityLog.getAll(params)
      .then((res) => this.setState({
        activities: res.activities,
        lastSortKey: order,
        pagination: {
          ...pagination,
          currentPage: page,
          isLastPage: res.is_last_page,
        },
      }));
  }

  render() {
    const { activities, pagination } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <button
            type="button"
            id="custom-filters-toggle"
            className="btn btn-primary"
            onClick={() => $('#custom-filters').toggle(100)}
          >
            Toggle Filters
          </button>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterEmail">User Email (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterEmail"
                  placeholder="abc@gmail.com"
                  onChange={this.handleChange('filterEmail')}
                />
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Activity Log</div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col" onClick={() => this.handleSort('id')}>Id</th>
                <th scope="col" onClick={() => this.handleSort('key')}>Action</th>
                <th scope="col">Parameters</th>
                <th scope="col" onClick={() => this.handleSort('email')}>User</th>
                <th scope="col" onClick={() => this.handleSort('created_at')}>Date</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{item.key}</td>
                  <td className="activity-parameters">
                    <pre>
                      {JSON.stringify(item.parameters, null, 2)}
                    </pre>
                  </td>
                  <td>{item.owner?.email || '-'}</td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={pagination.currentPage}
          isLastPage={pagination.isLastPage}
          handlePageChange={this.handleFetchActivityLog}
        />
      </div>
    );
  }
}

ActivityLogContainer.propTypes = {
  activities: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool.isRequired,
};

ActivityLogContainer.defaultProps = {
  activities: [],
};

export default ActivityLogContainer;
