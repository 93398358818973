import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import { successToast } from '../../services/toast';

class EditFormulaCocktailsForm extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleAddIngredient = this.handleAddIngredient.bind(this);
    this.handleDeleteIngredient = this.handleDeleteIngredient.bind(this);
    this.handlePrintBom = this.handlePrintBom.bind(this);
    this.handleFormulaCocktailUpdate = this.handleFormulaCocktailUpdate.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);

    this.defaultState = {
      step: 1,
      selectedFormulaCocktail: null,
      saveClicked: false,
      name: null,
      description: null,
      wherefourId: null,
      cocktailMass: null,
      partNumber: null,
      productType: 'None',
      currentTab: 'formula_base',
      selectedIngredients: [],
      formulaCocktails: props.formulaCocktails,
      lastSortKey: '',
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // Sort
  handleSort(sortKey) {
    const { formulaCocktails, lastSortKey } = this.state;
    const sortedFormulaCocktails = (['id'].includes(sortKey))
      ? formulaCocktails.sort((a, b) => a[sortKey] - b[sortKey])
      : formulaCocktails.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      formulaCocktails: reverseSort ? sortedFormulaCocktails.reverse() : sortedFormulaCocktails,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Tabs
  handleTabChange(type) {
    $('.custom-tabs > div').removeClass('active');
    $(`#tab-${type}`).addClass('active');
    this.setState({ currentTab: type });
  }

  // Ingredients
  handleAddIngredient() {
    const { ingredients } = this.props;

    this.setState((prevState) => ({
      selectedIngredients: [...prevState.selectedIngredients, {
        ingredient_id: ingredients[0].id,
        mass_fraction: 0,
      }],
    }));
  }

  handleDeleteIngredient(index) {
    const { selectedIngredients } = this.state;

    this.setState({
      selectedIngredients: selectedIngredients.filter((e, i) => i !== index),
    });
  }

  // Step change
  handleStepChange(e, val = 1) {
    const { step } = this.state;
    if (e) e.preventDefault();
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Step 1 - FormulaCocktail select
  handleFormulaCocktailSelect(formulaCocktail) {
    this.setState({
      selectedFormulaCocktail: formulaCocktail,
      name: formulaCocktail.name,
      description: formulaCocktail.description,
      wherefourId: formulaCocktail.wherefour_id,
      cocktailMass: formulaCocktail.cocktail_mass,
      partNumber: formulaCocktail.part_number,
      productType: formulaCocktail.product_type,
      selectedIngredients: formulaCocktail.ingredients,
    }, this.handleStepChange);
  }

  handlePrintBom() {
    const { selectedFormulaCocktail } = this.state;
    api.formulaCocktails.generateCocktailBom({ cocktail: selectedFormulaCocktail.id }, `cocktail-BOM-${selectedFormulaCocktail.part_number}`);
  }

  // Step 2 - Update
  handleFormulaCocktailUpdate() {
    const {
      name, description, wherefourId, cocktailMass, currentTab, selectedIngredients, selectedFormulaCocktail,
      partNumber, productType,
    } = this.state;

    const data = {
      name,
      description,
      wherefour_id: wherefourId,
      cocktail_mass: currentTab === 'formula_base' ? null : cocktailMass,
      part_number: partNumber,
      product_type: productType === 'none' ? null : productType,
      formula_type: currentTab,
      ingredients: selectedIngredients,
    };

    api.editFormulaCocktails.update(data, selectedFormulaCocktail.id).then(() => this.setState({ saveClicked: true }, this.handleSuccess));
  }

  handleSuccess() {
    successToast('Success');
  }

  render() {
    const {
      step, currentTab, selectedIngredients, selectedFormulaCocktail,
      formulaCocktails, saveClicked,
    } = this.state;
    const { ingredients } = this.props;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            {selectedFormulaCocktail ? `Edit Formula Cocktail (#${selectedFormulaCocktail.id})` : 'View Formula Cocktails'}
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose Formula Cocktail
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Formula Cocktail Info
            </span>
          </div>
          <div className={`custom-tabs ${step > 1 ? 'disabled' : ''}`}>
            <div id="tab-formula_base" className="active" onClick={() => this.handleTabChange('formula_base')}>Base</div>
            <div id="tab-formula_treatment" onClick={() => this.handleTabChange('formula_treatment')}>Treatment</div>
            <div id="tab-formula_environment" onClick={() => this.handleTabChange('formula_environment')}>Environment</div>
          </div>
          {step === 1 && (
            <div>
              <div className="table-responsive">
                <table className="table table-striped table-responsive-sm custom-table">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => this.handleSort('id')}>ID</th>
                      <th scope="col" onClick={() => this.handleSort('part_number')}>Part Number</th>
                      <th scope="col" onClick={() => this.handleSort('name')}>Name</th>
                      <th scope="col" onClick={() => this.handleSort('description')}>Description</th>
                      <th scope="col" style={{ minWidth: '12rem' }}>Ingredients</th>
                      <th scope="col" onClick={() => this.handleSort('created_at')} style={{ minWidth: '12rem' }}>
                        Created /
                        <br />
                        Updated
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formulaCocktails.filter((i) => i.formula_type === currentTab).map((item) => (
                      <tr className="custom-tr-selectable" key={item.id} onClick={() => this.handleFormulaCocktailSelect(item)}>
                        <th scope="row">{item.id}</th>
                        <td>{item.part_number}</td>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td>
                          {item.ingredients.length > 0 ? (
                            item.ingredients.map((i, index) => <div key={i.ingredient_id}>{`#${index + 1}: ${i.ingredient.name}, ${i.mass_fraction}%`}</div>)
                          ) : 'no ingredients'}
                        </td>
                        <td>
                          {new Date(item.created_at).toLocaleString()}
                          <br />
                          {new Date(item.updated_at).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {step === 2 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFormulaCocktailUpdate();
              }}
              ref={(form) => { this.formStep2 = form; }}
            >
              <div className="form-group">
                <label htmlFor="name">
                  Name
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  defaultValue={selectedFormulaCocktail.name}
                  onChange={this.handleChange('name')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">
                  Description
                </label>
                <input
                  required
                  type="text"
                  name="description"
                  className="form-control"
                  id="description"
                  defaultValue={selectedFormulaCocktail.description}
                  onChange={this.handleChange('description')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="wherefour_id">
                  Wherefour ID
                </label>
                <input
                  type="number"
                  name="wherefour_id"
                  className="form-control"
                  id="wherefour_id"
                  defaultValue={selectedFormulaCocktail.wherefour_id}
                  onChange={this.handleChange('wherefourId')}
                />
              </div>
              {currentTab !== 'formula_base' && (
                <div className="form-group">
                  <label htmlFor="cocktail_mass">
                    Cocktail Mass
                  </label>
                  <input
                    type="number"
                    step="0.1"
                    name="cocktail_mass"
                    className="form-control"
                    id="cocktail_mass"
                    defaultValue={selectedFormulaCocktail.cocktail_mass}
                    onChange={this.handleChange('cocktailMass')}
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="productType">
                  Product Type
                </label>
                <select
                  id="productType"
                  name="productType"
                  defaultValue={selectedFormulaCocktail.product_type}
                  onChange={this.handleChange('productType')}
                >
                  <option>none</option>
                  <option>shampoo</option>
                  <option>conditioner</option>
                </select>
              </div>
              <div className="form-group mt-2">
                <div className="custom-inputs-row">
                  <div style={{ width: '70%' }}>
                    Ingredient
                  </div>
                  <div style={{ width: '30%' }}>
                    Mass fraction, %
                  </div>
                </div>
                {selectedIngredients.map((ingredient, index) => (
                  <div className="custom-inputs-row">
                    <select
                      id={`ingredientId-${index}`}
                      name={`ingredientId-${index}`}
                      data-testid="ingredient"
                      value={ingredient.ingredient_id}
                      style={{ width: '70%' }}
                      onChange={(e) => this.setState({
                        selectedIngredients: selectedIngredients.map((i, ind) => (ind === index
                          ? ({ ...i, ingredient_id: parseFloat(e.target.value) }) : i)),
                      })}
                    >
                      {ingredients.map((item) => (
                        <option
                          key={item.id}
                          label={`${item.vendor_name} (#${item.id})`}
                          value={item.id}
                        >
                          {item.id}
                        </option>
                      ))}
                    </select>
                    <div style={{ display: 'flex', width: '30%' }}>
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        max="100"
                        value={ingredient.mass_fraction}
                        name={`ingredient-fraction-${index}`}
                        className="form-control"
                        data-testid="ingredient-fraction"
                        id={`ingredient-fraction-${index}`}
                        onChange={(e) => this.setState({
                          selectedIngredients: selectedIngredients.map((i, ind) => (ind === index
                            ? ({ ...i, mass_fraction: parseFloat(e.target.value) }) : i)),
                        })}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.handleDeleteIngredient.bind(this, index)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="form-group custom-inputs-row">
                <div style={{ width: '70%' }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    id="btn-add-ingredient"
                    onClick={this.handleAddIngredient}
                  >
                    Add Ingredient
                  </button>
                </div>
                {selectedIngredients.length > 0 && (
                  <div id="ingredients-total" style={{ fontWeight: 500, fontSize: '1.25rem', width: '30%' }}>
                    {`TOTAL: ${selectedIngredients.reduce((accumulator, currentValue) => accumulator + currentValue.mass_fraction, 0).toFixed(2)}%`}
                  </div>
                )}
              </div>
              <div className="btn-group">
                <button
                  type="button"
                  id="print-bom"
                  className="btn btn-primary"
                  disabled={!saveClicked}
                  onClick={this.handlePrintBom}
                >
                  Print BOM
                </button>
              </div>
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={(e) => this.handleStepChange(e, -1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

EditFormulaCocktailsForm.propTypes = {
  ingredients: PropTypes.instanceOf(Array),
  formulaCocktails: PropTypes.instanceOf(Array),
};

EditFormulaCocktailsForm.defaultProps = {
  ingredients: [],
  formulaCocktails: [],
};

export default EditFormulaCocktailsForm;
