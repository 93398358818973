import { jsHumanize, formatDate } from './parsers';

export const handleBeforePrint = (label, code, trigger = true, additionalData = '', labelsCount = 1) => {
  // set content
  const marginBetweenLabels = 5;
  const additionalHeight = marginBetweenLabels * (labelsCount - 1);
  let content = '';

  for (let i = 0; i < labelsCount; i += 1) {
    const marginTop = i > 0 ? marginBetweenLabels : 0;
    content += `<div class="label-container" style="margin-top: ${marginTop}px">${label.qrcode}<div class="print-code"><div>${code}</div>${additionalData}</div></div>`;
  }

  $('#barcode-container').html(content);

  $('div.print-code').css('font-size', `${label.styles.height * label.styles.text_height}px`);
  $('div.print-code').css('margin-right', `${label.styles.margin}px`);

  $('#barcode-container').css('height', `${label.styles.height * labelsCount + additionalHeight}px`);
  $('#barcode-container').css('width', `${label.styles.width}px`);
  $('#barcode-container').css('margin-top', `${label.styles.margin_top}px`);
  $('#barcode-container').css('margin-left', `${label.styles.margin_top}px`);

  // update the styles
  $('style#barcodePrint').text(`
    @page {
      size: auto;
      margin: 0;
    }
    
    @media print {
      html {
        zoom: 22%;
        height: ${label.styles.height * labelsCount + additionalHeight}px;
        width: ${label.styles.width}px;
      }
    }
  `);

  // print
  if (trigger) $('#print-trigger').click();
};

export const codeWithoutSuffix = (code) => {
  const parts = code.split('-');
  return parts.length === 3 ? code.replace(`-${parts[2]}`, '') : code;
};

export const labelData = (customer) => {
  const firstName = (customer.first_name || '')[0];
  const lastName = customer.last_name || '';
  const firstI = firstName ? `${jsHumanize(firstName)}.` : '';
  const name = `${firstI} ${jsHumanize(lastName)}`;
  return `<div>${name.substr(0, 12)}</div><div>${formatDate(new Date())}</div>`;
};
