import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import CustomerInfoSection from '../common/CustomerInfoSection';
import Pagination from '../customElements/Pagination';
import ShopifyOrderLink from '../common/ShopifyOrderLink';
import { successToast } from '../../services/toast';
import callEvent from '../../services/events';
import { DISPLAY_EXPORT_OPTIONS_MODAL } from '../../constants';

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this);
    this.handleFetchCustomers = this.handleFetchCustomers.bind(this);
    this.handleOrderTabChange = this.handleOrderTabChange.bind(this);
    this.handleSubscriptionTabChange = this.handleSubscriptionTabChange.bind(this);
    this.handleSubscriptionCancelSubmit = this.handleSubscriptionCancelSubmit.bind(this);
    this.handleDownloadCSV = this.handleDownloadCSV.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.defaultState = {
      step: 1,
      activeCustomersCount: props.active_customers_count,
      canceledCustomersCount: props.canceled_customers_count,
      customers: props.customers,
      customerInfo: null,
      searchText: urlParams.get('search') || '',
      lastSortKey: urlParams.get('order') || 'subscriptions.created_at desc',
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
      currentOrderTab: 'subscription',
      currentSubscriptionTab: urlParams.get('status') || 'Active',
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchCustomers(null, order);
  }

  // Step change
  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Step 1 - customer select
  handleCustomerSelect(customer) {
    api.customers.subscriptionDetails({ id: customer.id })
      .then((res) => {
        const customerInfo = res;
        customerInfo.customer = customer;
        this.setState({ customerInfo }, this.handleStepChange);
      });
  }

  handleFetchCustomers(newPage, newOrder, newTab) {
    const {
      pagination, lastSortKey, searchText, currentSubscriptionTab,
    } = this.state;
    const page = newPage || pagination.currentPage || 1;
    const order = newOrder || lastSortKey;
    const tab = newTab || currentSubscriptionTab;
    window.history.pushState({}, null, `?page=${page}&order=${order}&search=${searchText}&status=${tab}`);

    api.customers.fetchSubscribed(page, order, searchText, tab)
      .then((res) => this.setState({
        customers: res.customers,
        activeCustomersCount: res.active_customers_count,
        canceledCustomersCount: res.canceled_customers_count,
        lastSortKey: order,
        currentSubscriptionTab: tab,
        pagination: {
          ...pagination,
          currentPage: page,
          isLastPage: res.is_last_page,
        },
      }));
  }

  handleOrderTabChange(tab) {
    const { customerInfo } = this.state;
    window.history.pushState({}, null, `?tab=${tab}`);

    api.customers.subscriptionDetails({ id: customerInfo.customer.id, tab })
      .then((res) => this.setState({ customerInfo: { ...customerInfo, orders: res.orders }, currentOrderTab: tab }));
  }

  handleSubscriptionTabChange(tab) {
    this.handleFetchCustomers(1, null, tab);
  }

  handleSubscriptionCancelSubmit() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetchCustomers);
  }

  handleDownloadCSV() {
    callEvent(
      DISPLAY_EXPORT_OPTIONS_MODAL,
      {
        handleSubmit: (data) => {
          api.customers.downloadCSV(data).then(() => alert('The data export has been requested. You will receive an email with download URL when it is completed'));
        },
      },
    );
  }

  render() {
    const { currentUser } = this.props;
    const {
      step, customers, customerInfo, searchText, pagination, currentOrderTab, currentSubscriptionTab,
      activeCustomersCount, canceledCustomersCount,
    } = this.state;
    const isActiveTab = currentSubscriptionTab === 'Active';
    const subProp = isActiveTab ? 'subscription' : 'last_canceled_subscription';

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block search-container">
            <div>{customerInfo ? `Subscriptions (${customerInfo.customer.email})` : 'Subscriptions'}</div>
            <form
              className={`search-block ${customerInfo ? 'hidden' : ''}`}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFetchCustomers();
              }}
            >
              <input
                type="text"
                defaultValue={searchText}
                placeholder="Search..."
                id="search"
                onChange={this.handleChange('searchText')}
              />
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose customer
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. View all orders
            </span>
          </div>
          {step === 1 && (
            <div>
              <div>
                <a href="#" className="csv-download" onClick={this.handleDownloadCSV}>Export as CSV</a>

                <div className="custom-tabs">
                  <div
                    id="tab-active"
                    onClick={() => this.handleSubscriptionTabChange('Active')}
                    className={currentSubscriptionTab === 'Active' ? 'active' : ''}
                  >
                    {`Active (${activeCustomersCount})`}
                  </div>
                  <div
                    id="tab-cancelled"
                    onClick={() => this.handleSubscriptionTabChange('Canceled')}
                    className={currentSubscriptionTab === 'Canceled' ? 'active' : ''}
                  >
                    {`Canceled (${canceledCustomersCount})`}
                  </div>
                </div>

                <table className="table table-striped table-responsive-sm custom-table mt-5">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => this.handleSort('customers.email')}>CUSTOMER</th>
                      <th scope="col" onClick={() => this.handleSort('subscriptions.sh_qty')}>PRODUCTS</th>
                      <th scope="col" onClick={() => this.handleSort('subscriptions.period')}>PERIOD</th>
                      <th scope="col" onClick={() => this.handleSort('subscriptions.created_at')}>CREATED</th>
                      {isActiveTab && <th scope="col" onClick={() => this.handleSort('subscriptions.next_billing_date')}>NEXT BILLING DATE</th>}
                      {!isActiveTab && <th scope="col" onClick={() => this.handleSort('subscriptions.canceled_at')}>CANCELED</th>}
                      {!isActiveTab && <th scope="col" onClick={() => this.handleSort('subscriptions.cancel_reason')}>Cancel Reason</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((item) => (
                      <tr className="custom-tr-selectable" key={item.id} onClick={() => this.handleCustomerSelect(item)}>
                        <td>{`${item.first_name} ${item.last_name}\n ${item.email}`}</td>
                        <td>{`${item[subProp].sh_qty} x SH\n${item[subProp].co_qty} x CO`}</td>
                        <td>{`${item[subProp].period} month(s)`}</td>
                        <td>{new Date(item[subProp].created_at).toLocaleString()}</td>
                        {isActiveTab && <td>{new Date(item[subProp].next_billing_date).toLocaleString()}</td>}
                        {!isActiveTab && <td>{new Date(item[subProp].canceled_at).toLocaleString()}</td>}
                        {!isActiveTab && <td>{item[subProp].cancel_reason}</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Pagination
                currentPage={pagination.currentPage}
                isLastPage={pagination.isLastPage}
                handlePageChange={this.handleFetchCustomers}
              />
            </div>
          )}
          {step === 2 && (
            <div>
              {customerInfo && <CustomerInfoSection currentUser={currentUser} customerInfo={customerInfo} subscriptionCancelSubmit={this.handleSubscriptionCancelSubmit} subscriptionType={currentSubscriptionTab} shouldRenderSubscriptionInfo shouldRenderCurrentQuestionnaire={Object.keys(customerInfo.questionnaire).length} />}
              {/* Tabs */}
              <div className="custom-tabs">
                <div
                  id="tab-subscription"
                  onClick={() => this.handleOrderTabChange('subscription')}
                  className={currentOrderTab === 'subscription' ? 'active' : ''}
                >
                  Subscription
                </div>
                <div
                  id="tab-other"
                  onClick={() => this.handleOrderTabChange('other')}
                  className={currentOrderTab === 'other' ? 'active' : ''}
                >
                  Other
                </div>
                <div
                  id="tab-all"
                  onClick={() => this.handleOrderTabChange('all')}
                  className={currentOrderTab === 'all' ? 'active' : ''}
                >
                  ALL
                </div>
              </div>
              <table className="table table-striped table-responsive-sm custom-table mt-5">
                <thead>
                  <tr>
                    <th scope="col">Order</th>
                    <th scope="col">Products</th>
                    <th scope="col">Type</th>
                    <th scope="col">Carrier</th>
                    <th scope="col">Label Code</th>
                    <th scope="col">Created</th>
                    <th scope="col">Updated</th>
                    <th scope="col">Status Changed</th>
                    <th scope="col">Age</th>
                    { currentOrderTab === 'subscription' && <th scope="col">Total Order Value</th> }
                    { currentOrderTab === 'subscription' && <th scope="col">Discounts</th> }
                  </tr>
                </thead>
                <tbody>
                  {customerInfo.orders.map((item) => (
                    <tr key={item.id}>
                      <th scope="row"><ShopifyOrderLink shopifyId={item.shopify_id}>{`#${item.shopify_number}`}</ShopifyOrderLink></th>
                      <td>{`${item.shampoo_qty} x SH $${item.unit_costs.shampoo}\n${item.conditioner_qty} x CO $${item.unit_costs.conditioner}`}</td>
                      <td>{item.order_type}</td>
                      <td>{item.default_carrier}</td>
                      <td>{item.code}</td>
                      <td>{new Date(item.created_at).toLocaleString()}</td>
                      <td>{new Date(item.updated_at).toLocaleString()}</td>
                      <td>{new Date(item.status_changed_at).toLocaleString()}</td>
                      <td>{item.age}</td>
                      { currentOrderTab === 'subscription' && <td>{`$${(item.subtotal || 0).toFixed(2)}`}</td> }
                      { currentOrderTab === 'subscription' && <td>{`$${(item.discount_amount || 0).toFixed(2)}`}</td> }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Subscriptions.propTypes = {
  active_customers_count: PropTypes.number.isRequired,
  canceled_customers_count: PropTypes.number.isRequired,
  customers: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
  currentUser: PropTypes.shape({}).isRequired,
};

Subscriptions.defaultProps = {
  customers: [],
  isLastPage: false,
};

export default Subscriptions;
