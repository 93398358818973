import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../customElements/Pagination';
import api from '../../services/api';

class RecordsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFetchRecords = this.handleFetchRecords.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      records: props.records,
      searchText: urlParams.get('search') || '',
      lastSortKey: urlParams.get('order') || 'optic_number asc',
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === `${sortKey} asc` ? ' desc' : ' asc'}`;

    this.handleFetchRecords(null, order);
  }

    handleChange = (name) => (event) => {
      this.setState({
        [name]: event.target.value,
      });
    };

    handleFetchRecords(newPage, newOrder) {
      const {
        pagination, lastSortKey, searchText,
      } = this.state;
      const page = newPage || pagination.currentPage || 1;
      const order = newOrder || lastSortKey;
      window.history.pushState({}, null, `?page=${page}&order=${order}&search=${searchText}`);

      api.quizDeviceIds.fetchRecords(page, order, searchText)
        .then((res) => this.setState({
          records: res.records,
          lastSortKey: order,
          pagination: {
            ...pagination,
            currentPage: page,
            isLastPage: res.is_last_page,
          },
        }));
    }

    render() {
      const {
        records, pagination, searchText,
      } = this.state;

      return (
        <div className="card">
          <div className="card-body custom-form-block">
            <div className="custom-info-block search-container">
              <div>Quiz Device Ids</div>
              <form
                className="search-block"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleFetchRecords();
                }}
              >
                <input
                  type="text"
                  defaultValue={searchText}
                  placeholder="Search..."
                  id="search"
                  onChange={this.handleChange('searchText')}
                />
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-responsive-sm custom-table">
                <thead>
                  <tr>
                    <th scope="col" onClick={() => this.handleSort('optic_number')}>ID</th>
                    <th scope="col" onClick={() => this.handleSort('count')}>Count</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((item) => (
                    <tr key={item.optic_number}>
                      <th scope="row">{item.optic_number || '-'}</th>
                      <td>{item.count || '0'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                currentPage={pagination.currentPage}
                isLastPage={pagination.isLastPage}
                handlePageChange={this.handleFetchRecords}
              />
            </div>
          </div>
        </div>
      );
    }
}

RecordsContainer.propTypes = {
  records: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

RecordsContainer.defaultProps = {
  records: [],
  isLastPage: false,
};

export default RecordsContainer;
