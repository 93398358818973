import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import PackingSlipPrint from './PackingSlipPrint';
import api from '../../services/api';
import isSGDiscountCode from '../../services/discounts';
import Pagination from '../customElements/Pagination';
import { successToast } from '../../services/toast';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

class GenericProductsFulfillment extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);
    this.handleGenerateShippingLabel = this.handleGenerateShippingLabel.bind(this);
    this.onPrintTableMount = this.onPrintTableMount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.handleFetchOrders = this.handleFetchOrders.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.defaultState = {
      step: 1,
      codeError: null,
      searchedOrder: null,
      selectedOrder: null,
      readyForPrint: false,
      labelUrl: null,
      shippoError: null,
      labelRequired: true,
      skipValidation: false,
      orderFulfilled: false,
      carrier: 'usps',
      currentTab: urlParams.get('tab') || 'pending',
      lastSortKey: urlParams.get('order') || 'created_at desc',
      searchText: urlParams.get('search') || '',
      orders: props.orders,
      pagination: {
        canceled: {
          currentPage: urlParams.get('tab') === 'canceled' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'canceled' && urlParams.get('page') ? props.isLastPage : null,
        },
        completed: {
          currentPage: urlParams.get('tab') === 'completed' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'completed' && urlParams.get('page') ? props.isLastPage : null,
        },
        refunded: {
          currentPage: urlParams.get('tab') === 'refunded' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'refunded' && urlParams.get('page') ? props.isLastPage : null,
        },
      },
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchOrders(null, null, order);
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Tabs
  handleTabChange(tab) {
    const { pagination } = this.state;
    const page = pagination[tab] && pagination[tab].currentPage ? pagination[tab].currentPage : 1;
    this.handleFetchOrders(page, tab);
  }

  // Step change
  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Step 1 - Order select
  handleOrderSelect(order) {
    this.setState({ selectedOrder: order }, this.handleStepChange);
  }

  // Step 2 - Print
  onPrintTableMount() {
    this.setState({ readyForPrint: true });
  }

  // Step 2 - Generate Shipping Label
  handleGenerateShippingLabel() {
    const { selectedOrder, skipValidation, carrier } = this.state;
    this.setState({ shippoError: null });
    this.generateShippingLabelTrigger.disabled = true;

    api.ordersFulfillment.generateShippingLabel({
      id: selectedOrder.id, parcel_type: 'generic-products', skip_validation: skipValidation, carrier,
    })
      .then((res) => this.setState({ labelUrl: res.label_url }))
      .catch((res) => {
        this.generateShippingLabelTrigger.disabled = false;
        this.setState({ shippoError: res.error });
      });
  }

  // Step 2 - Fulfill order and print shipping label
  printLabel() {
    const { labelUrl, selectedOrder, carrier } = this.state;
    window.open(labelUrl, '_blank', 'noopener,noreferrer');
    api.ordersFulfillment.fulfill({
      id: selectedOrder.id, test_kit: false, generic_products: true, carrier,
    }).then(this.setState({ orderFulfilled: true }));
  }

  // Step 2 - Submit
  handleSubmit() {
    const { selectedOrder, orderFulfilled, carrier } = this.state;

    if (orderFulfilled) {
      this.handleSuccess();
      return;
    }

    api.ordersFulfillment.fulfill({
      id: selectedOrder.id, test_kit: false, generic_products: true, carrier,
    }).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetchOrders);
  }

  handleFetchOrders(newPage, newTab, newOrder) {
    const {
      currentTab, pagination, lastSortKey, searchText,
    } = this.state;
    const tab = newTab || currentTab;
    const page = newPage || (pagination[currentTab] ? pagination[currentTab].currentPage : 1);
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?tab=${tab}&page=${page}&order=${order}&search=${searchText}`);

    api.ordersFulfillment.fetchGenericProductOrders(page, tab, order, searchText)
      .then((res) => this.setState({
        orders: res.orders,
        currentTab: tab,
        lastSortKey: order,
        pagination: {
          ...pagination,
          [tab]: {
            ...pagination[tab],
            currentPage: page,
            isLastPage: res.is_last_page,
          },
        },
      }));
  }

  render() {
    const {
      step, readyForPrint, selectedOrder, labelUrl, shippoError,
      orders, currentTab, pagination,
      labelRequired, skipValidation, searchText, carrier,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block search-container">
            <div>
              {selectedOrder
                ? (
                  <>
                    Products Fulfillment (
                    <ShopifyOrderLink shopifyId={selectedOrder.shopify_id}>{`#${selectedOrder.shopify_number}`}</ShopifyOrderLink>
                    )
                  </>
                ) : 'Products Fulfillment'}
            </div>
            <form
              className={`search-block ${selectedOrder ? 'hidden' : ''}`}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFetchOrders();
              }}
            >
              <input
                type="text"
                defaultValue={searchText}
                placeholder="Search..."
                id="search"
                onChange={this.handleChange('searchText')}
              />
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Select Order
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Get shipping label and packing slip
            </span>
          </div>
          {/* Tabs */}
          <div className={step === 1 ? 'custom-tabs' : 'disabled custom-tabs'}>
            <div
              id="tab-pending"
              onClick={() => this.handleTabChange('pending')}
              className={currentTab === 'pending' ? 'active' : ''}
            >
              Pending
            </div>
            <div
              id="tab-completed"
              onClick={() => this.handleTabChange('completed')}
              className={currentTab === 'completed' ? 'active' : ''}
            >
              Completed
            </div>
            <div
              id="tab-canceled"
              onClick={() => this.handleTabChange('canceled')}
              className={currentTab === 'canceled' ? 'active' : ''}
            >
              Canceled
            </div>
            <div
              id="tab-refunded"
              onClick={() => this.handleTabChange('refunded')}
              className={currentTab === 'refunded' ? 'active' : ''}
            >
              Refunded
            </div>
          </div>
          {step === 1 && (
            <div>
              <div>
                <table className="table table-striped table-responsive-sm custom-table mt-5">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => this.handleSort('shopify_number')}>Order</th>
                      <th scope="col" onClick={() => this.handleSort('customer_id')}>Customer</th>
                      <th scope="col">Products</th>
                      <th scope="col" onClick={() => this.handleSort('order_type')}>Type</th>
                      <th scope="col" onClick={() => this.handleSort('default_carrier')}>Carrier</th>
                      <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                      <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                      <th scope="col" onClick={() => this.handleSort('discount_code')}>Discount Code</th>
                      {currentTab === 'refunded' && (
                        <th scope="col" onClick={() => this.handleSort('financial_status')}>Financial Status</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((item) => (
                      <tr
                        className={`custom-tr-selectable ${item.customer_product_issues_count > 0 || isSGDiscountCode(item.discount_code) ? 'red-text-highlight' : ''}`}
                        key={item.id}
                        onClick={() => this.handleOrderSelect(item)}
                      >
                        <th scope="row"><ShopifyOrderLink shopifyId={item.shopify_id}>{`#${item.shopify_number}`}</ShopifyOrderLink></th>
                        <td>{item.customer && `[${item.customer.id}] ${item.customer.first_name} ${item.customer.last_name}\n ${item.customer.email}`}</td>
                        <td>{`${item.pillowcase_black_qty} x Pillowcase [black]\n${item.pillowcase_white_qty} x Pillowcase [white]\n${item.scalp_brush_qty} x Scalp Brush\n${item.pumps_qty} x Pump\n${item.scent_cards_qty} x Scent Cards\n${item.sebum_strips_qty} x Sebum Strip`}</td>
                        <td>{item.order_type}</td>
                        <td>
                          {item.default_carrier}
                          {' '}
                          {currentTab === 'completed' && item.shippo_labels.length > 0 && <a href={item.shippo_labels[item.shippo_labels.length - 1].label_url} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}><FontAwesomeIcon icon={faPrint} /></a>}
                        </td>
                        <td>{new Date(item.created_at).toLocaleString()}</td>
                        <td>{new Date(item.updated_at).toLocaleString()}</td>
                        <td>{item.discount_code}</td>
                        {currentTab === 'refunded' && (
                          <td>{item.financial_status}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {currentTab !== 'pending' && (
                <Pagination
                  currentPage={pagination[currentTab].currentPage}
                  isLastPage={pagination[currentTab].isLastPage}
                  handlePageChange={this.handleFetchOrders}
                />
              )}
            </div>
          )}
          {step === 2 && (
            <div>
              {/* PackingSlip Table */}
              <PackingSlipPrint
                onMount={this.onPrintTableMount}
                selectedOrder={selectedOrder}
                ref={(el) => { this.printContent = el; }}
              />
              {/* Print Packing Slip */}
              {readyForPrint && (
                <ReactToPrint
                  trigger={() => (
                    <button
                      type="button"
                      className="btn btn-secondary mt-4"
                    >
                      Print Packing Slip
                    </button>
                  )}
                  content={() => this.printContent}
                />
              )}
              {/* Generate / Print Shipping Label */}
              <div className="mt-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  id="btn-shipping-label"
                  ref={(el) => { this.generateShippingLabelTrigger = el; }}
                  onClick={this.handleGenerateShippingLabel}
                  disabled={!labelRequired}
                >
                  Generate Shipping Label
                </button>
                <select
                  id="carrier"
                  name="carrier"
                  value={carrier}
                  onChange={this.handleChange('carrier')}
                  disabled={currentTab === 'published'}
                  style={{
                    width: '5rem', height: '2rem', backgroundColor: 'unset', paddingLeft: '0.5rem', marginLeft: '4rem',
                  }}
                >
                  <option value="usps">USPS</option>
                  <option value="fedex">FedEx</option>
                </select>
                <div style={{ marginLeft: '4rem', display: 'inline' }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="skipValidation"
                    id="skipValidation"
                    defaultChecked={skipValidation}
                    onChange={() => this.setState((prevState) => ({ skipValidation: !prevState.skipValidation }))}
                  />
                  <label className="form-check-label" htmlFor="skipValidation">
                    Skip address validation
                  </label>
                </div>
              </div>
              <div className="mt-2" style={{ marginLeft: '1.5rem' }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="labelRequired"
                  id="labelRequired"
                  defaultChecked={!labelRequired}
                  onChange={() => this.setState((prevState) => ({ labelRequired: !prevState.labelRequired }))}
                />
                <label className="form-check-label" htmlFor="labelRequired">
                  No shipping label required
                </label>
              </div>
              {labelUrl && (
                <div className="mt-2">
                  <a className="btn btn-secondary print-shipping-label" onClick={this.printLabel}>
                    Print Shipping Label
                  </a>
                </div>
              )}
              {shippoError && (
                <div className="err mt-2 shippo-error">{`Shippo Error: ${shippoError}`}</div>
              )}
              {/* Submit */}
              <div className="btn-group custom-btn-continue mt-5">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={() => this.handleStepChange(-1)}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!labelUrl && labelRequired}
                  onClick={this.handleSubmit}
                >
                  Complete Order
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

GenericProductsFulfillment.propTypes = {
  orders: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

GenericProductsFulfillment.defaultProps = {
  orders: [],
  isLastPage: false,
};

export default GenericProductsFulfillment;
