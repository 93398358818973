import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import print from 'print-js';

import api from '../../services/api';
import ShopifyOrderLink from '../common/ShopifyOrderLink';
import Pagination from '../customElements/Pagination';
import { AUTO_FETCH_INTERVAL_TIMEOUT } from '../../constants';

class ShippingLabelsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handlePrintClick = this.handlePrintClick.bind(this);
    this.handleFetchShippingLabels = this.handleFetchShippingLabels.bind(this);
    this.handleAutoPrint = this.handleAutoPrint.bind(this);
    this.handleTimerTick = this.handleTimerTick.bind(this);

    const urlParams = new URL(window.location.href).searchParams;

    this.defaultState = {
      autoPrint: false,
      shippingLabels: props.shippingLabels,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      currentTab: urlParams.get('tab') || 'ReadyToPrint',
      pagination: {
        Completed: {
          currentPage: urlParams.get('tab') === 'Completed' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'Completed' && urlParams.get('page') ? props.isLastPage : null,
        },
      },
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.autoFetchInterval = setInterval(this.handleTimerTick, AUTO_FETCH_INTERVAL_TIMEOUT);
  }

  componentWillUnmount() {
    clearInterval(this.autoFetchInterval);
  }

  handleTimerTick() {
    this.handleFetchShippingLabels()
      .then(this.handleAutoPrint);
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchShippingLabels(null, null, order);
  }

  // Tabs
  handleTabChange(tab) {
    const { pagination } = this.state;
    const page = pagination[tab] && pagination[tab].currentPage ? pagination[tab].currentPage : 1;
    this.handleFetchShippingLabels(page, tab, null);
  }

  // print click
  handlePrintClick(e, id) {
    const { currentTab } = this.state;
    e.stopPropagation();

    if (currentTab === 'ReadyToPrint') {
      api.shippingLabels.complete({ id }).then(() => this.handleFetchShippingLabels());
    }
  }

  handleAutoPrint() {
    clearTimeout(this.autoPrintTimer);
    const { shippingLabels, currentTab, autoPrint } = this.state;
    if (shippingLabels.length === 0 || currentTab !== 'ReadyToPrint' || !autoPrint) return;
    const label = shippingLabels[0];

    api.shippingLabels.complete({ id: label.id })
      .then(() => print({ printable: `/shipping_labels/${label.id}.pdf` }))
      .then(() => this.handleFetchShippingLabels())
      .then(() => { this.autoPrintTimer = setTimeout(this.handleAutoPrint, 5000); });
  }

  // fetch
  handleFetchShippingLabels(newPage, newTab, newOrder) {
    const {
      currentTab, pagination, lastSortKey,
    } = this.state;
    const tab = newTab || currentTab;
    const page = newPage || (pagination[currentTab] ? pagination[currentTab].currentPage : 1);
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?tab=${tab}&page=${page}&order=${order}`);

    return new Promise((resolve, reject) => {
      api.shippingLabels.fetch(page, tab, order)
        .then((res) => this.setState({
          shippingLabels: res.shipping_labels,
          currentTab: tab,
          lastSortKey: order,
          pagination: {
            ...pagination,
            [tab]: {
              ...pagination[tab],
              currentPage: page,
              isLastPage: res.is_last_page,
            },
          },
        }, resolve))
        .catch(reject);
    });
  }

  render() {
    const {
      shippingLabels, currentTab, pagination, autoPrint,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block labels-container">
            <div>Labels Queue</div>
            <div>
              { currentTab === 'ReadyToPrint' && (
                <>
                  <input
                    type="checkbox"
                    id="auto-print-checkbox"
                    checked={autoPrint}
                    onChange={(e) => this.setState({ autoPrint: e.target.checked })}
                  />
                  <label htmlFor="auto-print-checkbox">Enable auto-printing</label>
                </>
              )}
            </div>
          </div>
          <div className="custom-tabs">
            <div
              id="tab-pending"
              onClick={() => this.handleTabChange('ReadyToPrint')}
              className={currentTab === 'ReadyToPrint' ? 'active' : ''}
            >
              Ready to Print
            </div>
            <div
              id="tab-completed"
              onClick={() => this.handleTabChange('Completed')}
              className={currentTab === 'Completed' ? 'active' : ''}
            >
              Completed
            </div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col" onClick={() => this.handleSort('orders.shopify_number')}>ORDER</th>
                <th scope="col" onClick={() => this.handleSort('orders.default_carrier')}>CARRIER</th>
                <th scope="col" onClick={() => this.handleSort('orders.customer_id')}>CUSTOMER</th>
                <th scope="col">PRODUCTS</th>
                <th scope="col" onClick={() => this.handleSort('created_at')}>CREATED</th>
              </tr>
            </thead>
            <tbody>
              {shippingLabels.map((item) => (
                <tr key={item.id}>
                  <th scope="row"><ShopifyOrderLink shopifyId={item.order.shopify_id}>{`#${item.order.shopify_number}`}</ShopifyOrderLink></th>
                  <td>
                    {item.order.default_carrier}
                    {' '}
                    <a href={item.label_url} rel="noreferrer" target="_blank" onClick={(e) => this.handlePrintClick(e, item.id)}><FontAwesomeIcon icon={faPrint} /></a>
                  </td>
                  <td>{item.order.customer && `${item.order.customer.first_name} ${item.order.customer.last_name}\n ${item.order.customer.email}`}</td>
                  <td>{`${item.order.shampoo_qty} x SH $${item.order.unit_costs.shampoo}\n${item.order.conditioner_qty} x CO $${item.order.unit_costs.conditioner}`}</td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {currentTab === 'Completed' && (
          <Pagination
            currentPage={pagination[currentTab].currentPage}
            isLastPage={pagination[currentTab].isLastPage}
            handlePageChange={this.handleFetchShippingLabels}
          />
        )}
      </div>
    );
  }
}

ShippingLabelsContainer.propTypes = {
  shippingLabels: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

ShippingLabelsContainer.defaultProps = {
  shippingLabels: [],
  isLastPage: false,
};

export default ShippingLabelsContainer;
