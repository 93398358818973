import React from 'react';
import PropTypes from 'prop-types';

class LabelContainer extends React.Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  render() {
    return (
      <div data-testid="barcode-container" id="barcode-container" />
    );
  }
}

LabelContainer.propTypes = {
  onMount: PropTypes.func,
};

LabelContainer.defaultProps = {
  onMount: () => {},
};

export default LabelContainer;
