import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_TAG_MODAL, DISPLAY_CONFIRMATION_MODAL } from '../../constants';

class TagsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);

    this.handleTagCreate = this.handleTagCreate.bind(this);
    this.handleTagEdit = this.handleTagEdit.bind(this);
    this.handleTagDestroy = this.handleTagDestroy.bind(this);

    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.callTagModal = this.callTagModal.bind(this);

    this.state = {
      tags: props.tags,
      filteredTags: props.tags,
      lastSortKey: '',
      filterId: '',
      filterName: '',
      filterTagType: '',
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { filteredTags, lastSortKey } = this.state;
    const sortedTags = sortKey === 'id'
      ? filteredTags.sort((a, b) => a[sortKey] - b[sortKey])
      : filteredTags.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      filteredTags: reverseSort ? sortedTags.reverse() : sortedTags,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  // Add/edit/delete
  handleTagCreate(data) {
    const { tags } = this.state;
    api.tags.create(data)
      .then((res) => this.setState({
        tags: [res, ...tags],
      }, () => this.handleAddFilters()));
  }

  handleTagEdit(data, id) {
    const { tags } = this.state;
    api.tags.update(data, id)
      .then((res) => this.setState({
        tags: tags.map((p) => (p.id === res.id ? res : p)),
      }, () => this.handleAddFilters()));
  }

  handleTagDestroy(id) {
    const { tags, filteredTags } = this.state;
    api.tags.destroy(id)
      .then((res) => {
        this.setState({
          tags: tags.filter((p) => p.id !== res.id),
          filteredTags: filteredTags.filter((p) => p.id !== res.id),
        });
      });
  }

  // onChange
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Filters
  handleAddFilters(e) {
    if (e) e.preventDefault();
    const {
      tags, filterId, filterName, filterTagType,
    } = this.state;
    let newTags = tags;

    if (filterId) {
      newTags = newTags.filter((p) => p.id.toString().startsWith(filterId.toString()));
    }
    if (filterName) {
      newTags = newTags.filter((p) => p.name.startsWith(filterName));
    }
    if (filterTagType) {
      newTags = newTags.filter((p) => p.tag_type.startsWith(filterTagType));
    }
    this.setState({ filteredTags: newTags });
  }

  clearFilters() {
    const { tags } = this.state;
    this.filtersForm.reset();
    this.setState({
      filteredTags: tags,
      filterId: '',
      filterName: '',
      filterTagType: '',
    });
  }

  // Modal
  callTagModal(additionalData) {
    callEvent(
      DISPLAY_TAG_MODAL,
      {
        handleSubmit: additionalData.type === 'create'
          ? this.handleTagCreate : this.handleTagEdit,
        additionalData,
      },
    );
  }

  render() {
    const { filteredTags } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <button
            type="button"
            id="custom-filters-toggle"
            className="btn btn-primary"
            onClick={() => $('#custom-filters').toggle(100)}
          >
            Toggle Filters
          </button>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterId">ID (startsWith)</label>
                <input
                  type="number"
                  className="form-control"
                  id="filterId"
                  placeholder="1234"
                  onChange={this.handleChange('filterId')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterName">Name (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterName"
                  placeholder="abcd"
                  onChange={this.handleChange('filterName')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterTagType">Tag Type (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterTagType"
                  placeholder="abcd"
                  onChange={this.handleChange('filterTagType')}
                />
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Tags</div>
            <div
              className="custom-table-header-right"
              onClick={() => this.callTagModal({ type: 'create' })}
            >
              + Add new
            </div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col" onClick={() => this.handleSort('id')}>Tag id</th>
                <th scope="col" onClick={() => this.handleSort('name')}>Name</th>
                <th scope="col" onClick={() => this.handleSort('tag_type')}>Tag Type</th>
                <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {filteredTags.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{item.name}</td>
                  <td>{item.tag_type}</td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                  <td>{new Date(item.updated_at).toLocaleString()}</td>
                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.callTagModal({
                          type: 'update',
                          name: item.name,
                          tag_type: item.tag_type,
                          id: item.id,
                        })}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleTagDestroy(item.id))}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

TagsContainer.propTypes = {
  tags: PropTypes.instanceOf(Array),
};

TagsContainer.defaultProps = {
  tags: [],
};

export default TagsContainer;
