import React from 'react';
import PropTypes from 'prop-types';

const EnterEnvDataModal = ({
  handleSubmit, handleReject,
}) => (
  <div
    className="modal fade"
    id="enterEnvDataModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="enterEnvDataModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="enterEnvDataModalLabel">Enter Env Data</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="form-group">
              <label className="mr-4" htmlFor="humidity">
                Humidity:
              </label>
              <input
                type="number"
                step="0.01"
                className="form-control"
                id="humidity"
                placeholder="50"
                required
              />
            </div>
            <div className="form-group">
              <label className="mr-4" htmlFor="uv">
                UV:
              </label>
              <input
                type="number"
                step="0.01"
                className="form-control"
                id="uv"
                placeholder="8"
                required
              />
            </div>
            <div className="form-group">
              <label className="mr-4" htmlFor="air_pollution">
                Air pollution:
              </label>
              <input
                type="number"
                step="0.01"
                className="form-control"
                id="air_pollution"
                placeholder="25"
                required
              />
            </div>
            <div className="form-group">
              <label className="mr-4" htmlFor="water_hardness">
                Water hardness:
              </label>
              <input
                type="number"
                step="0.01"
                className="form-control"
                id="water_hardness"
                placeholder="60"
                required
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={handleReject}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

EnterEnvDataModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
};

export default EnterEnvDataModal;
