import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../services/api';
import { USERS_ROLE_UPDATE } from '../../constants';

const UserModal = ({ handleSubmit, handleReject, additionalData }) => {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    api.roles.getAll().then((res) => {
      setRoles(res.roles);
    });
  }, []);

  return (
    <div
      className="modal fade custom-modal"
      id="UserModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="UserModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="UserModalLabel">
              { additionalData.type === 'create' ? 'Add User' : 'Edit User' }
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleSubmit(e, additionalData)}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="email">
                  Email
                </label>
                <input
                  required
                  type="email"
                  name="email"
                  defaultValue={additionalData.email}
                  className="form-control"
                  id="email"
                  placeholder="Enter email..."
                />
              </div>
              {additionalData.type === 'create' && (
                <div className="form-group">
                  <label htmlFor="password">
                    Password
                  </label>
                  <input
                    required
                    type="password"
                    name="password"
                    defaultValue={additionalData.password}
                    className="form-control"
                    id="password"
                    placeholder="Enter password..."
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="first_name">
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  defaultValue={additionalData.first_name}
                  className="form-control"
                  id="first_name"
                  placeholder="Enter first_name..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="last_name">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  defaultValue={additionalData.last_name}
                  className="form-control"
                  id="last_name"
                  placeholder="Enter last_name..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  defaultValue={additionalData.title}
                  className="form-control"
                  id="title"
                  placeholder="Enter title..."
                />
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select id="status" name="status" defaultValue={additionalData.status}>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>
              {additionalData.currentUser.roles[0].permissions.includes(USERS_ROLE_UPDATE) && (
                <div className="form-group">
                  <label htmlFor="role_id">Role</label>
                  <select id="role_id" name="role_id" defaultValue={additionalData.role_id}>
                    <option value="" />
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>{role.name}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleReject}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

UserModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
};

UserModal.defaultProps = {
  additionalData: {},
};

export default UserModal;
