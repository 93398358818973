import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_TEST_KIT_MODAL, DISPLAY_CONFIRMATION_MODAL, DISPLAY_REASSIGN_TEST_KIT_MODAL } from '../../constants';
import Pagination from '../customElements/Pagination';

class TestKitsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);
    this.handleTestKitCreate = this.handleTestKitCreate.bind(this);
    this.handleTestKitDestroy = this.handleTestKitDestroy.bind(this);
    this.handleTestKitEdit = this.handleTestKitEdit.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.callTestKitModal = this.callTestKitModal.bind(this);
    this.handleFetchTestKits = this.handleFetchTestKits.bind(this);
    this.handleTestKitReassign = this.handleTestKitReassign.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      testKits: props.testKits,
      products: props.products,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      filterId: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).id : '',
      filterProductId: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).product_id : '',
      filterStatus: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).status : 'Without Completed',
      filter: {},
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchTestKits(null, order);
  }

  // Add/edit/delete
  handleTestKitCreate(data) {
    const { testKits } = this.state;
    api.testKits.create(data)
      .then((res) => this.setState({
        testKits: [res, ...testKits],
      }));
  }

  handleTestKitEdit(data, id) {
    const { testKits } = this.state;
    api.testKits.update(data, id)
      .then((res) => this.setState({
        testKits: testKits.map((p) => (p.id === res.id ? res : p)),
      }));
  }

  handleTestKitDestroy(id) {
    const { testKits } = this.state;
    api.testKits.destroy(id)
      .then((res) => {
        this.setState({
          testKits: testKits.filter((p) => p.id !== res.id),
        });
      });
  }

  handleTestKitReassign(data, id) {
    const { testKits } = this.state;
    api.testKits.reassign(id, data)
      .then((res) => this.setState({
        testKits: testKits.map((p) => (p.id === res.id ? res : p)),
      }));
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const { filterId, filterProductId, filterStatus } = this.state;
    const filter = {};

    if (filterId) {
      filter.id = filterId;
    }
    if (filterProductId) {
      filter.product_id = filterProductId;
    }
    if (filterStatus && filterStatus !== 'Any') {
      filter.status = filterStatus;
    }

    this.setState({ filter }, () => this.handleFetchTestKits(1));
  }

  clearFilters() {
    if (this.filtersForm) this.filtersForm.reset();
    this.setState({
      filter: {},
      filterId: '',
      filterProductId: '',
      filterStatus: '',
    }, () => this.handleFetchTestKits(1));
  }

  // Modal
  callTestKitModal(additionalData) {
    callEvent(
      DISPLAY_TEST_KIT_MODAL,
      {
        handleSubmit: additionalData.type === 'create'
          ? this.handleTestKitCreate : this.handleTestKitEdit,
        additionalData,
      },
    );
  }

  callReassignTestKitModal(additionalData) {
    callEvent(
      DISPLAY_REASSIGN_TEST_KIT_MODAL,
      {
        handleSubmit: this.handleTestKitReassign,
        additionalData,
      },
    );
  }

  handleFetchTestKits(newPage, newOrder) {
    const { pagination, lastSortKey, filter } = this.state;
    const page = newPage || pagination.currentPage;
    const order = newOrder || lastSortKey;
    const params = `?page=${page}&order=${order}&filter=${JSON.stringify(filter)}`;
    window.history.pushState({}, null, params);

    api.testKits.fetchTestKits(params)
      .then((res) => this.setState({
        testKits: res.test_kits,
        lastSortKey: order,
        pagination: {
          ...pagination,
          currentPage: page,
          isLastPage: res.is_last_page,
        },
      }));
  }

  render() {
    const {
      testKits, products, pagination,
      filterId, filterProductId, filterStatus,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row ml-0">
            <button
              type="button"
              id="custom-filters-toggle"
              className="btn btn-primary"
              onClick={() => $('#custom-filters').toggle(100)}
            >
              Toggle Filters
            </button>
            <div className="ml-4">
              {`Current Filter: { ID: ${filterId || 'Any'}, ProductID: ${filterProductId || 'Any'}, Status: ${filterStatus || 'Any'} }`}
            </div>
          </div>
          <div data-testid="custom-filters" id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterId">ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterId"
                  placeholder="1234"
                  onChange={this.handleChange('filterId')}
                  value={filterId}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterProductId">Product ID</label>
                <select
                  id="filterProductId"
                  name="filterProductId"
                  onChange={this.handleChange('filterProductId')}
                  value={filterProductId}
                >
                  <option>Any</option>
                  {products.map((item) => (
                    <option
                      key={item.id}
                      label={`${item.title} (#${item.id})`}
                    >
                      {item.id}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="filterStatus">Status</label>
                <select
                  id="filterStatus"
                  name="filterStatus"
                  onChange={this.handleChange('filterStatus')}
                  value={filterStatus}
                >
                  <option>Any</option>
                  <option>Without Completed</option>
                  <option>ReadyToShip</option>
                  <option>Shipped</option>
                  <option>Received</option>
                  <option>Completed</option>
                  <option>Discarded</option>
                </select>
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">TestKits</div>
            <div
              className="custom-table-header-right"
              onClick={() => this.callTestKitModal({ type: 'create', products })}
            >
              + Add new
            </div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col" onClick={() => this.handleSort('id')}>TestKit id</th>
                <th scope="col">Product</th>
                <th scope="col">Customer</th>
                <th scope="col">Order</th>
                <th scope="col">Assigned</th>
                <th scope="col">Activated</th>
                <th scope="col" onClick={() => this.handleSort('status')}>Status</th>
                <th scope="col" onClick={() => this.handleSort('code')}>Code</th>
                <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {testKits.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{`[${item.product.id}] ${item.product.title}`}</td>
                  <td>{item.customer && `[${item.customer.id}] ${item.customer.first_name} ${item.customer.last_name}\n ${item.customer.email}`}</td>
                  <td>{item.order && (`[${item.order.id}]\n#${item.order.shopify_number}`)}</td>
                  <td>
                    <input
                      type="checkbox"
                      name={`assigned-${item.id}`}
                      id={`assigned-${item.id}`}
                      defaultChecked={!['ReadyToShip', 'Discarded'].includes(item.status) && item.customer}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name={`activated-${item.id}`}
                      id={`activated-${item.id}`}
                      defaultChecked={!['ReadyToShip', 'Discarded'].includes(item.status) && item.kit_activated}
                      disabled
                    />
                  </td>
                  <td>{item.status}</td>
                  <td>{item.code}</td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                  <td>{new Date(item.updated_at).toLocaleString()}</td>
                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.callReassignTestKitModal({
                          id: item.id,
                          customer: item.customer,
                        })}
                      >
                        Reassign
                      </button>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.callTestKitModal({
                          type: 'update',
                          product_id: item.product.id,
                          status: item.status,
                          id: item.id,
                          customer: item.customer,
                          products,
                        })}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleTestKitDestroy(item.id))}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={pagination.currentPage}
          isLastPage={pagination.isLastPage}
          handlePageChange={this.handleFetchTestKits}
        />
      </div>
    );
  }
}

TestKitsContainer.propTypes = {
  products: PropTypes.instanceOf(Array),
  testKits: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

TestKitsContainer.defaultProps = {
  products: [],
  testKits: [],
  isLastPage: false,
};

export default TestKitsContainer;
