import React from 'react';

import PackingSlipPrint from '../orders/PackingSlipPrint';
import api from '../../services/api';
import codeErrors from '../../services/codeValidators';
import { successToast, errorToast } from '../../services/toast';

const defaultState = {
  customer: null,
  codeError: null,
  allowAccessioning: false,
  testKitId: null,
  order: null,
  questionnaire: null,
  hasMultipleTestKits: false,
};

class TestAccessioningForm extends React.Component {
  constructor(props) {
    super(props);

    this.verifyCode = this.verifyCode.bind(this);
    this.clearData = this.clearData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = defaultState;
  }

  // code
  verifyCode(e) {
    const code = e.target.value;
    const error = codeErrors(code);
    if (error) return this.setState({ codeError: error, allowAccessioning: false });

    return api.addCustomerTests.codeVerification({ code, status: 'Shipped', postfix_array: ['-TK'] })
      .then((res) => {
        let codeError = null;
        let allowAccessioning = true;
        if (res.err) {
          codeError = res.err;
          allowAccessioning = false;
        } else if (res.order && res.order.status === 'Canceled') codeError = 'Order is canceled!';
        else if (res.order && res.order.financial_status === 'refunded') codeError = 'Order is refunded!';
        else if (res.order && res.order.refund_issued) codeError = 'Order is partially refunded!';

        if (codeError) errorToast(codeError, '');

        this.setState({
          customer: res.customer,
          codeError,
          allowAccessioning,
          testKitId: res.test_kit_id,
          hasMultipleTestKits: res.has_multiple_test_kits,
          order: res.order,
          questionnaire: res.questionnaire,
        });
      });
  }

  clearData() {
    this.setState(defaultState, () => {
      this.codeInput.value = null;
    });
  }

  handleSubmit() {
    const { testKitId } = this.state;
    api.addCustomerTests.receive({ test_kit_id: testKitId })
      .then(() => {
        successToast('Success');
        window.scrollTo(0, 0);
        this.clearData();
      });
  }

  render() {
    const {
      codeError, customer, order, questionnaire, allowAccessioning, hasMultipleTestKits,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            Test Accessioning
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              id="code"
              name="code"
              className="form-control"
              onChange={this.verifyCode}
              ref={(input) => { this.codeInput = input; }}
            />
            {codeError && (
              <div data-testid="code-error" className="input-error">{codeError}</div>
            )}
          </div>
          {customer && (
            <div>
              <PackingSlipPrint
                selectedOrder={order}
                noHeader={true}
                noShipping={true}
                noTable={true}
              />

              <div>
                <div style={{ fontWeight: 'bold' }}>Customer info:</div>
                <div>{`Email: ${customer.email}`}</div>
                <div>{`First Name: ${customer.first_name}`}</div>
                <div>{`Last Name: ${customer.last_name}`}</div>
                { hasMultipleTestKits && <div className="text-danger">This customer has another test kit linked</div> }
              </div>

              <div className="mt-5">
                <div style={{ fontWeight: 'bold' }}>Customer questionnaire:</div>
                {Object.entries(questionnaire).map((hash) => <div key={hash[0]}>{`${hash[0]}: ${hash[1]}`}</div>)}
              </div>
            </div>
          )}
          <div className="btn-group custom-btn-continue mt-5">
            <button
              disabled={!customer}
              type="button"
              className="btn btn-secondary btn-back"
              onClick={this.clearData}
            >
              Cancel
            </button>
            <button
              disabled={!customer || !allowAccessioning}
              type="button"
              className="btn btn-primary"
              onClick={this.handleSubmit}
            >
              Accession
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TestAccessioningForm;
