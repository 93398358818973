import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';

import api from '../../services/api';
import { handleBeforePrint, codeWithoutSuffix, labelData } from '../../services/print';
import codeErrors from '../../services/codeValidators';
import LabelContainer from '../label/LabelContainer';
import { successToast } from '../../services/toast';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

class SampleWeighingQueueForm extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.handleTestKitSort = this.handleTestKitSort.bind(this);
    this.handleTestKitSelect = this.handleTestKitSelect.bind(this);
    this.onPrintMount = this.onPrintMount.bind(this);
    this.generateQC = this.generateQC.bind(this);
    this.calculateQC = this.calculateQC.bind(this);
    this.updateSampleWeight = this.updateSampleWeight.bind(this);
    this.handleCustomerTestSave = this.handleCustomerTestSave.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.generateCsvData = this.generateCsvData.bind(this);

    this.defaultState = {
      step: 1,
      selectedTestKit: null,
      highlightedTestKitId: null,
      codeError: null,
      sampleWeight: null,
      qc: null,
      labels: [],
      readyForPrint: false,
      lastSortKey: '',
      testKits: props.testKits,
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // Sort
  handleSort(sortKey) {
    const { testKits, lastSortKey } = this.state;
    const sortedTestKits = (sortKey === 'id')
      ? testKits.sort((a, b) => a[sortKey] - b[sortKey])
      : testKits.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      testKits: reverseSort ? sortedTestKits.reverse() : sortedTestKits,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  // Step change
  handleStepChange(e, val = 1) {
    const { step } = this.state;
    if (e) e.preventDefault();
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // code
  verifyCode(e) {
    const code = e.target.value;
    const error = codeErrors(code);
    if (error) return this.setState({ codeError: error });

    return api.addCustomerTests.codeVerification({
      code,
      status: 'Received',
      postfix_array: ['-TK'],
    })
      .then((res) => {
        this.setState({
          codeError: res.err,
          highlightedTestKitId: res.test_kit_id,
        });
      });
  }

  handleTestKitSort(x, y) {
    const { highlightedTestKitId } = this.state;

    if (x.id === highlightedTestKitId) {
      return -1;
    }
    if (y.id === highlightedTestKitId) {
      return 1;
    }
    return 0;
  }

  handleTestKitSelect(item) {
    api.print.allLabels({ code: item.code, label_type: 'test_kit', postfix_array: ['SS'] })
      .then((res) => this.setState({
        labels: res.labels,
        selectedTestKit: item,
        sampleWeight: item.sample_weight,
        qc: this.calculateQC(item.sample_weight),
      }, this.handleStepChange));
  }

  // print
  onPrintMount() {
    this.setState({ readyForPrint: true });
  }

  // Step 2 - formula
  generateQC(e) {
    const { value } = e.target;

    this.setState({
      qc: this.calculateQC(value),
      sampleWeight: value,
    });
  }

  calculateQC(value) {
    if (!value) return '';
    return value < 75 ? 'Fail' : 'Pass';
  }

  // Step 2 - update sample weight
  updateSampleWeight() {
    const { selectedTestKit, sampleWeight } = this.state;

    return api.testKits.update({ sample_weight: sampleWeight }, selectedTestKit.id).then(this.handleSuccess);
  }

  // Step 2 - Accept
  handleCustomerTestSave(e) {
    if (e) e.preventDefault();

    const { selectedTestKit, sampleWeight } = this.state;

    const data = {
      test_kit_id: selectedTestKit.id,
      customer_id: selectedTestKit.customer.id,
      result_avg_sample_weight: sampleWeight,
    };

    api.addCustomerTests.save(data).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetch);
  }

  handleFetch() {
    api.addCustomerTests.fetchWeighing().then((res) => this.setState({ testKits: res.test_kits }));
  }

  generateCsvData() {
    const { testKits } = this.state;

    return [
      ['Test Kit', 'Email', 'First Name', 'Last Name', 'Order', 'Weight', 'Created', 'Updated', 'Status Changed', 'Age'],
      ...testKits.map((item) => [item.code, (item.customer || {}).email, (item.customer || {}).first_name, (item.customer || {}).last_name, `#${(item.order || {}).shopify_number}`, `${item.sample_weight} mg`, new Date(item.created_at).toLocaleString(), new Date(item.updated_at).toLocaleString(), new Date(item.status_changed_at).toLocaleString(), item.age]),
    ];
  }

  render() {
    const {
      step, qc, sampleWeight, codeError, highlightedTestKitId, readyForPrint,
      labels, testKits, selectedTestKit,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            Sample Weighing
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose Test Kit
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Test Data
            </span>
          </div>
          {step === 1 && (
            <div>
              <div className="form-group input-medium">
                <label htmlFor="code">Code</label>
                <input
                  id="code"
                  name="code"
                  className="form-control"
                  onChange={this.verifyCode}
                />
                {codeError && (
                  <div data-testid="code-error" className="input-error">{codeError}</div>
                )}
              </div>
              <CSVLink className="csv-download" data={this.generateCsvData()}>Export as CSV</CSVLink>
              <table className="table table-striped table-responsive-sm custom-table mt-5">
                <thead>
                  <tr>
                    <th scope="col" onClick={() => this.handleSort('code')}>Test Kit</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Order</th>
                    <th scope="col" onClick={() => this.handleSort('sample_weight')}>Weight</th>
                    <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                    <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                    <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Status Changed</th>
                    <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Age</th>
                  </tr>
                </thead>
                <tbody>
                  {testKits.sort(this.handleTestKitSort).map((item) => (
                    <tr
                      key={item.id}
                      className={['custom-tr-selectable',
                        `${item.id === highlightedTestKitId ? 'highlighted' : ''}`,
                        `${this.calculateQC(item.sample_weight).toLowerCase() !== 'pass' ? 'underweight' : ''}`].join(' ')}
                      onClick={() => this.handleTestKitSelect(item)}
                    >
                      <th scope="row">{item.code}</th>
                      <td>{item.customer && `${item.customer.first_name} ${item.customer.last_name}\n ${item.customer.email}`}</td>
                      <td>{item.order && (<ShopifyOrderLink shopifyId={item.order.shopify_id}>{`#${item.order.shopify_number}`}</ShopifyOrderLink>)}</td>
                      <td>{`${item.sample_weight} mg`}</td>
                      <td>{new Date(item.created_at).toLocaleString()}</td>
                      <td>{new Date(item.updated_at).toLocaleString()}</td>
                      <td>{new Date(item.status_changed_at).toLocaleString()}</td>
                      <td>{item.age}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {step === 2 && (
            <form onSubmit={this.handleCustomerTestSave} ref={(form) => { this.formStep2 = form; }}>
              <div className="form-group">
                <div className="custom-2-blocks-row custom-2-blocks-row-header">
                  <div>
                    Sample Weight, mg
                  </div>
                  <div>
                    QC Pass/Fail
                  </div>
                </div>
                <div className="custom-2-blocks-row">
                  <input
                    required
                    defaultValue={sampleWeight}
                    onChange={this.generateQC}
                    type="number"
                    step="0.001"
                    min="0"
                    max="1000"
                    name="sampleWeight"
                    className="form-control"
                    id="sampleWeight"
                    data-testid="sampleWeight"
                  />
                  <div id="label-sampleWeight">{qc}</div>
                </div>
              </div>
              <div className="custom-hidden">
                <LabelContainer
                  onMount={this.onPrintMount}
                  ref={(el) => { this.printContent = el; }}
                />
                {readyForPrint && (
                  <ReactToPrint
                    trigger={() => (
                      <button
                        type="button"
                        className="btn btn-secondary"
                        id="print-trigger"
                      />
                    )}
                    content={() => this.printContent}
                  />
                )}
              </div>
              <div className="btn-group custom-labels-print-btns mt-5">
                {labels.map((label) => (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    key={label.code}
                    onClick={() => handleBeforePrint(label, codeWithoutSuffix(label.code), true, labelData(selectedTestKit.customer))}
                  >
                    {label.name}
                  </button>
                ))}
              </div>
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={(e) => this.handleStepChange(e, -1)}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.updateSampleWeight}
                >
                  Save
                </button>
                <button
                  type="submit"
                  disabled={qc.toLowerCase() !== 'pass'}
                  className="btn btn-primary"
                >
                  Accept
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

SampleWeighingQueueForm.propTypes = {
  testKits: PropTypes.instanceOf(Array),
};

SampleWeighingQueueForm.defaultProps = {
  testKits: [],
};

export default SampleWeighingQueueForm;
