import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const ExportOptionsModal = ({
  handleSubmit, handleReject,
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  return (
    <div
      className="modal fade"
      id="exportOptionsModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exportOptionsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exportOptionsModalLabel">Export Options</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label className="mr-4" htmlFor="start_date">Start Date</label>
                <DatePicker id="start_date" selected={startDate} onChange={setStartDate} />
              </div>
              <div className="form-group">
                <label className="mr-4" htmlFor="end_date">End Date</label>
                <DatePicker id="end_date" selected={endDate} onChange={setEndDate} />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleReject}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ExportOptionsModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
};

ExportOptionsModal.defaultProps = {
};

export default ExportOptionsModal;
