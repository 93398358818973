import React from 'react';
import PropTypes from 'prop-types';
import { GetProductType } from '../../constants';
import { formatDate } from '../../services/parsers';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

class PackingSlipPrint extends React.Component {
  constructor(props) {
    super(props);

    this.calculateSubTotal = this.calculateSubTotal.bind(this);
  }

  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  calculateSubTotal() {
    const { selectedOrder } = this.props;
    return selectedOrder.items.reduce((partialSum, a) => partialSum + a.quantity * parseFloat(a.price), 0);
  }

  render() {
    const {
      selectedOrder, productInputs, checkTestKitCode, noHeader, noShipping, noTable,
      disabledInputs, variantsToCheck,
    } = this.props;
    const shippingAddress = selectedOrder.shipping_address;

    return (
      <div id="packing-slip-container">
        <div id="print-logo" className="print-content mt-3" />
        {!noHeader && (
          <div className="packing-slip-header">
            {'Packing Slip for Order '}
            <ShopifyOrderLink shopifyId={selectedOrder.shopify_id}>{`#${selectedOrder.shopify_number}`}</ShopifyOrderLink>
          </div>
        )}
        <div className="packing-slip-content">
          <div className="packing-slip-subheader">
            <div className="packing-slip-subheader-left">
              <div className="bold">{`${selectedOrder.billing_address.first_name} ${selectedOrder.billing_address.last_name}`}</div>
              <div>{selectedOrder.billing_address.address1}</div>
              {selectedOrder.billing_address.address2 && <div>{selectedOrder.billing_address.address2}</div>}
              <div>{`${selectedOrder.billing_address.city}, ${selectedOrder.billing_address.province_code} ${selectedOrder.billing_address.zip}`}</div>
              <div>{selectedOrder.billing_address.country_code}</div>
            </div>
            <div className="packing-slip-subheader-right">
              <div>
                <div className="bold">Order ID:</div>
                <div className="bold">Order Date:</div>
              </div>
              <div>
                <div><ShopifyOrderLink shopifyId={selectedOrder.shopify_id}>{`#${selectedOrder.shopify_number}`}</ShopifyOrderLink></div>
                <div>{formatDate(new Date(selectedOrder.created_at))}</div>
              </div>
            </div>
          </div>
          {!noShipping && (
            <div className="packing-slip-ship-to">
              <div className="bold">Ship To:</div>
              <div>
                <div className="bold">{`${shippingAddress.first_name} ${shippingAddress.last_name}`}</div>
                <div>{shippingAddress.address1}</div>
                {shippingAddress.address2 && <div>{shippingAddress.address2}</div>}
                <div>{`${shippingAddress.city}, ${shippingAddress.province_code} ${shippingAddress.zip}`}</div>
                <div>{shippingAddress.country_code}</div>
              </div>
            </div>
          )}
          {!noTable && (
            <table>
              <thead>
                <tr>
                  <th scope="col">ITEMS</th>
                  <th scope="col">QTY</th>
                  <th scope="col">WEIGHT</th>
                  <th scope="col">PRICE</th>
                  <th scope="col">TOTAL</th>
                  {productInputs && (
                    <th scope="col">CODE</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {selectedOrder.items.map((item) => (
                  <tr className="product-row" key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>{`${((item.grams || 0.0) / 453.592).toFixed(2)} lbs`}</td>
                    <td>{`$ ${item.price}`}</td>
                    <td>{`$ ${item.quantity * parseFloat(item.price)}`}</td>
                    {productInputs && (
                      <td width="40%">
                        {variantsToCheck.includes(item.variant_id) && (
                          <div className="form-group">
                            <input
                              type="text"
                              name="code"
                              className="form-control"
                              id={`code-${item.id}`}
                              disabled={disabledInputs}
                              onChange={(event) => checkTestKitCode(event, `code-${item.id}`, GetProductType(item) === 'shampoo' ? '-SO' : '-CO')}
                            />
                            <div className="input-error" />
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
                <tr className="totals">
                  <td />
                  <td />
                  <td />
                  <td className="totals-label">Subtotal:</td>
                  <td>{`$ ${this.calculateSubTotal()}`}</td>
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td className="totals-label">Tax:</td>
                  <td>$ 0</td>
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td className="totals-label">Shipping:</td>
                  <td>{`$ ${selectedOrder.shipping_price}`}</td>
                  <td />
                </tr>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td className="bold totals-label">Total:</td>
                  <td className="bold">{`$ ${this.calculateSubTotal() + selectedOrder.shipping_price}`}</td>
                  <td />
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

PackingSlipPrint.propTypes = {
  selectedOrder: PropTypes.instanceOf(Object).isRequired,
  onMount: PropTypes.func,
  productInputs: PropTypes.bool,
  noHeader: PropTypes.bool,
  noShipping: PropTypes.bool,
  noTable: PropTypes.bool,
  checkTestKitCode: PropTypes.func,
  disabledInputs: PropTypes.bool,
  variantsToCheck: PropTypes.instanceOf(Array),
};

PackingSlipPrint.defaultProps = {
  onMount: () => {},
  productInputs: false,
  noHeader: false,
  noShipping: false,
  noTable: false,
  disabledInputs: false,
  checkTestKitCode: () => {},
  variantsToCheck: [],
};

export default PackingSlipPrint;
