import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_CONFIRMATION_MODAL } from '../../constants';
import Pagination from '../customElements/Pagination';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

class ProductFormulasContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleProductFormulaDestroy = this.handleProductFormulaDestroy.bind(this);
    this.handleFetchProductFormulas = this.handleFetchProductFormulas.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      productFormulas: props.productFormulas,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      filterId: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).id : '',
      filterType: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).product_type : 'Any',
      filterStatus: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).status : 'Without Published',
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchProductFormulas(null, order);
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const { filterId, filterType, filterStatus } = this.state;
    const filter = {};

    if (filterId) {
      filter.id = filterId;
    }
    if (filterType && filterType !== 'Any') {
      filter.product_type = filterType;
    }
    if (filterStatus && filterStatus !== 'Any') {
      filter.status = filterStatus;
    }

    this.setState({ filter }, () => this.handleFetchProductFormulas(1));
  }

  clearFilters() {
    this.filtersForm.reset();
    this.setState({
      filter: {},
      filterId: '',
      filterType: '',
      filterStatus: '',
    }, () => this.handleFetchProductFormulas(1));
  }

  // api
  handleProductFormulaDestroy(id) {
    const { productFormulas } = this.state;
    api.productFormulas.destroy(id)
      .then((res) => {
        this.setState({
          productFormulas: productFormulas.filter((i) => i.id !== res.id),
        });
      });
  }

  handleFetchProductFormulas(newPage, newOrder) {
    const { pagination, lastSortKey, filter } = this.state;
    const page = newPage || pagination.currentPage;
    const order = newOrder || lastSortKey;
    const params = `?page=${page}&order=${order}&filter=${JSON.stringify(filter)}`;
    window.history.pushState({}, null, params);

    api.productFormulas.fetchProductFormulas(params)
      .then((res) => this.setState({
        productFormulas: res.product_formulas,
        lastSortKey: order,
        pagination: {
          ...pagination,
          currentPage: page,
          isLastPage: res.is_last_page,
        },
      }));
  }

  render() {
    const {
      productFormulas, pagination,
      filterId, filterStatus, filterType,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body">
          <div className="row ml-0">
            <button
              type="button"
              id="custom-filters-toggle"
              className="btn btn-primary"
              onClick={() => $('#custom-filters').toggle(100)}
            >
              Toggle Filters
            </button>
            <div className="ml-4">
              {`Current Filter: { ID: ${filterId || 'Any'}, Type: ${filterType || 'Any'}, Status: ${filterStatus || 'Any'} }`}
            </div>
          </div>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterId">ID</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterId"
                  placeholder="123"
                  onChange={this.handleChange('filterId')}
                  value={filterId}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterType">Type</label>
                <select
                  id="filterType"
                  name="filterType"
                  onChange={this.handleChange('filterType')}
                  value={filterType}
                >
                  <option>Any</option>
                  <option>shampoo</option>
                  <option>conditioner</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="filterStatus">Status</label>
                <select
                  id="filterStatus"
                  name="filterStatus"
                  onChange={this.handleChange('filterStatus')}
                  value={filterStatus}
                >
                  <option>Any</option>
                  <option>Without Published</option>
                  <option>InProcess</option>
                  <option>Published</option>
                </select>
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Product Formula</div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm custom-table">
              <thead>
                <tr>
                  <th scope="col" onClick={() => this.handleSort('id')}>Product Formula ID</th>
                  <th scope="col" onClick={() => this.handleSort('product_type')}>Type</th>
                  <th scope="col" onClick={() => this.handleSort('status')}>Status</th>
                  <th scope="col" onClick={() => this.handleSort('order_id')}>Shopify Order</th>
                  <th scope="col">Base Cocktail</th>
                  <th scope="col">Treatment Cocktail</th>
                  <th scope="col">Environment Cocktail</th>
                  <th scope="col" onClick={() => this.handleSort('base_fraction')}>Base Fraction</th>
                  <th scope="col" onClick={() => this.handleSort('treatment_fraction')}>Treatment Fraction</th>
                  <th scope="col" onClick={() => this.handleSort('environment_fraction')}>Environment Fraction</th>
                  <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                  <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {productFormulas.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.product_type}</td>
                    <td>{item.status}</td>
                    <td><ShopifyOrderLink shopifyId={item.order.shopify_id}>{`#${item.order.shopify_number}`}</ShopifyOrderLink></td>
                    <td>{`#${item.base_cocktail.id} - ${item.base_cocktail.name}`}</td>
                    <td>{`#${item.treatment_cocktail.id} - ${item.treatment_cocktail.name}`}</td>
                    <td>{`#${item.environment_cocktail.id} - ${item.environment_cocktail.name}`}</td>
                    <td>{item.base_fraction}</td>
                    <td>{item.treatment_fraction}</td>
                    <td>{item.environment_fraction}</td>
                    <td>{new Date(item.created_at).toLocaleString()}</td>
                    <td>{new Date(item.updated_at).toLocaleString()}</td>
                    <td>
                      <div className="btn-group" role="group" aria-label="">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleProductFormulaDestroy(item.id))}
                        >
                          Remove
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={pagination.currentPage}
            isLastPage={pagination.isLastPage}
            handlePageChange={this.handleFetchProductFormulas}
          />
        </div>
      </div>
    );
  }
}

ProductFormulasContainer.propTypes = {
  productFormulas: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

ProductFormulasContainer.defaultProps = {
  productFormulas: [],
  isLastPage: false,
};

export default ProductFormulasContainer;
