import React from 'react';
import PropTypes from 'prop-types';

const TiktokUrlModal = ({
  handleSubmit,
  handleReject,
  additionalData,
  updateAdditionalData,
}) => {
  const handleChangeUrl = (e) => {
    const url = e.target.value;

    updateAdditionalData({
      ...additionalData,
      url,
    });
  };

  const handleChangeName = (e) => {
    const name = e.target.value;

    updateAdditionalData({
      ...additionalData,
      author_name: name,
    });
  };

  const handleChangeHandle = (e) => {
    const handle = e.target.value;

    updateAdditionalData({
      ...additionalData,
      author_handle: handle,
    });
  };

  const handleChangeBrand = (e) => {
    const handle = e.target.value;

    updateAdditionalData({
      ...additionalData,
      brand: handle,
    });
  };

  return (
    <div
      className="modal fade custom-modal"
      id="TiktokUrlModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="RoleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="RoleModalLabel">
              { additionalData.type === 'create' ? 'Add Tiktok Url' : 'Edit Tiktok Url' }
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleSubmit(e, additionalData)}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="url">
                  Tiktok Url
                </label>
                <input
                  type="url"
                  name="url"
                  value={additionalData.url}
                  className="form-control"
                  id="url"
                  placeholder="Enter tiktok url..."
                  onChange={handleChangeUrl}
                />
              </div>

              <div className="form-group">
                <label htmlFor="author_name">
                  Name
                </label>
                <input
                  type="text"
                  name="author_name"
                  value={additionalData.author_name}
                  className="form-control"
                  id="author_name"
                  placeholder="Enter author name..."
                  onChange={handleChangeName}
                />
              </div>

              <div className="form-group">
                <label htmlFor="author_handle">
                  Handle
                </label>
                <input
                  type="text"
                  name="author_handle"
                  value={additionalData.author_handle}
                  className="form-control"
                  id="author_handle"
                  placeholder="Enter author handle..."
                  onChange={handleChangeHandle}
                />
              </div>

              <div className="form-group">
                <label htmlFor="brand">
                  Brand
                </label>
                <select
                  name="brand"
                  value={additionalData.brand || ''}
                  className="form-control"
                  id="brand"
                  placeholder="Enter brand..."
                  onChange={handleChangeBrand}
                >
                  <option value="" />
                  <option value="thl">The Hair Lab</option>
                  <option value="strands">Strands Hair Care</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleReject}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

TiktokUrlModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
  updateAdditionalData: PropTypes.func.isRequired,
};

TiktokUrlModal.defaultProps = {
  additionalData: {},
};

export default TiktokUrlModal;
