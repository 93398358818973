import React from 'react';
import PropTypes from 'prop-types';
import ActiveStorageProvider from 'react-activestorage-provider';
import Select from 'react-select';

import api from '../../services/api';
import { successToast } from '../../services/toast';

class AddIngredientForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleAddInci = this.handleAddInci.bind(this);
    this.handleDeleteInci = this.handleDeleteInci.bind(this);
    this.handleIngredientCreate = this.handleIngredientCreate.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);

    this.defaultState = {
      name: '',
      vendorName: '',
      vendor: '',
      description: '',
      category: '',
      inci: [],
      cost: '',
      costUnits: 'per lbs',
      moq: '',
      partNumber: '',
      selectedTags: [],
      forTexture: false,
      forScalp: false,
      isVisible: true,
      photo: null,
      photoUrl: null,
    };
    this.state = this.defaultState;
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Checkboxes
  handleCheckBoxChange(id) {
    if ($(`input[name="${id}"]:checked`).length === $(`input[name="${id}"]`).length) {
      $(`input[id="${id}"]`).prop('checked', true);
    } else if ($(`input[id="${id}"]`).prop('checked')) {
      $(`input[id="${id}"]`).prop('checked', false);
    }
  }

  handleSelectAll(e, id) {
    const { checked } = e.target;
    $(`input[name="${id}"]`).prop('checked', checked);
  }

  // INCI
  handleAddInci() {
    this.setState((prevState) => ({
      inci: [...prevState.inci, {
        name: '',
        mass_fraction: 0,
      }],
    }));
  }

  handleDeleteInci(index) {
    const { inci } = this.state;

    this.setState({
      inci: inci.filter((e, i) => i !== index),
    });
  }

  // Create
  handleIngredientCreate() {
    const {
      name, vendorName, vendor, description, category, inci, cost, costUnits,
      moq, selectedTags, isVisible, photo, forTexture, forScalp, partNumber,
    } = this.state;

    const rules = [];

    const data = {
      name,
      vendor_name: vendorName,
      vendor,
      description,
      category: category === 'null' ? null : category,
      inci,
      cost,
      cost_units: costUnits,
      moq,
      part_number: partNumber,
      tags: selectedTags.map((t) => t.value),
      for_texture: forTexture,
      for_scalp: forScalp,
      is_visible: isVisible,
      photo,
      rules,
    };

    api.addIngredients.create(data).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState);
  }

  render() {
    const {
      step, photoUrl, inci, name, vendor, vendorName, description, category,
      moq, cost, costUnits, selectedTags,
    } = this.state;
    const { tags } = this.props;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            Add Ingredient
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Base Info
            </span>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleIngredientCreate();
            }}
            ref={(form) => { this.formStep1 = form; }}
          >
            <div className="form-group">
              <label htmlFor="name">
                Name
              </label>
              <input
                required
                type="text"
                name="name"
                className="form-control"
                id="name"
                value={name}
                onChange={this.handleChange('name')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="vendor_name">
                Vendor Name
              </label>
              <input
                required
                type="text"
                name="vendor_name"
                className="form-control"
                id="vendor_name"
                value={vendorName}
                onChange={this.handleChange('vendorName')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="vendor">
                Vendor
              </label>
              <input
                required
                type="text"
                name="vendor"
                className="form-control"
                id="vendor"
                value={vendor}
                onChange={this.handleChange('vendor')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">
                Description
              </label>
              <textarea
                required
                type="text"
                name="description"
                className="form-control"
                id="description"
                value={description}
                onChange={this.handleChange('description')}
              />
            </div>
            <div className="form-group">
              <div style={{ width: '30%' }}>
                <label htmlFor="category">
                  Category
                </label>
                <select
                  id="category"
                  name="category"
                  value={category}
                  onChange={this.handleChange('category')}
                >
                  <option value="null">(none)</option>
                  <option>Scalp</option>
                  <option>Boost</option>
                  <option>Protect</option>
                  <option>Revive</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="custom-inputs-row">
                <div style={{ width: '70%' }}>
                  INCI
                </div>
                <div style={{ width: '30%' }}>
                  Mass fraction, %
                </div>
              </div>
              {inci.map((item, index) => (
                <div className="custom-inputs-row">
                  <input
                    type="text"
                    value={item.name}
                    name={`inci-name-${index}`}
                    className="form-control"
                    id={`inci-name-${index}`}
                    style={{ width: '70%' }}
                    onChange={(e) => this.setState({
                      inci: inci.map((i, ind) => (ind === index
                        ? ({ ...i, name: e.target.value }) : i)),
                    })}
                  />
                  <div style={{ display: 'flex', width: '30%' }}>
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      max="100"
                      value={item.mass_fraction}
                      name={`inci-fraction-${index}`}
                      className="form-control"
                      id={`inci-fraction-${index}`}
                      onChange={(e) => this.setState({
                        inci: inci.map((i, ind) => (ind === index
                          ? ({ ...i, mass_fraction: parseFloat(e.target.value) }) : i)),
                      })}
                    />
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.handleDeleteInci.bind(this, index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="form-group custom-inputs-row">
              <div style={{ width: '70%' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleAddInci}
                >
                  Add INCI
                </button>
              </div>
              {inci.length > 0 && (
                <div style={{ fontWeight: 500, fontSize: '1.25rem', width: '30%' }}>
                  {`TOTAL: ${inci.reduce((accumulator, currentValue) => accumulator + currentValue.mass_fraction, 0)}%`}
                </div>
              )}
            </div>
            <div className="form-group input-with-support-row mt-5">
              <div style={{ width: '40%' }}>
                <label htmlFor="moq">
                  MOQ
                </label>
                <input
                  required
                  type="number"
                  step="0.01"
                  name="moq"
                  className="form-control"
                  id="moq"
                  value={moq}
                  onChange={this.handleChange('moq')}
                />
              </div>
              <div style={{ width: '40%' }}>
                <label htmlFor="cost">
                  Cost
                </label>
                <input
                  required
                  type="number"
                  step="0.01"
                  min="0"
                  name="cost"
                  className="form-control"
                  id="cost"
                  value={cost}
                  onChange={this.handleChange('cost')}
                />
              </div>
              <div style={{ width: '20%' }}>
                <label htmlFor="cost_units">
                  Units
                </label>
                <select
                  id="cost_units"
                  name="cost_units"
                  value={costUnits}
                  onChange={this.handleChange('costUnits')}
                >
                  <option>per lbs</option>
                  <option>per kg</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="tags">
                Tags
              </label>
              <Select
                id="tags"
                name="tags"
                isMulti
                isSearchable
                value={selectedTags}
                options={tags.map((item) => (
                  {
                    value: item.id,
                    label: `${item.name} (#${item.id})`,
                  }
                ))}
                onChange={(val) => this.setState({ selectedTags: val })}
              />
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="forTexture"
                id="forTexture"
                onChange={() => this.setState((prevState) => ({ forTexture: !prevState.forTexture }))}
              />
              <label className="form-check-label" htmlFor="forTexture">
                For Texture ?
              </label>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="forScalp"
                id="forScalp"
                onChange={() => this.setState((prevState) => ({ forScalp: !prevState.forScalp }))}
              />
              <label className="form-check-label" htmlFor="forScalp">
                For Scalp ?
              </label>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input"
                name="isVisible"
                id="isVisible"
                defaultChecked
                onChange={() => this.setState((prevState) => ({ isVisible: !prevState.isVisible }))}
              />
              <label className="form-check-label" htmlFor="isVisible">
                Is Visible ?
              </label>
            </div>
            <div className="form-group">
              <ActiveStorageProvider
                endpoint={{
                  path: '/ingredients/upload_image',
                  model: 'Ingredient',
                  attribute: 'photo',
                  method: 'POST',
                }}
                onSubmit={(res) => this.setState({
                  photo: res.image_code,
                  photoUrl: res.image_url,
                })}
                render={({ handleUpload, uploads, ready }) => (
                  <div>
                    <input
                      type="file"
                      className="form-control"
                      disabled={!ready}
                      onChange={(e) => handleUpload(e.currentTarget.files)}
                    />
                    {uploads.map((upload) => {
                      switch (upload.state) {
                        case 'waiting':
                          return (
                            <p key={upload.id}>
                              Waiting to upload
                              {upload.file.name}
                            </p>
                          );
                        case 'uploading':
                          return (
                            <p key={upload.id}>
                              Uploading
                              {upload.file.name}
                              :
                              {upload.progress}
                              %
                            </p>
                          );
                        case 'error':
                          return (
                            <p key={upload.id}>
                              Error uploading
                              {upload.file.name}
                              :
                              {upload.error}
                            </p>
                          );
                        case 'finished':
                          return (
                            <p key={upload.id}>
                              Finished uploading
                              {upload.file.name}
                            </p>
                          );
                        default:
                          return 0;
                      }
                    })}
                  </div>
                )}
              />
              {photoUrl && (
                <div className="uploaded-image-container">
                  <img src={photoUrl} alt="uploadedImage" />
                </div>
              )}
            </div>
            <div className="btn-group custom-btn-continue">
              <button
                type="submit"
                className="btn btn-primary"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddIngredientForm.propTypes = {
  tags: PropTypes.instanceOf(Array),
};

AddIngredientForm.defaultProps = {
  tags: [],
};

export default AddIngredientForm;
