import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const EnterSubscriptionDataModal = ({
  handleSubmit, handleReject, additionalData,
}) => {
  const [startDate, setStartDate] = useState(new Date(additionalData.next_billing_date));

  return (
    <div
      className="modal fade"
      id="enterSubscriptionDataModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="enterSubscriptionDataModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="enterSubscriptionDataModalLabel">Enter Subscription Data</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label className="mr-4" htmlFor="sh_qty">SH qty</label>
                <input
                  type="number"
                  className="form-control"
                  min="0"
                  max="9"
                  id="sh_qty"
                  defaultValue={additionalData.sh_qty}
                  required
                />
              </div>
              <div className="form-group">
                <label className="mr-4" htmlFor="co_qty">CO qty</label>
                <input
                  type="number"
                  className="form-control"
                  min="0"
                  max="9"
                  id="co_qty"
                  defaultValue={additionalData.co_qty}
                  required
                />
              </div>
              <div className="form-group">
                <label className="mr-4" htmlFor="discount">Discount, %</label>
                <input
                  type="number"
                  className="form-control"
                  min="0"
                  max="100"
                  step="0.01"
                  id="discount"
                  defaultValue={additionalData.discount}
                  required
                />
              </div>
              <div className="form-group">
                <label className="mr-4" htmlFor="period">Period (months)</label>
                <input
                  type="number"
                  className="form-control"
                  min="1"
                  max="3"
                  id="period"
                  defaultValue={additionalData.period}
                  required
                />
              </div>
              <div className="form-group">
                <label className="mr-4" htmlFor="reuse_pumps">Reuse pumps</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="reuse_pumps"
                  defaultChecked={additionalData.reuse_pumps}
                />
              </div>
              <DatePicker id="next_billing_date" selected={startDate} onChange={setStartDate} />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleReject}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

EnterSubscriptionDataModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
};

EnterSubscriptionDataModal.defaultProps = {
  additionalData: {},
};

export default EnterSubscriptionDataModal;
