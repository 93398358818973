import React from 'react';
import PropTypes from 'prop-types';

const Pagination = (props) => {
  const { currentPage, isLastPage, handlePageChange } = props;

  return (
    <div className="pagination-container">
      <ul className="pagination">
        <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'}>
          <a className="page-link" href="#" tabIndex="-1" aria-label="Previous" onClick={() => handlePageChange(currentPage - 1)}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {currentPage !== 1 && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
              {currentPage - 1}
            </a>
          </li>
        )}
        <li className="page-item active">
          <a className="page-link" href="#">
            {currentPage}
            <span className="sr-only">(current)</span>
          </a>
        </li>
        {!isLastPage && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
              {currentPage + 1}
            </a>
          </li>
        )}
        <li className={isLastPage ? 'page-item disabled' : 'page-item'}>
          <a className="page-link" href="#" aria-label="Next" onClick={() => handlePageChange(currentPage + 1)}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  isLastPage: PropTypes.bool,
  handlePageChange: PropTypes.func,
};

Pagination.defaultProps = {
  currentPage: 1,
  isLastPage: false,
  handlePageChange: () => {},
};

export default Pagination;
