import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import api from '../../services/api';

const TestKitModal = ({ handleSubmit, handleReject, additionalData }) => {
  const [customer, setCustomer] = useState(null);
  const abortController = useRef();

  useEffect(() => {
    setCustomer(additionalData.customer ? {
      label: `[${additionalData.customer.id}] ${additionalData.customer.first_name} ${additionalData.customer.last_name}\n ${additionalData.customer.email}`,
      value: additionalData.customer.id,
    } : null);
  }, [additionalData]);

  const loadCustomers = useCallback((inputValue, callback) => {
    if (abortController.current) abortController.current.abort();

    const controller = new AbortController();
    abortController.current = controller;
    const { signal } = controller;

    api.customers.fetchCustomers(1, 'created_at desc', inputValue, signal)
      .then((res) => {
        callback(
          res.customers.map((item) => ({
            label: `[${item.id}] ${item.first_name} ${item.last_name}\n ${item.email}`,
            value: item.id,
          })),
        );
      });
  }, []);

  const handleLoadCustomers = useMemo(() => debounce(loadCustomers, 500), [loadCustomers]);

  return (
    <div
      className="modal fade custom-modal"
      id="ReassignTestKitModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ReassignTestKitModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ReassignTestKitModalLabel">
              Reassign TestKit
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleSubmit(e, additionalData)}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="customer_id">New Customer</label>
                <AsyncSelect
                  id="customer_id"
                  name="customer_id"
                  value={customer}
                  onChange={setCustomer}
                  loadOptions={handleLoadCustomers}
                />
              </div>

              <div className="form-group">
                <label htmlFor="customer_test_status">Set Old Customer Test Status to</label>
                <select id="customer_test_status" name="customer_test_status">
                  <option value="0"># 0: Customer created</option>
                  <option value="1"># 1: Test Kit Ordered</option>
                  <option value="2"># 2: Test Kit Activated</option>
                  <option value="3"># 3: Test Kit Received (=Accessioned)</option>
                  <option value="4"># 4: Customer Test Created</option>
                  <option value="5"># 5: Customer Test Published</option>
                  <option value="6"># 6: Product Formula Created</option>
                  <option value="7"># 7: Products Shipped</option>
                  <option value="8"># 8: Products Delivered (Review)</option>
                  <option value="9"># 9: (Reorder)</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleReject}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

TestKitModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
};

TestKitModal.defaultProps = {
  additionalData: {},
};

export default TestKitModal;
