import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_USER_MODAL, DISPLAY_CONFIRMATION_MODAL } from '../../constants';

class UsersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);

    this.handleUserCreate = this.handleUserCreate.bind(this);
    this.handleUserDestroy = this.handleUserDestroy.bind(this);
    this.handleUserEdit = this.handleUserEdit.bind(this);

    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.callUserModal = this.callUserModal.bind(this);

    this.state = {
      users: props.users,
      filteredUsers: props.users,
      lastSortKey: '',
      filterEmail: '',
      filterTitle: '',
      filterStatus: '',
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { filteredUsers, lastSortKey } = this.state;
    let sortedUsers;
    if (sortKey === 'id') {
      sortedUsers = filteredUsers.sort((a, b) => a[sortKey] - b[sortKey]);
    } else if (sortKey === 'role') {
      sortedUsers = filteredUsers.sort((a, b) => a.roles[0].name.localeCompare(b.roles[0].name));
    } else {
      sortedUsers = filteredUsers.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    }
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      filteredUsers: reverseSort ? sortedUsers.reverse() : sortedUsers,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  // Add/edit/delete
  handleUserCreate(data) {
    const { users } = this.state;
    api.users.create(data)
      .then((res) => this.setState({
        users: [res, ...users],
      }, () => this.handleAddFilters()));
  }

  handleUserEdit(data, id) {
    const { users } = this.state;
    api.users.update(data, id)
      .then((res) => this.setState({
        users: users.map((p) => (p.id === res.id ? res : p)),
      }, () => this.handleAddFilters()));
  }

  handleUserDestroy(id) {
    const { users, filteredUsers } = this.state;
    api.users.destroy(id)
      .then((res) => {
        this.setState({
          users: users.filter((p) => p.id !== res.id),
          filteredUsers: filteredUsers.filter((p) => p.id !== res.id),
        });
      });
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const {
      users, filterEmail, filterTitle, filterStatus,
    } = this.state;
    let newUsers = users;

    if (filterEmail) {
      newUsers = newUsers.filter((i) => i.email.toString().startsWith(filterEmail.toString()));
    }
    if (filterTitle) {
      newUsers = newUsers.filter((i) => i.title.toString().startsWith(filterTitle.toString()));
    }
    if (filterStatus && filterStatus !== 'Any') {
      newUsers = newUsers.filter((i) => i.status.toString() === filterStatus);
    }
    this.setState({ filteredUsers: newUsers });
  }

  clearFilters() {
    const { users } = this.state;
    this.filtersForm.reset();
    this.setState({
      filteredUsers: users,
      filterEmail: '',
      filterTitle: '',
      filterStatus: '',
    });
  }

  // Modal
  callUserModal(additionalData) {
    callEvent(
      DISPLAY_USER_MODAL,
      {
        handleSubmit: additionalData.type === 'create'
          ? this.handleUserCreate : this.handleUserEdit,
        additionalData,
      },
    );
  }

  render() {
    const { filteredUsers } = this.state;
    const { currentUser } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          <button
            type="button"
            id="custom-filters-toggle"
            className="btn btn-primary"
            onClick={() => $('#custom-filters').toggle(100)}
          >
            Toggle Filters
          </button>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterEmail">Email (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterEmail"
                  placeholder="abc@gmail.com"
                  onChange={this.handleChange('filterEmail')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterTitle">Title (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterTitle"
                  placeholder="abcde"
                  onChange={this.handleChange('filterTitle')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterStatus">Status (equals)</label>
                <select
                  id="filterStatus"
                  name="filterStatus"
                  onChange={this.handleChange('filterStatus')}
                >
                  <option>Any</option>
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Users</div>
            <div
              className="custom-table-header-right"
              onClick={() => this.callUserModal({ type: 'create', currentUser })}
            >
              + Add new
            </div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col" onClick={() => this.handleSort('id')}>User id</th>
                <th scope="col" onClick={() => this.handleSort('email')}>Email</th>
                <th scope="col" onClick={() => this.handleSort('first_name')}>First name</th>
                <th scope="col" onClick={() => this.handleSort('last_name')}>Last name</th>
                <th scope="col" onClick={() => this.handleSort('title')}>Title</th>
                <th scope="col" onClick={() => this.handleSort('status')}>Status</th>
                <th scope="col" onClick={() => this.handleSort('role')}>Role</th>
                <th scope="col" onClick={() => this.handleSort('enable_two_factor')}>Enable 2FA</th>
                <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{item.email}</td>
                  <td>{item.first_name}</td>
                  <td>{item.last_name}</td>
                  <td>{item.title}</td>
                  <td>{item.status}</td>
                  <td>{item.roles[0] ? item.roles[0].name : ''}</td>
                  <td>{item.otp_required_for_login ? '+' : '-'}</td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                  <td>{new Date(item.updated_at).toLocaleString()}</td>
                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.callUserModal({
                          type: 'update',
                          email: item.email,
                          first_name: item.first_name,
                          last_name: item.last_name,
                          title: item.title,
                          status: item.status,
                          role_id: item.role_id,
                          id: item.id,
                          currentUser,
                        })}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleUserDestroy(item.id))}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

UsersContainer.propTypes = {
  users: PropTypes.instanceOf(Array),
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

UsersContainer.defaultProps = {
  users: [],
};

export default UsersContainer;
