import React from 'react';
import PropTypes from 'prop-types';

// unused!

class OrdersItemsTablePrint extends React.Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  render() {
    const { selectedOrder } = this.props;

    return (
      <table className="table table-striped table-responsive-sm custom-table print-content">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Price</th>
            <th scope="col">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {selectedOrder.items.map((item) => (
            <tr key={item.id}>
              <th scope="row">{item.id}</th>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

OrdersItemsTablePrint.propTypes = {
  selectedOrder: PropTypes.instanceOf(Object).isRequired,
  onMount: PropTypes.func,
};

OrdersItemsTablePrint.defaultProps = {
  onMount: () => {},
};

export default OrdersItemsTablePrint;
