import React from 'react';
import PropTypes from 'prop-types';

const TagModal = ({ handleSubmit, handleReject, additionalData }) => (
  <div
    className="modal fade"
    id="TagModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="TagModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="TagModalLabel">
            { additionalData.type === 'create' ? 'Add Tag' : 'Edit Tag' }
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={(e) => handleSubmit(e, additionalData)}>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="name">
                Tag Name
              </label>
              <input
                required
                type="text"
                name="name"
                defaultValue={additionalData.name}
                className="form-control"
                id="name"
                placeholder="Enter name..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="tag_type">
                Tag Type
              </label>
              <input
                required
                type="text"
                name="tag_type"
                defaultValue={additionalData.tag_type}
                className="form-control"
                id="tag_type"
                placeholder="Enter tag_type..."
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={handleReject}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

TagModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
};

TagModal.defaultProps = {
  additionalData: {},
};

export default TagModal;
