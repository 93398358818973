import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import { successToast } from '../../services/toast';

const defaultState = {
  name: '',
  description: '',
  wherefourId: '',
  cocktailMass: 13,
  productType: 'none',
  partNumber: '',
  currentTab: 'formula_base',
  selectedIngredients: [],
};

class AddFormulaCocktailForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.setPartNumber = this.setPartNumber.bind(this);
    this.handleAddIngredient = this.handleAddIngredient.bind(this);
    this.handleDeleteIngredient = this.handleDeleteIngredient.bind(this);
    this.handleFormulaCocktailCreate = this.handleFormulaCocktailCreate.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);

    this.state = defaultState;
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Tabs
  handleTabChange(type) {
    $('.custom-tabs > div').removeClass('active');
    $(`#tab-${type}`).addClass('active');
    this.setState({ currentTab: type }, this.setPartNumber);
  }

  setPartNumber() {
    const { currentTab, productType } = this.state;
    let part1;
    if (currentTab === 'formula_treatment') part1 = '204';
    else if (currentTab === 'formula_environment') part1 = '203';
    else if (productType === 'conditioner') part1 = '202';
    else if (productType === 'shampoo') part1 = '201';
    else part1 = '200';

    this.setState({ partNumber: `${part1}-####` });
  }

  // Ingredients
  handleAddIngredient() {
    const { ingredients } = this.props;

    this.setState((prevState) => ({
      selectedIngredients: [...prevState.selectedIngredients, {
        ingredient_id: ingredients[0].id,
        mass_fraction: 0,
      }],
    }));
  }

  handleDeleteIngredient(index) {
    const { selectedIngredients } = this.state;

    this.setState({
      selectedIngredients: selectedIngredients.filter((e, i) => i !== index),
    });
  }

  // Create
  handleFormulaCocktailCreate() {
    const {
      name, description, wherefourId, cocktailMass, currentTab, selectedIngredients,
      partNumber, productType,
    } = this.state;

    const data = {
      name,
      description,
      wherefour_id: wherefourId,
      cocktail_mass: currentTab === 'formula_base' ? null : cocktailMass,
      part_number: partNumber,
      formula_type: currentTab,
      product_type: productType === 'none' ? null : productType,
      ingredients: selectedIngredients,
    };

    api.addFormulaCocktails.create(data).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(defaultState);
  }

  render() {
    const {
      name, description, productType, selectedIngredients, wherefourId, cocktailMass, currentTab,
    } = this.state;
    const { ingredients } = this.props;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            Add Formula Cocktail
          </div>
          <div className="custom-stepper">
            <span className="custom-stepper-step active">
              1. Formula Cocktail Base Info
            </span>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.handleFormulaCocktailCreate();
            }}
            ref={(form) => { this.formStep1 = form; }}
          >
            <div className="custom-tabs">
              <div id="tab-formula_base" className="active" onClick={() => this.handleTabChange('formula_base')}>Base</div>
              <div id="tab-formula_treatment" onClick={() => this.handleTabChange('formula_treatment')}>Treatment</div>
              <div id="tab-formula_environment" onClick={() => this.handleTabChange('formula_environment')}>Environment</div>
            </div>
            <div className="form-group mt-5">
              <label htmlFor="name">
                Name
              </label>
              <input
                required
                type="text"
                name="name"
                className="form-control"
                id="name"
                value={name}
                onChange={this.handleChange('name')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">
                Description
              </label>
              <input
                required
                type="text"
                name="description"
                className="form-control"
                id="description"
                value={description}
                onChange={this.handleChange('description')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="wherefour_id">
                Wherefour ID
              </label>
              <input
                type="number"
                name="wherefour_id"
                className="form-control"
                id="wherefour_id"
                value={wherefourId}
                onChange={this.handleChange('wherefourId')}
              />
            </div>
            {currentTab !== 'formula_base' && (
              <div className="form-group">
                <label htmlFor="cocktail_mass">
                  Cocktail Mass
                </label>
                <input
                  type="number"
                  step="0.1"
                  name="cocktail_mass"
                  className="form-control"
                  id="cocktail_mass"
                  value={cocktailMass}
                  onChange={this.handleChange('cocktailMass')}
                />
              </div>
            )}
            <div className="form-group">
              <label htmlFor="productType">
                Product Type
              </label>
              <select
                id="productType"
                name="productType"
                value={productType}
                onChange={this.handleChange('productType')}
              >
                <option>none</option>
                <option>shampoo</option>
                <option>conditioner</option>
              </select>
            </div>
            <div className="form-group mt-2">
              <div className="custom-inputs-row">
                <div style={{ width: '70%' }}>
                  Ingredient
                </div>
                <div style={{ width: '30%' }}>
                  Mass fraction, %
                </div>
              </div>
              {selectedIngredients.map((ingredient, index) => (
                <div className="custom-inputs-row">
                  <select
                    data-testid="ingredient"
                    id={`ingredientId-${index}`}
                    name={`ingredientId-${index}`}
                    value={ingredient.ingredient_id}
                    style={{ width: '70%' }}
                    onChange={(e) => this.setState({
                      selectedIngredients: selectedIngredients.map((i, ind) => (ind === index
                        ? ({ ...i, ingredient_id: parseFloat(e.target.value) }) : i)),
                    })}
                  >
                    {ingredients.map((item) => (
                      <option
                        key={item.id}
                        label={`${item.vendor_name} (#${item.id})`}
                        value={item.id}
                      >
                        {item.id}
                      </option>
                    ))}
                  </select>
                  <div style={{ display: 'flex', width: '30%' }}>
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      max="100"
                      data-testid="ingredient-fraction"
                      value={ingredient.mass_fraction}
                      name={`ingredient-fraction-${index}`}
                      className="form-control"
                      id={`ingredient-fraction-${index}`}
                      onChange={(e) => this.setState({
                        selectedIngredients: selectedIngredients.map((i, ind) => (ind === index
                          ? ({ ...i, mass_fraction: parseFloat(e.target.value) }) : i)),
                      })}
                    />
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={this.handleDeleteIngredient.bind(this, index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="form-group custom-inputs-row">
              <div style={{ width: '70%' }}>
                <button
                  type="button"
                  className="btn btn-primary"
                  id="btn-add-ingredient"
                  onClick={this.handleAddIngredient}
                >
                  Add Ingredient
                </button>
              </div>
              {selectedIngredients.length > 0 && (
                <div id="ingredients-total" style={{ fontWeight: 500, fontSize: '1.25rem', width: '30%' }}>
                  {`TOTAL: ${selectedIngredients.reduce((accumulator, currentValue) => accumulator + currentValue.mass_fraction, 0)}%`}
                </div>
              )}
            </div>
            <div className="btn-group custom-btn-continue">
              <button
                type="submit"
                className="btn btn-primary"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddFormulaCocktailForm.propTypes = {
  ingredients: PropTypes.instanceOf(Array),
};

AddFormulaCocktailForm.defaultProps = {
  ingredients: [],
};

export default AddFormulaCocktailForm;
