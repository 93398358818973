import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_PRODUCT_MODAL, DISPLAY_CONFIRMATION_MODAL } from '../../constants';

class ProductsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);

    this.handleProductCreate = this.handleProductCreate.bind(this);
    this.handleProductDestroy = this.handleProductDestroy.bind(this);
    this.handleProductEdit = this.handleProductEdit.bind(this);

    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.callProductModal = this.callProductModal.bind(this);

    this.state = {
      products: props.products,
      filteredProducts: props.products,
      lastSortKey: '',
      filterId: '',
      filterTitle: '',
      filterSKU: '',
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { filteredProducts, lastSortKey } = this.state;
    const sortedProducts = sortKey === 'id'
      ? filteredProducts.sort((a, b) => a[sortKey] - b[sortKey])
      : filteredProducts.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      filteredProducts: reverseSort ? sortedProducts.reverse() : sortedProducts,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  // Add/edit/delete
  handleProductCreate(data) {
    const { products } = this.state;
    api.products.create(data)
      .then((res) => this.setState({
        products: [res, ...products],
      }, () => this.handleAddFilters()));
  }

  handleProductEdit(data, id) {
    const { products } = this.state;
    api.products.update(data, id)
      .then((res) => this.setState({
        products: products.map((p) => (p.id === res.id ? res : p)),
      }, () => this.handleAddFilters()));
  }

  handleProductDestroy(id) {
    const { products, filteredProducts } = this.state;
    api.products.destroy(id)
      .then((res) => {
        this.setState({
          products: products.filter((p) => p.id !== res.id),
          filteredProducts: filteredProducts.filter((p) => p.id !== res.id),
        });
      });
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const {
      products, filterId, filterTitle, filterSKU,
    } = this.state;
    let newProducts = products;

    if (filterId) {
      newProducts = newProducts.filter((p) => p.id.toString().startsWith(filterId.toString()));
    }
    if (filterTitle) {
      newProducts = newProducts.filter((p) => p.title.startsWith(filterTitle));
    }
    if (filterSKU) {
      newProducts = newProducts.filter((p) => p.sku.startsWith(filterSKU));
    }
    this.setState({ filteredProducts: newProducts });
  }

  clearFilters() {
    const { products } = this.state;
    this.filtersForm.reset();
    this.setState({
      filteredProducts: products,
      filterId: '',
      filterTitle: '',
      filterSKU: '',
    });
  }

  // Modal
  callProductModal(additionalData) {
    callEvent(
      DISPLAY_PRODUCT_MODAL,
      {
        handleSubmit: additionalData.type === 'create'
          ? this.handleProductCreate : this.handleProductEdit,
        additionalData,
      },
    );
  }

  render() {
    const { filteredProducts } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <button
            type="button"
            id="custom-filters-toggle"
            className="btn btn-primary"
            onClick={() => $('#custom-filters').toggle(100)}
          >
            Toggle Filters
          </button>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterId">ID (startsWith)</label>
                <input
                  type="number"
                  className="form-control"
                  id="filterId"
                  placeholder="1234"
                  onChange={this.handleChange('filterId')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterTitle">Title (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterTitle"
                  placeholder="abcd"
                  onChange={this.handleChange('filterTitle')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterSKU">SKU (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterSKU"
                  placeholder="abcd"
                  onChange={this.handleChange('filterSKU')}
                />
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Products</div>
            <div
              className="custom-table-header-right"
              onClick={() => this.callProductModal({ type: 'create' })}
            >
              + Add new
            </div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col" onClick={() => this.handleSort('id')}>Product id</th>
                <th scope="col" onClick={() => this.handleSort('sku')}>sku</th>
                <th scope="col" onClick={() => this.handleSort('title')}>Title</th>
                <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {filteredProducts.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td>{item.sku}</td>
                  <td>{item.title}</td>
                  <td>{new Date(item.created_at).toLocaleString()}</td>
                  <td>{new Date(item.updated_at).toLocaleString()}</td>
                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.callProductModal({
                          type: 'update',
                          title: item.title,
                          sku: item.sku,
                          id: item.id,
                        })}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleProductDestroy(item.id))}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

ProductsContainer.propTypes = {
  products: PropTypes.instanceOf(Array),
};

ProductsContainer.defaultProps = {
  products: [],
};

export default ProductsContainer;
