import React from 'react';
import PropTypes from 'prop-types';

export default function ShopifyOrderLink({ shopifyId, children }) {
  return (
    <a target="_blank" rel="noreferrer" href={`${window.SHOPIFY_STORE_URL}/admin/orders/${shopifyId}`}>
      { children }
    </a>
  );
}

ShopifyOrderLink.propTypes = {
  shopifyId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
