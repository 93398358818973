import React from 'react';
import PropTypes from 'prop-types';
import { ROLE_PERMISSIONS } from '../../constants';

const RoleModal = ({
  handleSubmit,
  handleReject,
  additionalData,
  updateAdditionalData,
}) => {
  const handleChangePermissions = (e) => {
    const { checked, name } = e.target;

    updateAdditionalData({
      ...additionalData,
      permissions: checked ? [...additionalData.permissions, name] : additionalData.permissions.filter((perm) => perm !== name),
    });
  };

  const handleChangeRoleName = (e) => {
    const name = e.target.value;

    updateAdditionalData({
      ...additionalData,
      name,
    });
  };

  return (
    <div
      className="modal fade custom-modal"
      id="RoleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="RoleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="RoleModalLabel">
              { additionalData.type === 'create' ? 'Add Role' : 'Edit Role' }
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleSubmit(e, additionalData)}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="last_name">
                  Role Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={additionalData.name}
                  className="form-control"
                  id="name"
                  placeholder="Enter role name..."
                  onChange={handleChangeRoleName}
                />
              </div>
              {ROLE_PERMISSIONS.map((group, i) => (

                <div key={i} className="permission_group">
                  <div className="permission_group-name">
                    {group.group_name}
                  </div>
                  {group.permissions.map((item) => (
                    <div key={item.id} className="permission">
                      <input
                        type="checkbox"
                        name={item.id}
                        id={item.id}
                        onChange={handleChangePermissions}
                        checked={additionalData?.permissions?.includes(item.id)}
                      />
                      <label htmlFor={item.id}>{item.name}</label>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={handleReject}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

RoleModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
  updateAdditionalData: PropTypes.func.isRequired,
};

RoleModal.defaultProps = {
  additionalData: {},
};

export default RoleModal;
