import React from 'react';
import PropTypes from 'prop-types';

const AddPromoKitsOrderModal = ({
  handleSubmit, handleReject,
}) => (
  <div
    className="modal fade"
    id="addPromoKitsOrderModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="addPromoKitsOrderModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="addPromoKitsOrderModalLabel">Add Promo Test Kit Order</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="form-group">
              <label className="mr-4" htmlFor="qty">
                Choose the number of kits
              </label>
              <select id="qty" name="qty">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={handleReject}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Generate
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

AddPromoKitsOrderModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
};

export default AddPromoKitsOrderModal;
