import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_CONFIRMATION_MODAL } from '../../constants';

class IngredientsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);

    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleIngredientDestroy = this.handleIngredientDestroy.bind(this);

    this.state = {
      ingredients: props.ingredients,
      filteredIngredients: props.ingredients,
      lastSortKey: '',
      filterId: '',
      filterName: '',
      filterDescription: '',
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { filteredIngredients, lastSortKey } = this.state;
    const sortedIngredients = (['id', 'cost', 'moq'].includes(sortKey))
      ? filteredIngredients.sort((a, b) => a[sortKey] - b[sortKey])
      : filteredIngredients.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      filteredIngredients: reverseSort ? sortedIngredients.reverse() : sortedIngredients,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const {
      ingredients, filterId, filterName, filterDescription,
    } = this.state;
    let newIngredients = ingredients;

    if (filterId) {
      newIngredients = newIngredients
        .filter((i) => i.id.toString().startsWith(filterId.toString()));
    }
    if (filterName) {
      newIngredients = newIngredients
        .filter((i) => i.name.toString().startsWith(filterName.toString()));
    }
    if (filterDescription) {
      newIngredients = newIngredients
        .filter((i) => i.description.toString().startsWith(filterName.toString()));
    }
    this.setState({ filteredIngredients: newIngredients });
  }

  clearFilters() {
    const { ingredients } = this.state;
    this.filtersForm.reset();
    this.setState({
      filteredIngredients: ingredients,
      filterId: '',
      filterName: '',
      filterDescription: '',
    });
  }

  // api
  handleIngredientDestroy(id) {
    const { ingredients, filteredIngredients } = this.state;
    api.ingredients.destroy(id)
      .then((res) => {
        this.setState({
          ingredients: ingredients.filter((i) => i.id !== res.id),
          filteredIngredients: filteredIngredients.filter((i) => i.id !== res.id),
        });
      });
  }

  render() {
    const { filteredIngredients } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <button
            type="button"
            id="custom-filters-toggle"
            className="btn btn-primary"
            onClick={() => $('#custom-filters').toggle(100)}
          >
            Toggle Filters
          </button>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterId">ID (startsWith)</label>
                <input
                  type="number"
                  className="form-control"
                  id="filterId"
                  placeholder="123"
                  onChange={this.handleChange('filterId')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterName">Name (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterName"
                  placeholder="abc"
                  onChange={this.handleChange('filterName')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="filterDescription">Description (startsWith)</label>
                <input
                  type="text"
                  className="form-control"
                  id="filterDescription"
                  placeholder="abc"
                  onChange={this.handleChange('filterDescription')}
                />
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Ingredients</div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm custom-table">
              <thead>
                <tr>
                  <th scope="col" onClick={() => this.handleSort('id')}>Ingredient id</th>
                  <th scope="col" onClick={() => this.handleSort('part_number')}>Part Number</th>
                  <th scope="col" onClick={() => this.handleSort('name')}>Name</th>
                  <th scope="col" onClick={() => this.handleSort('vendor_name')}>Vendor Name</th>
                  <th scope="col" onClick={() => this.handleSort('vendor')}>Vendor</th>
                  <th scope="col" onClick={() => this.handleSort('description')}>Description</th>
                  <th scope="col">INCI</th>
                  <th scope="col" onClick={() => this.handleSort('cost')}>Cost</th>
                  <th scope="col" onClick={() => this.handleSort('cost_units')}>Cost Units</th>
                  <th scope="col" onClick={() => this.handleSort('moq')}>MOQ</th>
                  <th scope="col">For Texture</th>
                  <th scope="col">For Scalp</th>
                  <th scope="col">Is Visible</th>
                  {/* <th scope="col">Rules</th> */}
                  <th scope="col">Tags</th>
                  <th scope="col">Image</th>
                  <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                  <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {filteredIngredients.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.part_number}</td>
                    <td>{item.name}</td>
                    <td>{item.vendor_name}</td>
                    <td>{item.vendor}</td>
                    <td>{item.description}</td>
                    <td>
                      {item.inci.length > 0 ? (
                        item.inci.map((i, index) => <div key={i.name}>{`#${index + 1}: ${i.name}, ${i.mass_fraction}%; `}</div>)
                      ) : 'no INCI'}
                    </td>
                    <td>{item.cost}</td>
                    <td>{item.cost_units}</td>
                    <td>{item.moq}</td>
                    <td>{item.for_texture.toString()}</td>
                    <td>{item.for_scalp.toString()}</td>
                    <td>{item.is_visible.toString()}</td>
                    {/* <td>{JSON.stringify(item.rules)}</td> */}
                    <td>
                      {item.tags.length > 0 ? (
                        item.tags.map((i, index) => `Tag #${index + 1}: ${i.name}, ${i.tag_type}; `)
                      ) : 'no tags'}
                    </td>
                    <td>
                      {item.image ? (
                        <img src={item.image} alt="img" width="150px" />
                      ) : 'no image'}
                    </td>
                    <td>{new Date(item.created_at).toLocaleString()}</td>
                    <td>{new Date(item.updated_at).toLocaleString()}</td>
                    <td>
                      <div className="btn-group" role="group" aria-label="">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleIngredientDestroy(item.id))}
                        >
                          Remove
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

IngredientsContainer.propTypes = {
  ingredients: PropTypes.instanceOf(Array),
};

IngredientsContainer.defaultProps = {
  ingredients: [],
};

export default IngredientsContainer;
