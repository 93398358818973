import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

import PackingSlipPrint from './PackingSlipPrint';
import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_ADD_PROMO_BUNDLE_ORDER_MODAL, DISPLAY_ADD_PROMO_KITS_ORDER_MODAL } from '../../constants';
import codeErrors from '../../services/codeValidators';
import Pagination from '../customElements/Pagination';
import { successToast } from '../../services/toast';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

class TestKitsFulfillment extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.callAddPromoBundleOrderModal = this.callAddPromoBundleOrderModal.bind(this);
    this.callAddPromoKitsOrderModal = this.callAddPromoKitsOrderModal.bind(this);
    this.handleAddPromoBundleOrder = this.handleAddPromoBundleOrder.bind(this);
    this.handleAddPromoKitsOrder = this.handleAddPromoKitsOrder.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);
    this.checkTestKitCode = this.checkTestKitCode.bind(this);
    this.handleStep2Submit = this.handleStep2Submit.bind(this);
    this.handleGenerateShippingLabel = this.handleGenerateShippingLabel.bind(this);
    this.onPrintTableMount = this.onPrintTableMount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.handleFetchOrders = this.handleFetchOrders.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.generateCsvData = this.generateCsvData.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.defaultState = {
      step: 1,
      codes: [],
      selectedOrder: null,
      readyForPrint: false,
      labelUrl: null,
      shippoError: null,
      labelRequired: true,
      skipValidation: false,
      orderFulfilled: false,
      carrier: 'usps',
      currentTab: urlParams.get('tab') || 'pending',
      searchText: urlParams.get('search') || '',
      orders: props.orders,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      pagination: {
        canceled: {
          currentPage: urlParams.get('tab') === 'canceled' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'canceled' && urlParams.get('page') ? props.isLastPage : null,
        },
        completed: {
          currentPage: urlParams.get('tab') === 'completed' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'completed' && urlParams.get('page') ? props.isLastPage : null,
        },
        refunded: {
          currentPage: urlParams.get('tab') === 'refunded' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'refunded' && urlParams.get('page') ? props.isLastPage : null,
        },
      },
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchOrders(null, null, order);
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Tabs
  handleTabChange(tab) {
    const { pagination } = this.state;
    const page = pagination[tab] && pagination[tab].currentPage ? pagination[tab].currentPage : 1;
    this.handleFetchOrders(page, tab);
  }

  // Step change
  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Modal
  callAddPromoBundleOrderModal() {
    callEvent(DISPLAY_ADD_PROMO_BUNDLE_ORDER_MODAL, {
      handleSubmit: (data) => this.handleAddPromoBundleOrder(data),
    });
  }

  callAddPromoKitsOrderModal() {
    callEvent(DISPLAY_ADD_PROMO_KITS_ORDER_MODAL, {
      handleSubmit: (data) => this.handleAddPromoKitsOrder(data),
    });
  }

  // Promo
  handleAddPromoBundleOrder(data) {
    api.ordersFulfillment.addPromoKitsOrder(data)
      .then((res) => window.console.log(res));
  }

  handleAddPromoKitsOrder(data) {
    api.ordersFulfillment.addPromoKitsOrder(data)
      .then((res) => window.console.log(res));
  }

  // Step 1 - Order select
  handleOrderSelect(order) {
    this.setState({ selectedOrder: order }, () => {
      this.handleStepChange();
      $('.custom-stepper').removeClass('custom-hidden');
    });
  }

  // Step 2 - Check codes
  checkTestKitCode(e, id) {
    const code = e.target.value;
    const parsedCode = code.substr(0, 10);
    const errBlock = $(`#${id}`).closest('div.form-group').find('.input-error');
    errBlock.html('');
    const error = codeErrors(code);
    if (error) return errBlock.html(error);

    let codeInUse = false;
    $('input[name="code"]').each(function checkInputVals() {
      if ($(this).val() === code && $(this).attr('id') !== id) {
        codeInUse = true;
      }
    });
    if (codeInUse) return errBlock.html('This code is already in use!');

    return api.ordersFulfillment.codeVerification({ code: parsedCode })
      .then((res) => errBlock.html(res.err));
  }

  // Step 2 - Submit
  handleStep2Submit() {
    $('input[name="code"]').each(function checkInputVals() {
      const errBlock = $(`#${$(this).attr('id')}`).closest('div.form-group').find('.input-error');
      if ($(this).val().length !== 13) {
        errBlock.html('ID is required');
      }
    });

    if ($('.input-error:empty').length === $('input[name="code"]').length) {
      this.setState({
        codes: $("input[name='code']").map(function inputVals() { return $(this).val().substr(0, 10); }).get(),
      }, () => {
        this.setState({ shippoError: null });
        this.handleStepChange();
      });
    }
  }

  // Step 3 - Ready for print
  onPrintTableMount() {
    this.setState({ readyForPrint: true });
  }

  // Step 3 - Generate Shipping Label
  handleGenerateShippingLabel() {
    const { selectedOrder, skipValidation, carrier } = this.state;
    this.setState({ shippoError: null });
    this.generateShippingLabelTrigger.disabled = true;

    api.ordersFulfillment.generateShippingLabel({
      id: selectedOrder.id, parcel_type: 'test-kit', qty: selectedOrder.tk_qty, skip_validation: skipValidation, carrier,
    })
      .then((res) => this.setState({ labelUrl: res.label_url }))
      .catch((res) => {
        this.generateShippingLabelTrigger.disabled = false;
        this.setState({ shippoError: res.error });
      });
  }

  // Step 3 - Fulfill order and print shipping label
  printLabel() {
    const {
      labelUrl, selectedOrder, codes, carrier,
    } = this.state;
    window.open(labelUrl, '_blank', 'noopener,noreferrer');
    api.ordersFulfillment.fulfill({
      id: selectedOrder.id, codes, test_kit: true, carrier,
    }).then(this.setState({ orderFulfilled: true }));
  }

  // Step 3 - Submit
  handleSubmit() {
    const {
      selectedOrder, codes, orderFulfilled, carrier,
    } = this.state;

    if (orderFulfilled) {
      this.handleStepChange();
      return;
    }

    api.ordersFulfillment.fulfill({
      id: selectedOrder.id, codes, test_kit: true, carrier,
    }).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetchOrders);
  }

  handleFetchOrders(newPage, newTab, newOrder) {
    const {
      currentTab, pagination, lastSortKey, searchText,
    } = this.state;
    const tab = newTab || currentTab;
    const page = newPage || (pagination[currentTab] ? pagination[currentTab].currentPage : 1);
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?tab=${tab}&page=${page}&order=${order}&search=${searchText}`);

    api.ordersFulfillment.fetchTestKitOrders(page, tab, order, searchText)
      .then((res) => this.setState({
        orders: res.orders,
        currentTab: tab,
        lastSortKey: order,
        pagination: {
          ...pagination,
          [tab]: {
            ...pagination[tab],
            currentPage: page,
            isLastPage: res.is_last_page,
          },
        },
      }));
  }

  generateCsvData() {
    const { orders } = this.state;

    return [
      ['Order', 'Email', 'First Name', 'Last Name', 'QTY', 'Carrier', 'Created', 'Updated', 'Status Changed', 'Age'],
      ...orders.map((item) => [`#${item.shopify_number}`, (item.customer || {}).email, (item.customer || {}).first_name, (item.customer || {}).last_name, item.tk_qty, item.default_carrier, new Date(item.created_at).toLocaleString(), new Date(item.updated_at).toLocaleString(), new Date(item.status_changed_at).toLocaleString(), item.age]),
    ];
  }

  render() {
    const {
      step, readyForPrint, selectedOrder, labelUrl, shippoError,
      orders, currentTab, pagination, labelRequired, skipValidation,
      searchText, carrier,
    } = this.state;
    const { tkVariantId, gkVariantId } = this.props;

    const isTestKit = (i) => i.variant_id === tkVariantId || i.variant_id === gkVariantId;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block search-container">
            <div>
              {selectedOrder
                ? (
                  <>
                    Test Kits Fulfillment (
                    <ShopifyOrderLink shopifyId={selectedOrder.shopify_id}>{`#${selectedOrder.shopify_number}`}</ShopifyOrderLink>
                    )
                  </>
                ) : 'Test Kits Fulfillment'}
            </div>
            <form
              className={`search-block ${selectedOrder ? 'hidden' : ''}`}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFetchOrders();
              }}
            >
              <input
                type="text"
                defaultValue={searchText}
                placeholder="Search..."
                id="search"
                onChange={this.handleChange('searchText')}
              />
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
          {step === 1 && (
            <div className="mt-4 mb-4">
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={this.callAddPromoBundleOrderModal}
              >
                Add Promo Bundle Order
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.callAddPromoKitsOrderModal}
              >
                Add Promo Test Kit Order
              </button>
            </div>
          )}
          <div className="custom-stepper custom-hidden">
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              1. Link Test Kits
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 3 ? 'active' : ''}`}>
              2. Get shipping label and packing slip
            </span>
          </div>
          {/* Tabs */}
          <div className={step === 1 ? 'custom-tabs' : 'disabled custom-tabs'}>
            <div
              id="tab-pending"
              onClick={() => this.handleTabChange('pending')}
              className={currentTab === 'pending' ? 'active' : ''}
            >
              Pending
            </div>
            <div
              id="tab-completed"
              onClick={() => this.handleTabChange('completed')}
              className={currentTab === 'completed' ? 'active' : ''}
            >
              Completed
            </div>
            <div
              id="tab-canceled"
              onClick={() => this.handleTabChange('canceled')}
              className={currentTab === 'canceled' ? 'active' : ''}
            >
              Canceled
            </div>
            <div
              id="tab-refunded"
              onClick={() => this.handleTabChange('refunded')}
              className={currentTab === 'refunded' ? 'active' : ''}
            >
              Refunded
            </div>
          </div>
          {step === 1 && (
            <div>
              <div>
                <CSVLink className="csv-download" data={this.generateCsvData()}>Export as CSV</CSVLink>
                <table className="table table-striped table-responsive-sm custom-table">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => this.handleSort('id')}>ORDER</th>
                      <th scope="col" onClick={() => this.handleSort('customer_id')}>Customer</th>
                      <th scope="col">QTY</th>
                      <th scope="col">ADDITIONAL PRODUCTS</th>
                      <th scope="col" onClick={() => this.handleSort('default_carrier')}>Carrier</th>
                      <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                      <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                      <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Status Changed</th>
                      <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Age</th>
                      {currentTab === 'refunded' && (
                        <th scope="col" onClick={() => this.handleSort('financial_status')}>Financial Status</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((item) => (
                      <tr className="custom-tr-selectable" key={item.id} onClick={() => this.handleOrderSelect(item)}>
                        <th scope="row"><ShopifyOrderLink shopifyId={item.shopify_id}>{`#${item.shopify_number}`}</ShopifyOrderLink></th>
                        <td>{item.customer && `${item.customer.first_name} ${item.customer.last_name}\n ${item.customer.email}`}</td>
                        <td>{item.tk_qty}</td>
                        <td>{`${item.pillowcase_black_qty} x Pillowcase [black]\n${item.pillowcase_white_qty} x Pillowcase [white]`}</td>
                        <td>
                          {item.default_carrier}
                          {' '}
                          {currentTab === 'completed' && item.shippo_labels.length > 0 && <a href={item.shippo_labels[item.shippo_labels.length - 1].label_url} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}><FontAwesomeIcon icon={faPrint} /></a>}
                        </td>
                        <td>{new Date(item.created_at).toLocaleString()}</td>
                        <td>{new Date(item.updated_at).toLocaleString()}</td>
                        <td>{new Date(item.status_changed_at).toLocaleString()}</td>
                        <td>{item.age}</td>
                        {currentTab === 'refunded' && (
                          <td>{item.financial_status}</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {currentTab !== 'pending' && (
                <Pagination
                  currentPage={pagination[currentTab].currentPage}
                  isLastPage={pagination[currentTab].isLastPage}
                  handlePageChange={this.handleFetchOrders}
                />
              )}
            </div>
          )}
          {step === 2 && (
            <div>
              <div>
                <PackingSlipPrint
                  selectedOrder={selectedOrder}
                  checkTestKitCode={this.checkTestKitCode}
                  noTable={true}
                />
                <table className="table table-striped table-responsive-sm custom-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">ID</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.items.filter((item) => isTestKit(item)).map((item) => [...Array(item.quantity)].map((e, i) => (
                      <tr key={`${item.id}-${i}`}>
                        <th scope="row">{i + 1}</th>
                        <td>{item.name}</td>
                        <td>
                          <div className="form-group">
                            <input
                              required
                              type="text"
                              name="code"
                              className="form-control"
                              id={`code-${i}`}
                              disabled={currentTab !== 'pending'}
                              onChange={(event) => this.checkTestKitCode(event, `code-${i}`)}
                            />
                            <div className="input-error" />
                          </div>
                        </td>
                      </tr>
                    )))}
                    {selectedOrder.items.filter((item) => !isTestKit(item)).map((item, index) => (
                      <tr key={`${item.id}-${index}`}>
                        <th scope="row">{index + 1 + selectedOrder.items.filter((i) => isTestKit(i)).length}</th>
                        <td>{item.name}</td>
                        <td />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={() => this.handleStepChange(-1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={this.handleStep2Submit}
                  disabled={currentTab !== 'pending'}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div>
              <PackingSlipPrint
                onMount={this.onPrintTableMount}
                selectedOrder={selectedOrder}
                ref={(el) => { this.printContent = el; }}
              />
              {readyForPrint && (
                <ReactToPrint
                  trigger={() => (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      id="print-trigger"
                    >
                      Print Packing Slip
                    </button>
                  )}
                  content={() => this.printContent}
                />
              )}
              <div className="mt-2 generate-shipping-label-block">
                <button
                  type="button"
                  className="btn btn-secondary"
                  id="btn-shipping-label"
                  ref={(el) => { this.generateShippingLabelTrigger = el; }}
                  onClick={this.handleGenerateShippingLabel}
                  disabled={!labelRequired}
                >
                  Generate Shipping Label
                </button>
                <select
                  id="carrier"
                  name="carrier"
                  value={carrier}
                  onChange={this.handleChange('carrier')}
                  disabled={currentTab === 'published'}
                  style={{
                    width: '5rem', height: '2rem', backgroundColor: 'unset', paddingLeft: '0.5rem',
                  }}
                >
                  <option value="usps">USPS</option>
                  <option value="fedex">FedEx</option>
                </select>
                <div style={{ marginLeft: '4rem' }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="skipValidation"
                    id="skipValidation"
                    defaultChecked={skipValidation}
                    onChange={() => this.setState((prevState) => ({ skipValidation: !prevState.skipValidation }))}
                  />
                  <label className="form-check-label" htmlFor="skipValidation">
                    Skip address validation
                  </label>
                </div>
              </div>
              <div className="mt-2" style={{ marginLeft: '1.5rem' }}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="labelRequired"
                  id="labelRequired"
                  defaultChecked={!labelRequired}
                  onChange={() => this.setState((prevState) => ({ labelRequired: !prevState.labelRequired }))}
                />
                <label className="form-check-label" htmlFor="labelRequired">
                  No shipping label required
                </label>
              </div>
              {labelUrl && (
                <div className="mt-2">
                  <a className="btn btn-secondary" onClick={this.printLabel}>
                    Print Shipping Label
                  </a>
                </div>
              )}
              {shippoError && (
                <div data-testid="shippo-error" className="err mt-2 shippo-error">{`Shippo Error: ${shippoError}`}</div>
              )}
              <div className="btn-group custom-btn-continue mt-5">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={() => this.handleStepChange(-1)}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!labelUrl && labelRequired}
                  onClick={this.handleSubmit}
                >
                  Complete Order
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

TestKitsFulfillment.propTypes = {
  orders: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
  tkVariantId: PropTypes.number,
  gkVariantId: PropTypes.number,
};

TestKitsFulfillment.defaultProps = {
  orders: [],
  isLastPage: false,
  tkVariantId: 0,
  gkVariantId: 0,
};

export default TestKitsFulfillment;
