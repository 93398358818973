import React from 'react';
import PropTypes from 'prop-types';

const TestKitModal = ({ handleSubmit, handleReject, additionalData }) => (
  <div
    className="modal fade custom-modal"
    id="TestKitModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="TestKitModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="TestKitModalLabel">
            { additionalData.type === 'create' ? 'Add TestKit' : 'Edit TestKit' }
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={(e) => handleSubmit(e, additionalData)}>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="productId">
                productId
              </label>
              <select id="productId" name="productId" defaultValue={additionalData.product_id}>
                {additionalData.products.map((item) => (
                  <option
                    key={item.id}
                    label={`${item.title} (#${item.id})`}
                  >
                    {item.id}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select id="status" name="status" defaultValue={additionalData.status}>
                <option>ReadyToShip</option>
                <option>Shipped</option>
                <option>Received</option>
                <option>Completed</option>
                <option>Discarded</option>
              </select>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={handleReject}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

TestKitModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
};

TestKitModal.defaultProps = {
  additionalData: {},
};

export default TestKitModal;
