import React from 'react';
import PropTypes from 'prop-types';
// import ActiveStorageProvider from 'react-activestorage-provider';

const CustomerTestModal = ({ handleSubmit, handleReject, additionalData }) => (
  <div
    className="modal fade custom-modal"
    id="CustomerTestModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="CustomerTestModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="CustomerTestModalLabel">
            { additionalData.type === 'create' ? 'Add CustomerTest' : 'Edit CustomerTest' }
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={(e) => handleSubmit(e, additionalData)}>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="testKitId">
                testKitId
              </label>
              <select id="testKitId" name="testKitId" defaultValue={additionalData.test_kit_id}>
                {additionalData.testKits.map((item) => (
                  <option
                    key={item.id}
                    label={`#${item.id}`}
                  >
                    {item.id}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="customerId">
                customerId
              </label>
              <select id="customerId" name="customerId" defaultValue={additionalData.customer_id}>
                {additionalData.customers.map((item) => (
                  <option
                    key={item.id}
                    label={`${item.uid} (#${item.id})`}
                  >
                    {item.id}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select id="status" name="status" defaultValue={additionalData.status}>
                <option>InProcess</option>
                <option>Finished</option>
                <option>Published</option>
                <option>Failure</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="result_avg_protein_loss">
                result_avg_protein_loss
              </label>
              <input
                required
                type="number"
                name="result_avg_protein_loss"
                defaultValue={additionalData.result_avg_protein_loss}
                className="form-control"
                id="result_avg_protein_loss"
                placeholder="Enter result_avg_protein_loss..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="result_avg_hair_diam">
                result_avg_hair_diam
              </label>
              <input
                required
                type="number"
                name="result_avg_hair_diam"
                defaultValue={additionalData.result_avg_hair_diam}
                className="form-control"
                id="result_avg_hair_diam"
                placeholder="Enter result_avg_hair_diam..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="result_avg_sample_weight">
                result_avg_sample_weight
              </label>
              <input
                required
                type="number"
                name="result_avg_sample_weight"
                defaultValue={additionalData.result_avg_sample_weight}
                className="form-control"
                id="result_avg_sample_weight"
                placeholder="Enter result_avg_sample_weight..."
              />
            </div>
            <div className="form-group">
              <label htmlFor="result_avg_microscopic_analysis_score">
                result_avg_microscopic_analysis_score
              </label>
              <input
                required
                min="1"
                max="4"
                type="number"
                name="result_avg_microscopic_analysis_score"
                defaultValue={additionalData.result_avg_microscopic_analysis_score}
                className="form-control"
                id="result_avg_microscopic_analysis_score"
                placeholder="Enter result_avg_microscopic_analysis_score..."
              />
            </div>
            <input
              type="hidden"
              name="cuticle_images"
              // defaultValue={additionalData.cuticle_images}
              className="form-control"
              id="cuticle_images"
            />
            {/* <ActiveStorageProvider
              endpoint={{
                path: '/customer_tests/upload_image',
                model: 'CustomerTest',
                attribute: 'cuticle_images',
                method: 'POST',
              }}
              multiple={true}
              onSubmit={(res) => {
                const curValue = $('input#cuticle_images').val();
                $('input#cuticle_images').val(curValue ? [curValue, res.image_codes] : res.image_codes);
              }}
              render={({ handleUpload, uploads, ready }) => (
                <div>
                  <input
                    type="file"
                    className="form-control"
                    disabled={!ready}
                    multiple
                    onChange={e => handleUpload(e.currentTarget.files)}
                  />
                  {uploads.map((upload) => {
                    switch (upload.state) {
                      case 'waiting':
                        return (
                          <p key={upload.id}>
                            Waiting to upload
                            {upload.file.name}
                          </p>
                        );
                      case 'uploading':
                        return (
                          <p key={upload.id}>
                            Uploading
                            {upload.file.name}
                            :
                            {upload.progress}
                            %
                          </p>
                        );
                      case 'error':
                        return (
                          <p key={upload.id}>
                            Error uploading
                            {upload.file.name}
                            :
                            {upload.error}
                          </p>
                        );
                      case 'finished':
                        return (
                          <p key={upload.id}>
                            Finished uploading
                            {upload.file.name}
                          </p>
                        );
                      default:
                        return 0;
                    }
                  })}
                </div>
              )}
            /> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={handleReject}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

CustomerTestModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  additionalData: PropTypes.instanceOf(Object),
};

CustomerTestModal.defaultProps = {
  additionalData: {},
};

export default CustomerTestModal;
