import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import Logo from 'images/logo.svg';
import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_EXPORT_OPTIONS_MODAL } from '../../constants';

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleDownloadCSV = this.handleDownloadCSV.bind(this);
    this.handleOrdersExport = this.handleOrdersExport.bind(this);
    this.handleQuizExport = this.handleQuizExport.bind(this);
    this.handleTblQuizExport = this.handleTblQuizExport.bind(this);
    this.handleScannerExport = this.handleScannerExport.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.showExportNotification = this.showExportNotification.bind(this);
  }

  showExportNotification() {
    alert('The data export has been requested. You will receive an email with download URL when it is completed');
  }

  handleDownloadCSV(e, type) {
    e.preventDefault();
    if (type === 'activity_log') api.activityLog.downloadCSV().then(this.showExportNotification);
    if (type === 'quiz_recommendations') api.quizRecords.downloadRecommendationsCSV().then(this.showExportNotification);
    if (type === 'tbl_quiz_recommendations') api.tblQuizRecords.downloadRecommendationsCSV().then(this.showExportNotification);
  }

  handleOrdersExport(e) {
    e.preventDefault();
    callEvent(
      DISPLAY_EXPORT_OPTIONS_MODAL,
      {
        handleSubmit: (data) => {
          api.orders.downloadCSV(data, 'orders').then(this.showExportNotification);
        },
      },
    );
  }

  handleQuizExport(e) {
    e.preventDefault();
    callEvent(
      DISPLAY_EXPORT_OPTIONS_MODAL,
      {
        handleSubmit: (data) => {
          api.quizRecords.downloadCSV(data, 'quiz').then(this.showExportNotification);
        },
      },
    );
  }

  handleTblQuizExport(e) {
    e.preventDefault();
    callEvent(
      DISPLAY_EXPORT_OPTIONS_MODAL,
      {
        handleSubmit: (data) => {
          api.tblQuizRecords.downloadCSV(data, 'tbl_quiz').then(this.showExportNotification);
        },
      },
    );
  }

  handleScannerExport(e) {
    e.preventDefault();
    callEvent(
      DISPLAY_EXPORT_OPTIONS_MODAL,
      {
        handleSubmit: (data) => {
          api.quizRecords.downloadCSV(data, 'scanner').then(this.showExportNotification);
        },
      },
    );
  }

  handleLogout(e) {
    e.preventDefault();

    api.auth.logout()
      .then(() => { window.location.href = '/'; });
  }

  disable2FA() {
    if (window.confirm('Disable 2FA!')) api.auth.disable2FA().then(() => { window.location.reload(); });
  }

  render() {
    const {
      user, canExportQuizRecords, canExportTblQuizRecords, canExportActivityLog,
    } = this.props;

    return (
      <nav className="navbar navbar-expand-lg">
        <img src={Logo} alt="logo" className="nav-logo" />

        <button type="button" className="btn" id="menu-toggle">
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            {user ? (
              <>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="emailDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {user.email}
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="emailDropdown">
                    {canExportActivityLog && (
                      <a className="dropdown-item" href="#" onClick={(e) => this.handleDownloadCSV(e, 'activity_log')}>
                        Export
                        Activity Log for last 30 days
                      </a>
                    )}

                    <a className="dropdown-item" href="/discounts/generate_codes">Generate Discount Codes</a>
                    <a className="dropdown-item" href="/admin/users/edit">Change Password</a>
                    {user.otp_required_for_login ? (
                      <a className="dropdown-item" href="#" onClick={this.disable2FA}>Disable 2FA</a>
                    ) : (
                      <a className="dropdown-item" href="/two_factor_settings/new">Enable 2FA</a>
                    )}
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" href="#" onClick={this.handleLogout}>Sign Out</a>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="strandsDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Strands
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="strandsDropdown">
                    <a className="dropdown-item" href="#" onClick={this.handleOrdersExport}>
                      Export Orders
                    </a>
                  </div>
                </li>
                { canExportQuizRecords && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="thlDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      The Hair Lab
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="thlDropdown">
                      <a className="dropdown-item" href="#" onClick={this.handleQuizExport}>
                        Export Quiz Records
                      </a>
                      <a className="dropdown-item" href="#" onClick={this.handleScannerExport}>
                        Export Scanner Records
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => this.handleDownloadCSV(e, 'quiz_recommendations')}
                      >
                        Export Quiz Recommendations Stats for this month
                      </a>
                    </div>
                  </li>
                )}

                {canExportTblQuizRecords && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="tblDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      The Body Lab
                    </a>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="tblDropdown">
                      <a className="dropdown-item" href="#" onClick={this.handleTblQuizExport}>
                        Export Quiz Records
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={(e) => this.handleDownloadCSV(e, 'tbl_quiz_recommendations')}
                      >
                        Export Quiz Recommendations Stats for this month
                      </a>
                    </div>
                  </li>
                )}
              </>
            ) : (
              <li className="nav-item">
                <a className="dropdown-item" href="/admin/users/sign_in">Sign In</a>
              </li>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  user: PropTypes.instanceOf(Object),
  canExportQuizRecords: PropTypes.bool.isRequired,
  canExportTblQuizRecords: PropTypes.bool.isRequired,
  canExportActivityLog: PropTypes.bool.isRequired,
};

Navbar.defaultProps = {
  user: null,
};

export default Navbar;
