import React from 'react';
import PropTypes from 'prop-types';

class CancelSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleCheck = this.handleCheck.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.getFormData = this.getFormData.bind(this);

    this.state = {
      options: [
        { id: 'didnt_like', label: 'I didn’t like my Strands products.', checked: false },
        {
          id: 'too_expensive',
          label: 'The subscription was too expensive.',
          checked: false,
          questions: [{
            id: 'product_worth',
            title: 'What could we do in order to make the value of the product worth it?',
            options: [{
              id: 'bigger_bottles',
              label: 'Bigger bottles',
              checked: false,
            }, {
              id: 'product_quality',
              label: 'Improve product quality',
              checked: false,
            }, {
              id: 'decrease_price',
              label: 'Decrease price',
              checked: false,
            }],
          }, {
            id: 'test_kit_own',
            title: 'Would you have been interested in purchasing the test kit on it\'s own?',
            type: 'radio',
            options: [{
              id: '1',
              label: 'Yes',
              checked: false,
            }, {
              id: '0',
              label: 'No',
              checked: false,
            }],
          }],
        },
        {
          id: 'found_better',
          label: 'I found a better hair care brand.',
          checked: false,
          questions: [{
            id: 'switched_products',
            title: 'What products have you switched over to?',
            type: 'textarea',
            value: '',
          }, {
            id: 'switched_products_reason',
            title: 'Why did you switch products?',
            options: [{
              id: 'better_performance',
              label: 'Better performance',
              checked: false,
            }, {
              id: 'better_cost',
              label: 'Better cost',
              checked: false,
            }, {
              id: 'bigger_bottles',
              label: 'Bigger bottles',
              checked: false,
            }, {
              id: 'easier_to_find',
              label: 'Easier to find',
              checked: false,
            }, {
              id: 'other',
              label: 'Other',
              checked: false,
              text_id: 'switched_products_reason_other',
              value: '',
            }],
          }],
        },
        {
          id: 'poor_experience',
          label: 'I had a poor experience.',
          checked: false,
          questions: [{
            id: 'under_expectations',
            title: 'What part of your journey did you have experience that was under expectations?',
            options: [{
              id: 'test_kit',
              label: 'Test Kit',
              checked: false,
              questions: [{
                id: 'test_kit_under_expectations',
                title: 'What about your test kit experience was under expectations?',
                options: [{
                  id: 'sample_collection',
                  label: 'Hair sample collection',
                  checked: false,
                }, {
                  id: 'time_to_activate',
                  label: 'Time to activate',
                  checked: false,
                }, {
                  id: 'questions_asked',
                  label: 'Questions asked',
                  checked: false,
                }, {
                  id: 'hair_results',
                  label: 'Hair results',
                  checked: false,
                }, {
                  id: 'fragrance_options',
                  label: 'Fragrance options',
                  checked: false,
                }, {
                  id: 'scalp_testing',
                  label: 'Scalp sebum testing',
                  checked: false,
                }, {
                  id: 'test_report',
                  label: 'Test Report',
                  checked: false,
                }],
              }],
            }, {
              id: 'fragrance',
              label: 'Fragrance',
              checked: false,
              questions: [{
                id: 'likes_fragrance',
                title: 'Did you like the fragrance you selected when you smelled it during your test kit activation?',
                type: 'radio',
                options: [{
                  id: '1',
                  label: 'Yes',
                  checked: false,
                }, {
                  id: '0',
                  label: 'No',
                  checked: false,
                }],
              }, {
                id: 'fragrance_under_expectations',
                title: 'What about the fragrance experience was under expectations?',
                options: [{
                  id: 'too_strong',
                  label: 'The fragrance was too strong',
                  checked: false,
                }, {
                  id: 'too_weak',
                  label: 'The fragrance was too weak',
                  checked: false,
                }, {
                  id: 'not_long_lasting',
                  label: 'The fragrance was not long lasting',
                  checked: false,
                }, {
                  id: 'different_smell',
                  label: 'The fragrance smelled different then the scent card',
                  checked: false,
                }, {
                  id: 'smelled_off',
                  label: 'The fragrance smelled off',
                  checked: false,
                }, {
                  id: 'other',
                  label: 'Other',
                  checked: false,
                  text_id: 'fragrance_under_expectations_other',
                  value: '',
                }],
              }],
            }, {
              id: 'product',
              label: 'Product',
              checked: false,
            }, {
              id: 'shipping',
              label: 'Shipping',
              checked: false,
            }, {
              id: 'customer_service',
              label: 'Customer Service',
              checked: false,
            }, {
              id: 'price',
              label: 'Price',
              checked: false,
            }],
          }],
        },
        { id: 'moved_out', label: 'I moved out of the country.', checked: false },
        {
          id: 'other',
          label: 'Other',
          checked: false,
          text_id: 'cancel_reason_other',
          value: '',
        },
      ],
    };
  }

  handleCheck(e, question, option) {
    const { options } = this.state;
    const { checked } = e.target;
    // eslint-disable-next-line no-param-reassign
    option.checked = checked;
    if (question.type === 'radio') {
      // eslint-disable-next-line no-param-reassign
      question.options.find((o) => o !== option).checked = !checked;
    }
    this.setState({ options: [...options] });
  }

  handleText(e, option) {
    const { options } = this.state;
    const { value } = e.target;
    // eslint-disable-next-line no-param-reassign
    option.value = value;
    this.setState({ options: [...options] });
  }

  getFormData(question, fd) {
    // eslint-disable-next-line no-param-reassign
    if (question.type === 'textarea') fd[question.id] = question.value;
    else if (question.options) {
      // eslint-disable-next-line no-param-reassign
      fd[question.id] = [];
      question.options.forEach((option) => {
        // eslint-disable-next-line no-param-reassign
        if (question.type === 'radio' && option.checked) fd[question.id] = option.id;
        if (question.type !== 'radio' && option.checked) fd[question.id].push(option.id);
        // eslint-disable-next-line no-param-reassign
        if (option.checked && option.text_id) fd[option.text_id] = option.value;
        if (option.checked && option.questions) option.questions.forEach((q) => this.getFormData(q, fd));
      });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { options } = this.state;
    const { handleSubmit } = this.props;

    const data = {};
    this.getFormData({ id: 'cancel_reason', options }, data);
    if (!data.cancel_reason.length) return;

    handleSubmit(data);
  }

  renderQuestion(question) {
    return (
      <div>
        { Boolean(question.title) && (
        <h6>
          {'- '}
          {question.title}
        </h6>
        ) }

        {question.type === 'textarea' && (
          <textarea
            className="form-control"
            id={question.id}
            name={question.id}
            value={question.value}
            onChange={(e) => this.handleText(e, question)}
          />
        )}

        {(question.options || []).map((option) => (
          <div className="form-group" key={`${question.id}-${option.id}`}>
            <label className="mr-4" htmlFor={`${question.id}-${option.id}`}>{option.label}</label>
            <input
              type={question.type || 'checkbox'}
              className="form-check-input"
              name={question.id}
              id={`${question.id}-${option.id}`}
              value={option.id}
              checked={option.checked}
              onChange={(e) => this.handleCheck(e, question, option)}
            />

            { Boolean(option.text_id && option.checked) && (
              <div>
                <textarea
                  className="form-control"
                  id={option.text_id}
                  name={option.text_id}
                  value={option.value}
                  onChange={(e) => this.handleText(e, option)}
                />
              </div>
            ) }

            { Boolean(option.questions && option.checked) && (
              <div className="ml-3">
                { option.questions.map((q) => this.renderQuestion(q)) }
              </div>
            ) }
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { options } = this.state;
    const { handleReject } = this.props;

    return (
      <div
        className="modal fade"
        id="cancelSubscriptionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="cancelSubscriptionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="cancelSubscriptionModalLabel">Cancel Subscription Reason:</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={this.handleFormSubmit}>
              <div className="modal-body">
                { this.renderQuestion({ id: 'cancel_reason', options })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={handleReject}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CancelSubscriptionModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
};

export default CancelSubscriptionModal;
