import React from 'react';
import PropTypes from 'prop-types';
import ActiveStorageProvider from 'react-activestorage-provider';
import Select from 'react-select';

import api from '../../services/api';
import IngredientsTable from './IngredientsTable';
import { successToast } from '../../services/toast';

class EditIngredientsForm extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleIngredientSelect = this.handleIngredientSelect.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleAddInci = this.handleAddInci.bind(this);
    this.handleDeleteInci = this.handleDeleteInci.bind(this);
    this.handleIngredientUpdate = this.handleIngredientUpdate.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleFetch = this.handleFetch.bind(this);

    this.defaultState = {
      step: 1,
      selectedIngredient: null,
      name: '',
      vendorName: '',
      vendor: '',
      description: '',
      webDescription: '',
      category: '',
      inci: '',
      cost: '',
      costUnits: 'per lbs',
      moq: '',
      partNumber: '',
      tags: props.tags,
      ingredients: props.ingredients,
      selectedTags: [],
      forTexture: false,
      forScalp: false,
      isVisible: true,
      photo: null,
      photoUrl: null,
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Step change
  handleStepChange(e, val = 1) {
    const { step } = this.state;
    if (e) e.preventDefault();
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Step 1 - Ingredient select
  handleIngredientSelect(ingredient) {
    this.setState({
      selectedIngredient: ingredient,
      name: ingredient.name,
      vendorName: ingredient.vendor_name,
      vendor: ingredient.vendor,
      description: ingredient.description,
      webDescription: ingredient.web_description,
      category: ingredient.category,
      inci: ingredient.inci,
      cost: ingredient.cost,
      costUnits: ingredient.cost_units,
      moq: ingredient.moq,
      partNumber: ingredient.part_number,
      forTexture: ingredient.for_texture,
      forScalp: ingredient.for_scalp,
      isVisible: ingredient.is_visible,
      selectedTags: ingredient.tags,
      photo: ingredient.image,
      photoUrl: ingredient.image,
    }, () => this.handleStepChange());
  }

  // Checkboxes
  handleCheckBoxChange(id) {
    if ($(`input[name="${id}"]:checked`).length === $(`input[name="${id}"]`).length) {
      $(`input[id="${id}"]`).prop('checked', true);
    } else if ($(`input[id="${id}"]`).prop('checked')) {
      $(`input[id="${id}"]`).prop('checked', false);
    }
  }

  handleSelectAll(e, id) {
    const { checked } = e.target;
    $(`input[name="${id}"]`).prop('checked', checked);
  }

  // INCI
  handleAddInci() {
    this.setState((prevState) => ({
      inci: [...prevState.inci, {
        name: '',
        mass_fraction: 0,
      }],
    }));
  }

  handleDeleteInci(index) {
    const { inci } = this.state;

    this.setState({
      inci: inci.filter((e, i) => i !== index),
    });
  }

  // Step 3 - Update
  handleIngredientUpdate() {
    const {
      name, vendorName, vendor, description, webDescription, category, inci, cost, costUnits,
      moq, selectedTags, isVisible, photo, selectedIngredient, partNumber,
      forTexture, forScalp,
    } = this.state;

    const rules = [];

    const data = {
      name,
      vendor_name: vendorName,
      vendor,
      description,
      web_description: webDescription,
      category: category === 'null' ? null : category,
      inci,
      cost,
      cost_units: costUnits,
      moq,
      part_number: partNumber,
      tags: selectedTags.map((t) => t.value),
      for_texture: forTexture,
      for_scalp: forScalp,
      is_visible: isVisible,
      photo: photo === selectedIngredient.image ? undefined : photo,
      rules,
    };

    api.editIngredients.update(data, selectedIngredient.id).then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetch);
  }

  handleFetch() {
    api.editIngredients.fetch().then((res) => this.setState({ tags: res.tags, ingredients: res.ingredients }));
  }

  render() {
    const {
      step, photoUrl, selectedIngredient, inci, tags, ingredients,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body custom-form-block">
          <div className="custom-info-block">
            {selectedIngredient ? `Edit ingredient (#${selectedIngredient.id})` : 'View Ingredients'}
          </div>
          <div className="custom-stepper">
            <span className={`custom-stepper-step ${step === 1 ? 'active' : ''}`}>
              1. Choose Ingredient
            </span>
            <span className="custom-stepper-arrow">
              {'>'}
            </span>
            <span className={`custom-stepper-step ${step === 2 ? 'active' : ''}`}>
              2. Base Info
            </span>
          </div>
          {step === 1 && (
            <>
              <button
                type="button"
                className="btn btn-primary mb-4"
                onClick={() => window.open('/ingredients/pdf_list', '_blank').focus()}
              >
                Print PDF List
              </button>
              <IngredientsTable ingredients={ingredients} handleIngredientSelect={this.handleIngredientSelect} />
            </>
          )}
          {step === 2 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleIngredientUpdate();
              }}
              ref={(form) => { this.formStep2 = form; }}
            >
              <div className="form-group">
                <label htmlFor="name">
                  Name
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  defaultValue={selectedIngredient.name}
                  onChange={this.handleChange('name')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="vendor_name">
                  Vendor Name
                </label>
                <input
                  required
                  type="text"
                  name="vendor_name"
                  className="form-control"
                  id="vendor_name"
                  defaultValue={selectedIngredient.vendor_name}
                  onChange={this.handleChange('vendorName')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="vendor">
                  Vendor
                </label>
                <input
                  required
                  type="text"
                  name="vendor"
                  className="form-control"
                  id="vendor"
                  defaultValue={selectedIngredient.vendor}
                  onChange={this.handleChange('vendor')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="web_description">
                  Web Report Description
                </label>
                <textarea
                  required
                  type="text"
                  name="web_description"
                  className="form-control"
                  id="web_description"
                  defaultValue={selectedIngredient.web_description}
                  onChange={this.handleChange('webDescription')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">
                  Tri-Fold Description
                </label>
                <textarea
                  required
                  type="text"
                  name="description"
                  className="form-control"
                  id="description"
                  defaultValue={selectedIngredient.description}
                  onChange={this.handleChange('description')}
                />
              </div>
              <div className="form-group">
                <div style={{ width: '30%' }}>
                  <label htmlFor="category">
                    Category
                  </label>
                  <select
                    id="category"
                    name="category"
                    defaultValue={selectedIngredient.category}
                    onChange={this.handleChange('category')}
                  >
                    <option value="null">(none)</option>
                    <option>Scalp</option>
                    <option>Boost</option>
                    <option>Protect</option>
                    <option>Revive</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="custom-inputs-row">
                  <div style={{ width: '70%' }}>
                    INCI
                  </div>
                  <div style={{ width: '30%' }}>
                    Mass fraction, %
                  </div>
                </div>
                {inci.map((item, index) => (
                  <div className="custom-inputs-row">
                    <input
                      type="text"
                      value={item.name}
                      name={`inci-name-${index}`}
                      className="form-control"
                      id={`inci-name-${index}`}
                      style={{ width: '70%' }}
                      onChange={(e) => this.setState({
                        inci: inci.map((i, ind) => (ind === index
                          ? ({ ...i, name: e.target.value }) : i)),
                      })}
                    />
                    <div style={{ display: 'flex', width: '30%' }}>
                      <input
                        type="number"
                        step="0.01"
                        min="0"
                        max="100"
                        value={item.mass_fraction}
                        name={`inci-fraction-${index}`}
                        className="form-control"
                        id={`inci-fraction-${index}`}
                        onChange={(e) => this.setState({
                          inci: inci.map((i, ind) => (ind === index
                            ? ({ ...i, mass_fraction: parseFloat(e.target.value) }) : i)),
                        })}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.handleDeleteInci.bind(this, index)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="form-group custom-inputs-row">
                <div style={{ width: '70%' }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleAddInci}
                  >
                    Add INCI
                  </button>
                </div>
                {inci.length > 0 && (
                  <div style={{ fontWeight: 500, fontSize: '1.25rem', width: '30%' }}>
                    {`TOTAL: ${inci.reduce((accumulator, currentValue) => accumulator + currentValue.mass_fraction, 0)}%`}
                  </div>
                )}
              </div>
              <div className="form-group input-with-support-row mt-5">
                <div style={{ width: '40%' }}>
                  <label htmlFor="moq">
                    MOQ
                  </label>
                  <input
                    required
                    type="number"
                    step="0.01"
                    name="moq"
                    className="form-control"
                    id="moq"
                    defaultValue={selectedIngredient.moq}
                    onChange={this.handleChange('moq')}
                  />
                </div>
                <div style={{ width: '40%' }}>
                  <label htmlFor="cost">
                    Cost
                  </label>
                  <input
                    required
                    type="number"
                    step="0.01"
                    min="0"
                    name="cost"
                    className="form-control"
                    id="cost"
                    defaultValue={selectedIngredient.cost}
                    onChange={this.handleChange('cost')}
                  />
                </div>
                <div style={{ width: '20%' }}>
                  <label htmlFor="cost_units">
                    Units
                  </label>
                  <select
                    id="cost_units"
                    name="cost_units"
                    onChange={this.handleChange('costUnits')}
                    defaultValue={selectedIngredient.cost_units}
                  >
                    <option>per lbs</option>
                    <option>per kg</option>
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="tags">
                  Tags
                </label>
                <Select
                  id="tags"
                  name="tags"
                  isMulti
                  isSearchable
                  options={tags.map((item) => (
                    {
                      value: item.id,
                      label: `${item.name} (#${item.id})`,
                    }
                  ))}
                  defaultValue={selectedIngredient.tags.map((item) => (
                    {
                      value: item.id,
                      label: `${item.name} (#${item.id})`,
                    }
                  ))}
                  onChange={(val) => this.setState({ selectedTags: val })}
                />
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="forTexture"
                  id="forTexture"
                  defaultChecked={selectedIngredient.for_texture}
                  onChange={() => this.setState((prevState) => ({ forTexture: !prevState.forTexture }))}
                />
                <label className="form-check-label" htmlFor="forTexture">
                  For Texture ?
                </label>
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="forScalp"
                  id="forScalp"
                  defaultChecked={selectedIngredient.for_scalp}
                  onChange={() => this.setState((prevState) => ({ forScalp: !prevState.forScalp }))}
                />
                <label className="form-check-label" htmlFor="forScalp">
                  For Scalp ?
                </label>
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="isVisible"
                  id="isVisible"
                  defaultChecked={selectedIngredient.is_visible}
                  onChange={() => this.setState((prevState) => ({ isVisible: !prevState.isVisible }))}
                />
                <label className="form-check-label" htmlFor="isVisible">
                  Is Visible ?
                </label>
              </div>
              <div className="form-group">
                <ActiveStorageProvider
                  endpoint={{
                    path: '/ingredients/upload_image',
                    model: 'Ingredient',
                    attribute: 'photo',
                    method: 'POST',
                  }}
                  onSubmit={(res) => this.setState({
                    photo: res.image_code,
                    photoUrl: res.image_url,
                  })}
                  render={({ handleUpload, uploads, ready }) => (
                    <div>
                      <input
                        type="file"
                        className="form-control"
                        disabled={!ready}
                        onChange={(e) => handleUpload(e.currentTarget.files)}
                      />
                      {uploads.map((upload) => {
                        switch (upload.state) {
                          case 'waiting':
                            return (
                              <p key={upload.id}>
                                Waiting to upload
                                {upload.file.name}
                              </p>
                            );
                          case 'uploading':
                            return (
                              <p key={upload.id}>
                                Uploading
                                {upload.file.name}
                                :
                                {upload.progress}
                                %
                              </p>
                            );
                          case 'error':
                            return (
                              <p key={upload.id}>
                                Error uploading
                                {upload.file.name}
                                :
                                {upload.error}
                              </p>
                            );
                          case 'finished':
                            return (
                              <p key={upload.id}>
                                Finished uploading
                                {upload.file.name}
                              </p>
                            );
                          default:
                            return 0;
                        }
                      })}
                    </div>
                  )}
                />
                {photoUrl && (
                  <div className="uploaded-image-container">
                    <img src={photoUrl} alt="uploadedImage" />
                  </div>
                )}
              </div>
              <div className="btn-group custom-btn-continue">
                <button
                  type="button"
                  className="btn btn-secondary btn-back"
                  onClick={(e) => this.handleStepChange(e, -1)}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  Continue
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

EditIngredientsForm.propTypes = {
  tags: PropTypes.instanceOf(Array),
  ingredients: PropTypes.instanceOf(Array),
};

EditIngredientsForm.defaultProps = {
  tags: [],
  ingredients: [],
};

export default EditIngredientsForm;
