import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_TIKTOK_URL_MODAL, DISPLAY_CONFIRMATION_MODAL } from '../../constants';

const formatBrand = (brand) => {
  if (brand === 'thl') return 'The Hair Lab';
  if (brand === 'strands') return 'Stands Hair Care';
  return '';
};

class TiktokUrlsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);

    this.handleTiktokUrlCreate = this.handleTiktokUrlCreate.bind(this);
    this.handleTiktokUrlEdit = this.handleTiktokUrlEdit.bind(this);
    this.handleTiktokUrlDestroy = this.handleTiktokUrlDestroy.bind(this);
    this.handleDownloadCSV = this.handleDownloadCSV.bind(this);

    this.callTiktokUrlModal = this.callTiktokUrlModal.bind(this);

    this.state = {
      tiktokUrls: props.tiktokUrls,
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Add/edit/delete
  handleTiktokUrlCreate(data) {
    const { tiktokUrls } = this.state;
    api.tiktokUrls.create(data)
      .then((res) => this.setState({
        tiktokUrls: [res, ...tiktokUrls],
      }));
  }

  handleTiktokUrlEdit(data, id) {
    const { tiktokUrls } = this.state;
    api.tiktokUrls.update(data, id)
      .then((res) => this.setState({
        tiktokUrls: tiktokUrls.map((p) => (p.id === res.id ? res : p)),
      }));
  }

  handleTiktokUrlDestroy(id) {
    const { tiktokUrls } = this.state;
    api.tiktokUrls.destroy(id)
      .then((res) => {
        this.setState({
          tiktokUrls: tiktokUrls.filter((p) => p.id !== res.id),
        });
      });
  }

  // Modal
  callTiktokUrlModal(additionalData) {
    callEvent(
      DISPLAY_TIKTOK_URL_MODAL,
      {
        handleSubmit: additionalData.type === 'create' ? this.handleTiktokUrlCreate : this.handleTiktokUrlEdit,
        additionalData,
      },
    );
  }

  handleDownloadCSV(id) {
    api.tiktokUrls.downloadCommentsCsv(id)
      .then(() => alert('The data export has been requested. You will receive an email with download URL when it is completed'));
  }

  render() {
    const { canExportComments } = this.props;
    const { tiktokUrls } = this.state;

    return (
      <div className="card">
        <div className="card-body">
          <div className="custom-table-header">
            <div className="custom-table-header-left">Tiktok Scraping Urls</div>
            <div
              className="custom-table-header-right"
              onClick={() => this.callTiktokUrlModal({ type: 'create' })}
            >
              + Add new
            </div>
          </div>
          <table className="table table-striped table-responsive-sm custom-table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th className="text-left" scope="col">Url</th>
                <th scope="col">Name</th>
                <th scope="col">Handle</th>
                <th scope="col">Brand</th>
                <th scope="col">Likes</th>
                <th scope="col">Views</th>
                <th scope="col">Shares</th>
                <th scope="col">Saves</th>
                <th scope="col">Comments</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {tiktokUrls.map((item) => (
                <tr key={item.id}>
                  <th scope="row">{item.id}</th>
                  <td className="text-left" style={{ wordBreak: 'break-all' }}>
                    <a href={item.url} target="_blank" rel="noreferrer">{item.url}</a>
                  </td>
                  <td>
                    {item.author_name || '-'}
                  </td>
                  <td>
                    {item.author_handle || '-'}
                  </td>
                  <td>
                    {formatBrand(item.brand) || '-'}
                  </td>
                  <td>
                    {item.stats?.likes || '-'}
                  </td>
                  <td>
                    {item.stats?.views || '-'}
                  </td>
                  <td>
                    {item.stats?.shares || '-'}
                  </td>
                  <td>
                    {item.stats?.saves || '-'}
                  </td>
                  <td>
                    {item.stats?.comments || '-'}
                  </td>
                  <td>
                    <div className="btn-group" role="group" aria-label="">
                      { canExportComments && (
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => this.handleDownloadCSV(item.id)}
                        >
                          Export Comments
                        </button>
                      ) }
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.callTiktokUrlModal({
                          type: 'update',
                          id: item.id,
                          url: item.url,
                          author_name: item.author_name,
                          author_handle: item.author_handle,
                          brand: item.brand,
                        })}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleTiktokUrlDestroy(item.id))}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

TiktokUrlsContainer.propTypes = {
  tiktokUrls: PropTypes.instanceOf(Array),
  canExportComments: PropTypes.bool.isRequired,
};

TiktokUrlsContainer.defaultProps = {
  tiktokUrls: [],
};

export default TiktokUrlsContainer;
