import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import { successToast } from '../../services/toast';
import CurrencyInput from '../common/CurrencyInput';

function parseCurrency(value) {
  if (typeof value !== 'string') return value;
  return Number(value.replace('$', ''));
}

const settingsNames = {
  subscription_discount: 'Subscription discount (%)',
  publish_report_notification_text: 'Report email notification text',
  gift_set_price: 'Gift set price',
};

const currencyFields = ['gift_set_price'];

class EditSettings extends React.Component {
  constructor(props) {
    super(props);

    this.handleSettingsChange = this.handleSettingsChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.renderInput = this.renderInput.bind(this);

    this.state = {
      settings: props.settings,
    };
  }

  handleSettingsChange = (name) => (event) => {
    const { value, type } = event.target;

    this.setState((prevState) => ({
      settings: prevState.settings.map((setting) => (setting.key === name ? { ...setting, value: type === 'number' ? parseFloat(value) : value } : setting)),
    }));
  }

  handleSave() {
    const { settings } = this.state;
    const parsed = settings.map((setting) => (currencyFields.includes(setting.key) ? { ...setting, value: parseCurrency(setting.value) } : setting));
    api.settings.save({ settings: parsed }).then(() => successToast('Saved'));
  }

  renderInput(setting) {
    if (currencyFields.includes(setting.key)) {
      return (
        <CurrencyInput
          className="form-control"
          id={setting.key}
          name={setting.key}
          value={typeof setting.value === 'string' ? setting.value : setting.value.toFixed(2)}
          onChange={this.handleSettingsChange(setting.key)}
        />
      );
    }

    return (
      <input
        type={`${typeof setting.value === 'string' ? 'text' : 'number'}`}
        step="0.01"
        className="form-control"
        id={setting.key}
        name={setting.key}
        value={setting.value}
        onChange={this.handleSettingsChange(setting.key)}
      />
    );
  }

  render() {
    const { settings } = this.state;

    return (
      <div className="card">
        <div className="card-body">
          {settings.map((setting) => (
            <div className="form-group" key={setting.key}>
              <label htmlFor={setting.key}>{settingsNames[setting.key]}</label>
              { this.renderInput(setting) }
            </div>
          ))}
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.handleSave}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

EditSettings.propTypes = {
  settings: PropTypes.instanceOf(Array),
};

EditSettings.defaultProps = {
  settings: [],
};

export default EditSettings;
