import React from 'react';
import PropTypes from 'prop-types';

import api from '../../services/api';
import callEvent from '../../services/events';
import { DISPLAY_CUSTOMER_TEST_MODAL, DISPLAY_CONFIRMATION_MODAL } from '../../constants';
import Pagination from '../customElements/Pagination';

class CustomerTests extends React.Component {
  constructor(props) {
    super(props);

    this.callConfirmationModal = this.callConfirmationModal.bind(this);
    this.handleCustomerTestCreate = this.handleCustomerTestCreate.bind(this);
    this.handleCustomerTestDestroy = this.handleCustomerTestDestroy.bind(this);
    this.handleCustomerTestEdit = this.handleCustomerTestEdit.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleAddFilters = this.handleAddFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.callCustomerTestModal = this.callCustomerTestModal.bind(this);
    this.handleFetchCustomerTests = this.handleFetchCustomerTests.bind(this);

    const urlParams = new URL(window.location.href).searchParams;
    this.state = {
      customerTests: props.customerTests,
      customers: props.customers,
      testKits: props.testKits,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      filterCustomerId: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).customer_id : 'Any',
      filterTestKitId: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).test_kit_id : 'Any',
      filterStatus: urlParams.get('filter') ? JSON.parse(urlParams.get('filter')).status : 'Without Published',
      filter: {},
      pagination: {
        currentPage: urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
        isLastPage: props.isLastPage,
      },
    };
  }

  // events
  callConfirmationModal(question, callback, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: callback,
      question,
      additionalData,
    });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchCustomerTests(null, order);
  }

  // Add/edit/delete
  handleCustomerTestCreate(data) {
    const { customerTests } = this.state;
    api.customerTests.create(data)
      .then((res) => this.setState({
        customerTests: [res, ...customerTests],
      }));
  }

  handleCustomerTestEdit(data, id) {
    const { customerTests } = this.state;
    api.customerTests.update(data, id)
      .then((res) => this.setState({
        customerTests: customerTests.map((p) => (p.id === res.id ? res : p)),
      }));
  }

  handleCustomerTestDestroy(id) {
    const { customerTests } = this.state;
    api.customerTests.destroy(id)
      .then((res) => {
        this.setState({
          customerTests: customerTests.filter((p) => p.id !== res.id),
        });
      });
  }

  // Filters
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddFilters(e) {
    if (e) e.preventDefault();
    const { filterCustomerId, filterTestKitId, filterStatus } = this.state;
    const filter = {};

    if (filterCustomerId && filterCustomerId !== 'Any') {
      filter.customer_id = filterCustomerId;
    }
    if (filterTestKitId && filterTestKitId !== 'Any') {
      filter.test_kit_id = filterTestKitId;
    }
    if (filterStatus && filterStatus !== 'Any') {
      filter.status = filterStatus;
    }

    this.setState({ filter }, () => this.handleFetchCustomerTests(1));
  }

  clearFilters() {
    this.filtersForm.reset();
    this.setState({
      filterCustomerId: '',
      filterTestKitId: '',
      filterStatus: '',
      filter: {},
    }, () => this.handleFetchCustomerTests(1));
  }

  // Modal
  callCustomerTestModal(additionalData) {
    callEvent(
      DISPLAY_CUSTOMER_TEST_MODAL,
      {
        handleSubmit: additionalData.type === 'create'
          ? this.handleCustomerTestCreate : this.handleCustomerTestEdit,
        additionalData,
      },
    );
  }

  handleFetchCustomerTests(newPage, newOrder) {
    const { pagination, lastSortKey, filter } = this.state;
    const page = newPage || pagination.currentPage;
    const order = newOrder || lastSortKey;
    const params = `?page=${page}&order=${order}&filter=${JSON.stringify(filter)}`;
    window.history.pushState({}, null, params);

    api.customerTests.fetchCustomerTests(params)
      .then((res) => this.setState({
        customerTests: res.customer_tests,
        lastSortKey: order,
        pagination: {
          ...pagination,
          currentPage: page,
          isLastPage: res.is_last_page,
        },
      }));
  }

  render() {
    const {
      customerTests, testKits, customers, pagination,
      filterCustomerId, filterTestKitId, filterStatus,
    } = this.state;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row ml-0">
            <button
              type="button"
              id="custom-filters-toggle"
              className="btn btn-primary"
              onClick={() => $('#custom-filters').toggle(100)}
            >
              Toggle Filters
            </button>
            <div className="ml-4">
              {`Current Filter: { CustomerId: ${filterCustomerId || 'Any'}, TestKitId: ${filterTestKitId || 'Any'}, Status: ${filterStatus || 'Any'} }`}
            </div>
          </div>
          <div id="custom-filters" className="custom-filters-block">
            <div>Filters</div>
            <form onSubmit={this.handleAddFilters} ref={(form) => { this.filtersForm = form; }}>
              <div className="form-group">
                <label htmlFor="filterCustomerId">Customer ID</label>
                <select
                  id="filterCustomerId"
                  name="filterCustomerId"
                  onChange={this.handleChange('filterCustomerId')}
                  value={filterCustomerId}
                >
                  <option>Any</option>
                  {customers.map((item) => (
                    <option
                      key={item.id}
                      label={`${item.uid} (#${item.id})`}
                    >
                      {item.id}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="filterTestKitId">TestKit ID</label>
                <select
                  id="filterTestKitId"
                  name="filterTestKitId"
                  onChange={this.handleChange('filterTestKitId')}
                  value={filterTestKitId}
                >
                  <option>Any</option>
                  {testKits.map((item) => (
                    <option
                      key={item.id}
                      label={`#${item.id}`}
                    >
                      {item.id}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="filterStatus">Status</label>
                <select
                  id="filterStatus"
                  name="filterStatus"
                  onChange={this.handleChange('filterStatus')}
                  value={filterStatus}
                >
                  <option>Any</option>
                  <option>Without Published</option>
                  <option>Created</option>
                  <option>InProcess</option>
                  <option>Finished</option>
                  <option>Published</option>
                  <option>Failure</option>
                </select>
              </div>
              <div className="btn-group" role="group" aria-label="">
                <button
                  id="filterSubmit"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add filters
                </button>
                <button
                  id="clearFilterSubmit"
                  type="button"
                  className="btn btn-primary"
                  onClick={this.clearFilters}
                >
                  Clear filters
                </button>
              </div>
            </form>
          </div>
          <div className="custom-table-header">
            <div className="custom-table-header-left">Customer Tests</div>
            <div
              className="custom-table-header-right"
              onClick={() => this.callCustomerTestModal({
                type: 'create',
                customers,
                testKits,
              })}
            >
              + Add new
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-responsive-sm custom-table">
              <thead>
                <tr>
                  <th scope="col" onClick={() => this.handleSort('id')}>Customer Test id</th>
                  <th scope="col" onClick={() => this.handleSort('customer_id')}>Customer id</th>
                  <th scope="col" onClick={() => this.handleSort('test_kit_id')}>Test Kit</th>
                  <th scope="col" onClick={() => this.handleSort('user_id')}>User id</th>
                  <th scope="col" onClick={() => this.handleSort('status')}>Status</th>
                  <th scope="col" onClick={() => this.handleSort('result_avg_protein_loss')}>Protein loss</th>
                  <th scope="col" onClick={() => this.handleSort('result_avg_hair_diam')}>Hair diam</th>
                  <th scope="col" onClick={() => this.handleSort('result_avg_sample_weight')}>Sample weight</th>
                  <th scope="col" onClick={() => this.handleSort('result_avg_microscopic_analysis_score')}>Microscopic analysis score</th>
                  <th scope="col">Images</th>
                  <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                  <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {customerTests.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.customer_id}</td>
                    <td>{`[${item.test_kit_id}] ${item.testKit_code}`}</td>
                    <td>{item.user_id}</td>
                    <td>{item.status}</td>
                    <td>{item.result_avg_protein_loss}</td>
                    <td>{item.result_avg_hair_diam}</td>
                    <td>{item.result_avg_sample_weight}</td>
                    <td>{item.result_avg_microscopic_analysis_score}</td>
                    <td>
                      {item.cuticle_images ? item.cuticle_images.map((image) => (
                        <img key={image} src={image} alt="img" width="150px" />
                      )) : 'no images'}
                    </td>
                    <td>{new Date(item.created_at).toLocaleString()}</td>
                    <td>{new Date(item.updated_at).toLocaleString()}</td>
                    <td>
                      <div className="btn-group" role="group" aria-label="">
                        <button
                          type="button"
                          className="btn btn-warning"
                          onClick={() => this.callCustomerTestModal({
                            type: 'update',
                            customer_id: item.customer_id,
                            test_kit_id: item.test_kit_id,
                            status: item.status,
                            result_avg_protein_loss: item.result_avg_protein_loss,
                            result_avg_hair_diam: item.result_avg_hair_diam,
                            result_avg_sample_weight: item.result_avg_sample_weight,
                            result_avg_microscopic_analysis_score: item.result_avg_microscopic_analysis_score,
                            cuticle_images: item.cuticle_images,
                            id: item.id,
                            customers,
                            testKits,
                          })}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => this.callConfirmationModal('Are you sure ?', () => this.handleCustomerTestDestroy(item.id))}
                        >
                          Remove
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={pagination.currentPage}
            isLastPage={pagination.isLastPage}
            handlePageChange={this.handleFetchCustomerTests}
          />
        </div>
      </div>
    );
  }
}

CustomerTests.propTypes = {
  customerTests: PropTypes.instanceOf(Array),
  customers: PropTypes.instanceOf(Array),
  testKits: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
};

CustomerTests.defaultProps = {
  customerTests: [],
  customers: [],
  testKits: [],
  isLastPage: false,
};

export default CustomerTests;
