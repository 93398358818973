import React from 'react';

import api from '../../services/api';

class EnableTwoFactor extends React.Component {
  constructor(props) {
    super(props);

    this.handleSetPhone = this.handleSetPhone.bind(this);
    this.handle2FA = this.handle2FA.bind(this);

    this.state = {
      code: '',
      password: '',
      phoneNumber: '',
      step: 0,
    };
  }

  handleSetPhone(e) {
    if (e) e.preventDefault();
    const { phoneNumber } = this.state;

    api.auth.sendCode({ phone_number: phoneNumber }).then(() => {
      this.setState({
        step: 1,
      });
    });
  }

  handle2FA(e) {
    if (e) e.preventDefault();
    const { code, password } = this.state;

    api.auth.enable2FA({ code, password }).then((res) => {
      if (res.ok) window.location.href = '/';
    });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { step } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2>Two Factor Authentication</h2>
          </div>
        </div>
        {step === 0 && (
          <div className="row">
            <div className="col-md-12">
              <div className="card-deck">
                <div className="card">
                  <div className="card-header">
                    1. Set Your phone
                  </div>
                  <div className="card-body">
                    <form onSubmit={this.handleSetPhone}>
                      <div className="form-group">
                        <label htmlFor="phoneNumber">Phone number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phoneNumber"
                          placeholder="+"
                          onChange={this.handleChange('phoneNumber')}
                        />
                      </div>
                      <div className="btn-group" role="group" aria-label="">
                        <button
                          id="filterSubmit"
                          type="submit"
                          className="btn btn-primary"
                        >
                          Send code
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 1 && (
          <div className="row">
            <div className="col-md-12">
              <div className="card-deck">
                <div className="card">
                  <div className="card-header">
                    2. Confirm OTP Code
                  </div>
                  <div className="card-body">
                    <form onSubmit={this.handle2FA}>
                      <div className="form-group">
                        <label htmlFor="code">Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="code"
                          onChange={this.handleChange('code')}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          onChange={this.handleChange('password')}
                        />
                      </div>
                      <div className="btn-group" role="group" aria-label="">
                        <button
                          id="filterSubmit"
                          type="submit"
                          className="btn btn-primary"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EnableTwoFactor;
