import React from 'react';
import PropTypes from 'prop-types';

class IngredientsTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleUpdateContent = this.handleUpdateContent.bind(this);

    this.state = {
      ingredients: props.ingredients,
      lastSortKey: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { ingredients } = this.props;
    if (prevProps.ingredients !== ingredients) this.handleUpdateContent(ingredients);
  }

  handleUpdateContent(ingredients) {
    this.setState({ ingredients, lastSortKey: '' });
  }

  // Sort
  handleSort(sortKey) {
    const { ingredients, lastSortKey } = this.state;
    const sortedIngredients = (['id', 'moq', 'for_texture', 'for_scalp', 'is_visible'].includes(sortKey))
      ? ingredients.sort((a, b) => a[sortKey] - b[sortKey])
      : ingredients.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    const reverseSort = lastSortKey === sortKey;
    this.setState({
      ingredients: reverseSort ? sortedIngredients.reverse() : sortedIngredients,
      lastSortKey: reverseSort ? '' : sortKey,
    });
  }

  render() {
    const { ingredients } = this.state;
    const { handleIngredientSelect } = this.props;

    return (
      <div className="table-responsive">
        <table className="table table-striped table-responsive-sm custom-table">
          <thead>
            <tr>
              <th scope="col" onClick={() => this.handleSort('id')}>ID</th>
              <th scope="col" onClick={() => this.handleSort('part_number')}>Part Number</th>
              <th scope="col" style={{ minWidth: '10rem', maxWidth: '12rem' }}>
                Name /
                <br />
                Vendor Name
              </th>
              <th scope="col" style={{ minWidth: '15rem' }} onClick={() => this.handleSort('vendor')}>Vendor</th>
              <th scope="col" style={{ minWidth: '15rem' }} onClick={() => this.handleSort('description')}>Description</th>
              <th scope="col" style={{ minWidth: '20rem' }}>INCI</th>
              <th scope="col" style={{ minWidth: '8rem' }}>Cost</th>
              <th scope="col" onClick={() => this.handleSort('moq')}>MOQ</th>
              <th scope="col" onClick={() => this.handleSort('for_texture')}>For Texture</th>
              <th scope="col" onClick={() => this.handleSort('for_scalp')}>For Scalp</th>
              <th scope="col" onClick={() => this.handleSort('is_visible')}>Is Visible</th>
              {/* <th scope="col">Rules</th> */}
              <th scope="col">Tags</th>
              <th scope="col">Image</th>
              <th scope="col" style={{ minWidth: '13rem' }}>
                Created /
                <br />
                Updated
              </th>
            </tr>
          </thead>
          <tbody>
            {ingredients.map((item) => (
              <tr className="custom-tr-selectable" key={item.id} onClick={() => handleIngredientSelect(item)}>
                <th scope="row">{item.id}</th>
                <td>{item.part_number}</td>
                <td>
                  {item.name}
                  <br />
                  {item.vendor_name}
                </td>
                <td>{item.vendor}</td>
                <td>{item.description}</td>
                <td>
                  {item.inci.length > 0 ? (
                    item.inci.map((i, index) => <div key={i.name}>{`#${index + 1}: ${i.name}, ${i.mass_fraction}%`}</div>)
                  ) : 'no INCI'}
                </td>
                <td>{`$${item.cost} ${item.cost_units}`}</td>
                <td>{item.moq}</td>
                <td>{item.for_texture.toString()}</td>
                <td>{item.for_scalp.toString()}</td>
                <td>{item.is_visible.toString()}</td>
                {/* <td>{JSON.stringify(item.rules)}</td> */}
                <td>
                  {item.tags.length > 0 ? (
                    item.tags.map((i, index) => `Tag #${index + 1}: ${i.name}, ${i.tag_type}; `)
                  ) : 'no tags'}
                </td>
                <td>
                  {item.image ? (
                    <img src={item.image} alt="img" style={{ width: '6rem' }} />
                  ) : '(no image)'}
                </td>
                <td>
                  {new Date(item.created_at).toLocaleString()}
                  <br />
                  {new Date(item.updated_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

IngredientsTable.propTypes = {
  ingredients: PropTypes.instanceOf(Array),
  handleIngredientSelect: PropTypes.func,
};

IngredientsTable.defaultProps = {
  ingredients: [],
  handleIngredientSelect: () => {},
};

export default IngredientsTable;
