import React from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';

import LabelContainer from '../label/LabelContainer';
import api from '../../services/api';
import codeErrors from '../../services/codeValidators';
import isSGDiscountCode from '../../services/discounts';
import { jsHumanize, formatDate } from '../../services/parsers';
import { handleBeforePrint } from '../../services/print';
import { GetProductType, DISPLAY_CONFIRMATION_MODAL } from '../../constants';
import IngredientsTable from '../ingredients/IngredientsTable';
import callEvent from '../../services/events';
import Pagination from '../customElements/Pagination';
import CustomerInfoSection from '../common/CustomerInfoSection';
import { successToast } from '../../services/toast';
import ShopifyOrderLink from '../common/ShopifyOrderLink';

class ProductsQueue extends React.Component {
  constructor(props) {
    super(props);

    this.setUrlStep = this.setUrlStep.bind(this);
    this.labelData = this.labelData.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.verifyCode = this.verifyCode.bind(this);
    this.callConfirmationModal = this.callConfirmationModal.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);
    this.onLabelContainerMount = this.onLabelContainerMount.bind(this);
    this.fetchLabels = this.fetchLabels.bind(this);
    this.renderCocktailInputs = this.renderCocktailInputs.bind(this);
    this.handleFetchIngredients = this.handleFetchIngredients.bind(this);
    this.handleCalculateFormulation = this.handleCalculateFormulation.bind(this);
    this.getUniqueObjectsFromArray = this.getUniqueObjectsFromArray.bind(this);
    this.handleProductFormulaSave = this.handleProductFormulaSave.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.handleReportPreview = this.handleReportPreview.bind(this);
    this.handleFetchOrders = this.handleFetchOrders.bind(this);
    this.handlePrintBom = this.handlePrintBom.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.generateCsvData = this.generateCsvData.bind(this);
    this.handleCalcFormulaChanged = this.handleCalcFormulaChanged.bind(this);
    this.handleCustomerInfoUpdate = this.handleCustomerInfoUpdate.bind(this);

    const urlParams = new URL(window.location.href).searchParams;

    this.defaultState = {
      step: 1,
      searchText: urlParams.get('search') || '',
      labels: [],
      codeError: null,
      notifyCustomer: true,
      notifyCustomerText: props.notify_text,
      searchedOrder: null,
      selectedOrder: null,
      currentProductType: '',
      currentProductName: '',
      orderProductTypes: [],
      labelsReadyForPrint: false,
      currentTab: urlParams.get('tab') || 'pending',
      publishDisabled: true,
      customerInfo: null,
      lastSortKey: urlParams.get('order') || 'created_at desc',
      saveClicked: false,
      textureIngredient1: null,
      textureIngredient2: null,
      scalpIngredient1: null,
      scalpIngredient2: null,
      invertPDFLabelsColor: true,
      formulaChanged: false,
      orders: props.orders,
      shampoo: {
        product_type: 'shampoo',
        baseCocktail: props.formulaCocktails.filter((f) => f.formula_type === 'formula_base')[0].id,
        treatmentCocktail: props.formulaCocktails.filter((f) => f.formula_type === 'formula_treatment')[0].id,
        environmentCocktail: props.formulaCocktails.filter((f) => f.formula_type === 'formula_environment')[0].id,
        baseFraction: 90,
        treatmentFraction: 5,
        environmentFraction: 5,
        finalInciList: '',
        ingredients: [],
        inci: [],
      },
      conditioner: {
        product_type: 'conditioner',
        baseCocktail: props.formulaCocktails.filter((f) => f.formula_type === 'formula_base')[0].id,
        treatmentCocktail: props.formulaCocktails.filter((f) => f.formula_type === 'formula_treatment')[0].id,
        environmentCocktail: props.formulaCocktails.filter((f) => f.formula_type === 'formula_environment')[0].id,
        baseFraction: 90,
        treatmentFraction: 5,
        environmentFraction: 5,
        finalInciList: '',
        ingredients: [],
        inci: [],
      },
      pagination: {
        published: {
          currentPage: urlParams.get('tab') === 'published' && urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1,
          isLastPage: urlParams.get('tab') === 'published' && urlParams.get('page') ? props.isLastPage : null,
        },
      },
    };
    this.state = this.defaultState;
  }

  componentDidMount() {
    window.addEventListener('popstate', this.setUrlStep);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.setUrlStep);
  }

  setUrlStep() {
    const newStep = Number(new URL(window.location.href).searchParams.get('step')) || 1;
    this.setState({ step: newStep });
  }

  // Sort
  handleSort(sortKey) {
    const { lastSortKey } = this.state;
    const order = `${sortKey}${lastSortKey === sortKey ? ' desc' : ''}`;

    this.handleFetchOrders(null, null, order);
  }

  labelData(customer) {
    const name = `${jsHumanize(customer.first_name[0])}. ${jsHumanize(customer.last_name)}`;
    return `<div>${name.substr(0, 12)}</div><div>${formatDate(new Date())}</div>`;
  }

  // input change
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  // Step change
  handleStepChange(val = 1) {
    const { step } = this.state;
    window.history.pushState({}, null, `?step=${step + val}`);

    this.setState((prevState) => ({
      step: prevState.step + val,
    }));
  }

  // Tabs
  handleTabChange(type, mainTabs = false) {
    if (mainTabs) {
      const { pagination } = this.state;
      this.setState({
        codeError: null,
        searchedOrder: null,
      }, () => $('input#code').val(''));
      const page = pagination[type] && pagination[type].currentPage ? pagination[type].currentPage : 1;
      return this.handleFetchOrders(page, type);
    }

    const productName = type === 'shampoo' ? 'Shampoo' : 'Conditioner';
    return this.setState({ currentProductType: type, currentProductName: productName }, this.handleCalcFormulaChanged);
  }

  // events
  callConfirmationModal(question, additionalData) {
    callEvent(DISPLAY_CONFIRMATION_MODAL, {
      handleSubmit: this.handlePublish,
      question,
      additionalData,
    });
  }

  // code
  verifyCode(e) {
    const { currentTab } = this.state;
    const code = e.target.value;
    const error = codeErrors(code, ['-SO', '-CO']);
    if (error) return this.setState({ codeError: error });

    return api.ordersFulfillment.fulfillmentCodeVerification({
      code,
      tab: currentTab,
      postfix_array: ['-SO', '-CO'],
      queue: true,
    })
      .then((res) => this.setState({
        codeError: res.err,
        searchedOrder: res.order,
      }));
  }

  // Step 1 - Order select
  handleOrderSelect(order) {
    const orderProductTypes = order.items.map((o) => GetProductType(o));
    const newState = {
      selectedOrder: order,
      orderProductTypes,
      textureIngredient1: order.texture_ingredients[0],
      textureIngredient2: order.texture_ingredients[1],
      scalpIngredient1: order.scalp_ingredients[0],
      scalpIngredient2: order.scalp_ingredients[1],
    };

    api.productFormulas.customerInfo({ customer_id: order.customer_id, order_id: order.id })
      .then((res) => {
        newState.customerInfo = res;
        if (order.product_formulas.length === 0) {
          newState.shampoo = {
            baseCocktail: res.recommended_cocktails.base_cocktails.shampoo,
            treatmentCocktail: res.recommended_cocktails.treatment_cocktails.shampoo,
            environmentCocktail: res.recommended_cocktails.environment_cocktails.shampoo,
            baseFraction: 90,
            treatmentFraction: 5,
            environmentFraction: 5,
            finalInciList: '',
            ingredients: [],
            inci: [],
          };
          newState.conditioner = {
            baseCocktail: res.recommended_cocktails.base_cocktails.conditioner,
            treatmentCocktail: res.recommended_cocktails.treatment_cocktails.conditioner,
            environmentCocktail: res.recommended_cocktails.environment_cocktails.conditioner,
            baseFraction: 90,
            treatmentFraction: 5,
            environmentFraction: 5,
            finalInciList: '',
            ingredients: [],
            inci: [],
          };
        } else {
          const orderShampooFormula = order.product_formulas.find((f) => f.product_type === 'shampoo');
          const orderConditionerFormula = order.product_formulas.find((f) => f.product_type === 'conditioner');
          newState.publishDisabled = false;

          if (orderProductTypes.includes('shampoo')) {
            newState.shampoo = {
              baseCocktail: orderShampooFormula.base_cocktail_id,
              treatmentCocktail: orderShampooFormula.treatment_cocktail_id,
              environmentCocktail: orderShampooFormula.environment_cocktail_id,
              baseFraction: orderShampooFormula.base_fraction,
              treatmentFraction: orderShampooFormula.treatment_fraction,
              environmentFraction: orderShampooFormula.environment_fraction,
              finalInciList: orderShampooFormula.final_inci_list,
              ingredients: res.shampoo_ingredients,
              inci: [],
            };
          }

          if (orderProductTypes.includes('conditioner')) {
            newState.conditioner = {
              baseCocktail: orderConditionerFormula.base_cocktail_id,
              treatmentCocktail: orderConditionerFormula.treatment_cocktail_id,
              environmentCocktail: orderConditionerFormula.environment_cocktail_id,
              baseFraction: orderConditionerFormula.base_fraction,
              treatmentFraction: orderConditionerFormula.treatment_fraction,
              environmentFraction: orderConditionerFormula.environment_fraction,
              finalInciList: orderConditionerFormula.final_inci_list,
              ingredients: res.conditioner_ingredients,
              inci: [],
            };
          }
        }
        this.setState(newState, this.fetchLabels);
      });
  }

  // Formula changed
  handleCalcFormulaChanged() {
    const {
      customerInfo, currentProductType, shampoo, conditioner,
    } = this.state;
    const lastCocktails = customerInfo.recommended_cocktails.last_cocktails;
    const newCocktails = { shampoo, conditioner };

    if (!lastCocktails[currentProductType]) return this.setState({ formulaChanged: false });
    const currentCocktails = newCocktails[currentProductType];
    const currentLastCocktails = lastCocktails[currentProductType];
    const formulaChanged = currentCocktails.baseCocktail !== currentLastCocktails.base || currentCocktails.treatmentCocktail !== currentLastCocktails.treatment || currentCocktails.environmentCocktail !== currentLastCocktails.environment;

    return this.setState({ formulaChanged });
  }

  // Step 2 - Print
  onLabelContainerMount() {
    this.setState({ labelsReadyForPrint: true });
  }

  // Step 2 - Fetch Labels
  fetchLabels() {
    const { selectedOrder } = this.state;
    this.handleTabChange(selectedOrder.shampoo_qty > 0 ? 'shampoo' : 'conditioner');

    api.print.allLabels({
      code: selectedOrder.code,
      label_type: 'order',
      postfix_array: ['SO', 'CO'],
    })
      .then((res) => this.setState({ labels: res.labels }, () => this.handleStepChange()));
  }

  // Fetch Ingredients and INCI
  handleFetchIngredients(formula, name) {
    const { customerInfo, shampoo, conditioner } = this.state;

    const data = {
      ids: [formula.baseCocktail, formula.treatmentCocktail, formula.environmentCocktail],
      all_ids: [shampoo.baseCocktail, shampoo.treatmentCocktail, shampoo.environmentCocktail, conditioner.baseCocktail, conditioner.treatmentCocktail, conditioner.environmentCocktail],
      base_fraction: formula.baseFraction,
      treatment_fraction: formula.treatmentFraction,
      environment_fraction: formula.environmentFraction,
      customer_id: customerInfo.customer.id,
    };

    api.productFormulas.fetchIngredients(data)
      .then((res) => this.setState({
        [name]: {
          ...formula,
          ingredients: res.ingredients,
          inci: res.inci,
          finalInciList: res.inci.map((i) => i.name).join(', '),
        },
        scalpIngredient1: res.recommended_ingredients.scalp1,
        scalpIngredient2: res.recommended_ingredients.scalp2,
        textureIngredient1: res.recommended_ingredients.texture1,
        textureIngredient2: res.recommended_ingredients.texture2,
      }));
  }

  handleCalculateFormulation(formula, name) {
    const { customerInfo } = this.state;

    this.setState({
      [name]: {
        ...formula,
        baseCocktail: customerInfo.recommended_cocktails.base_cocktails[name],
        treatmentCocktail: customerInfo.recommended_cocktails.treatment_cocktails[name],
        environmentCocktail: customerInfo.recommended_cocktails.environment_cocktails[name],
        finalInciList: '',
        ingredients: [],
        inci: [],
      },
    });
  }

  getUniqueObjectsFromArray(arr, comp) {
    return arr.map((e) => e[comp]).map((e, i, final) => final.indexOf(e) === i && i).filter((e) => arr[e]).map((e) => arr[e]);
  }

  // Submit
  handleProductFormulaSave() {
    const {
      selectedOrder, orderProductTypes, shampoo, conditioner,
      textureIngredient1, textureIngredient2, scalpIngredient1, scalpIngredient2,
    } = this.state;
    if (!($('#texture_ingredient1').val() && $('#texture_ingredient2').val() && $('#scalp_ingredient1').val() && $('#scalp_ingredient2').val())) return alert('Texture/Scalp Ingredients are required!');

    const data = {
      order_id: selectedOrder.id,
      texture_ingredients: [textureIngredient1 || $('#texture_ingredient1').val(), textureIngredient2 || $('#texture_ingredient2').val()],
      scalp_ingredients: [scalpIngredient1 || $('#scalp_ingredient1').val(), scalpIngredient2 || $('#scalp_ingredient2').val()],
    };

    if (orderProductTypes.includes('shampoo')) {
      data.shampoo = {
        product_type: 'shampoo',
        base_cocktail_id: shampoo.baseCocktail,
        treatment_cocktail_id: shampoo.treatmentCocktail,
        environment_cocktail_id: shampoo.environmentCocktail,
        base_fraction: shampoo.baseFraction,
        treatment_fraction: shampoo.treatmentFraction,
        environment_fraction: shampoo.environmentFraction,
        final_inci_list: shampoo.finalInciList,
      };
    }
    if (orderProductTypes.includes('conditioner')) {
      data.conditioner = {
        product_type: 'conditioner',
        base_cocktail_id: conditioner.baseCocktail,
        treatment_cocktail_id: conditioner.treatmentCocktail,
        environment_cocktail_id: conditioner.environmentCocktail,
        base_fraction: conditioner.baseFraction,
        treatment_fraction: conditioner.treatmentFraction,
        environment_fraction: conditioner.environmentFraction,
        final_inci_list: conditioner.finalInciList,
      };
    }

    const submitFunc = selectedOrder.product_formulas.length > 0 ? api.productFormulas.save : api.productFormulas.create;

    return submitFunc(data)
      .then(() => this.setState({
        publishDisabled: false,
        saveClicked: true,
      }, () => alert('The record saved successfully')));
  }

  handlePublish() {
    const { selectedOrder, notifyCustomer, notifyCustomerText } = this.state;

    api.productFormulas.publish({ order_id: selectedOrder.id, notify_customer: notifyCustomer, notify_customer_text: notifyCustomerText })
      .then(this.handleSuccess);
  }

  handleSuccess() {
    successToast('Success');
    window.scrollTo(0, 0);
    this.setState(this.defaultState, this.handleFetchOrders);
  }

  handleReportPreview() {
    const { selectedOrder } = this.state;

    api.productFormulas.reportPreview({ order_id: selectedOrder.id })
      .then((res) => window.open(res.redirect_url, '_blank').focus());
  }

  // INCI table
  renderINCIs = (INCIs) => (
    <div className="form-group mt-5 mb-5">
      <table className="table table-striped table-responsive-sm custom-table">
        <thead>
          <tr>
            <th scope="col">INCI name</th>
            <th scope="col">Effective Concentration, %</th>
          </tr>
        </thead>
        <tbody>
          {INCIs.map((item) => (
            <tr key={item.name}>
              <th scope="row">{item.name}</th>
              <td>{item.effective_concentration < 0.1 ? '<0.1' : item.effective_concentration}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  renderCocktailInputs = (productType, formula, allCocktails, cocktailType, cocktailName, currentTab) => (
    <div className="form-group cocktail-inputs" style={{ display: 'flex' }}>
      <div style={{ width: '70%', marginRight: '2rem' }}>
        <label htmlFor={`${cocktailType}_cocktail`}>
          {`${cocktailName} Cocktail`}
        </label>
        <select
          id={`${cocktailType}_cocktail`}
          name={`${cocktailType}_cocktail`}
          value={formula[`${cocktailType}Cocktail`]}
          className="form-control"
          disabled={currentTab !== 'pending'}
          onChange={(e) => {
            const newFormula = { ...formula, [`${cocktailType}Cocktail`]: parseFloat(e.target.value) };
            this.setState({
              [productType]: newFormula,
            }, () => {
              this.handleFetchIngredients(newFormula, productType);
              this.handleCalcFormulaChanged();
            });
          }}
        >
          {allCocktails
            .filter((f) => f.formula_type === `formula_${cocktailType}`)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => (
              <option
                key={item.id}
                label={`${item.name} (#${item.id})`}
                value={item.id}
              >
                {item.id}
              </option>
            ))}
        </select>
      </div>
      <div style={{ width: '30%' }}>
        <label htmlFor={`${cocktailType}_fraction`}>
          {`${cocktailName} Fraction`}
        </label>
        <input
          required
          type="number"
          step="0.01"
          min="0"
          max="100"
          id={`${cocktailType}_fraction`}
          name={`${cocktailType}_fraction`}
          value={formula[`${cocktailType}Fraction`]}
          className="form-control"
          disabled={currentTab !== 'pending'}
          onChange={(e) => this.setState({
            [productType]: { ...formula, [`${cocktailType}Fraction`]: parseFloat(e.target.value) },
          })}
        />
      </div>
    </div>
  );

  handleFetchOrders(newPage, newTab, newOrder) {
    const {
      currentTab, pagination, lastSortKey, searchText,
    } = this.state;
    const tab = newTab || currentTab;
    const page = newPage || (pagination[currentTab] ? pagination[currentTab].currentPage : 1);
    const order = newOrder || lastSortKey;
    window.history.pushState({}, null, `?tab=${tab}&page=${page}&order=${order}&search=${searchText}`);

    api.ordersFulfillment.fetchProductQueueOrders(page, tab, order, searchText)
      .then((res) => this.setState({
        orders: res.orders,
        currentTab: tab,
        lastSortKey: order,
        pagination: {
          ...pagination,
          [tab]: {
            ...pagination[tab],
            currentPage: page,
            isLastPage: res.is_last_page,
          },
        },
      }));
  }

  handlePrintBom() {
    const { selectedOrder, customerInfo } = this.state;
    const fileName = `${selectedOrder.shopify_number}-${customerInfo.customer.first_name}-${customerInfo.customer.last_name}-BOM`;
    api.formulaCocktails.generateOrderBom({ order_id: selectedOrder.id }, fileName);
  }

  generateCsvData() {
    const { orders } = this.state;

    return [
      ['Order', 'Email', 'First Name', 'Last Name', 'Products', 'Type', 'Label Code', 'Created', 'Updated', 'Status Changed', 'Age'],
      ...orders.map((item) => [`#${item.shopify_number}`, (item.customer || {}).email, (item.customer || {}).first_name, (item.customer || {}).last_name, `${item.shampoo_qty} x SH $${(item.unit_costs || {}).shampoo}\n${item.conditioner_qty} x CO $${(item.unit_costs || {}).conditioner}`, item.order_type, item.code, new Date(item.created_at).toLocaleString(), new Date(item.updated_at).toLocaleString(), new Date(item.status_changed_at).toLocaleString(), item.age]),
    ];
  }

  handleCustomerInfoUpdate(customerInfo) {
    this.setState({ customerInfo });
  }

  render() {
    const { currentUser } = this.props;
    const {
      step, labelsReadyForPrint, selectedOrder, labels, searchText,
      orderProductTypes, currentProductType, currentProductName,
      currentTab, orders, publishDisabled, customerInfo, saveClicked,
      textureIngredient1, textureIngredient2, scalpIngredient1, scalpIngredient2,
      shampoo, conditioner, pagination, invertPDFLabelsColor, codeError, searchedOrder,
      notifyCustomer, notifyCustomerText, formulaChanged,
    } = this.state;
    const formula = this.state[currentProductType]; // eslint-disable-line react/destructuring-assignment
    const { formulaCocktails, archivedFormulaCocktails } = this.props;
    const shouldShowNextBtn = orderProductTypes.includes('shampoo') && orderProductTypes.includes('conditioner') && currentProductType === 'shampoo' && currentTab === 'pending';
    const parsedOrders = searchedOrder ? [searchedOrder, ...orders.filter((i) => i.id !== searchedOrder.id)] : orders;

    return (
      <div className="card products-queue-page">
        <div className="card-body custom-form-block">
          <div className="custom-info-block search-container">
            <div style={{ color: (customerInfo || {}).product_issues_count > 1 ? 'red' : 'inherit' }}>{selectedOrder ? `Products Queue (#${selectedOrder.shopify_number})${customerInfo.product_issues_count > 1 ? ': DO NOT FULFILL WITHOUT APPROVAL FROM MANAGEMENT' : ''}` : 'Products Queue'}</div>
            <form
              className={`search-block ${selectedOrder ? 'hidden' : ''}`}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleFetchOrders();
              }}
            >
              <input
                type="text"
                defaultValue={searchText}
                placeholder="Search..."
                id="search"
                onChange={this.handleChange('searchText')}
              />
              <button type="submit" className="btn btn-primary">Submit</button>
            </form>
          </div>
          {step === 1 && (
            <div>
              {/* Tabs */}
              <div className="custom-tabs">
                <div
                  id="tab-pending"
                  onClick={() => this.handleTabChange('pending', true)}
                  className={currentTab === 'pending' ? 'active' : ''}
                >
                  Pending
                </div>
                <div
                  id="tab-published"
                  onClick={() => this.handleTabChange('published', true)}
                  className={currentTab === 'published' ? 'active' : ''}
                >
                  Published
                </div>
              </div>
              <div>
                <div className="form-group input-medium mb-5">
                  <label htmlFor="code">Code</label>
                  <input
                    id="code"
                    name="code"
                    className="form-control"
                    onChange={this.verifyCode}
                  />
                  {codeError && (
                    <div className="input-error">{codeError}</div>
                  )}
                </div>
                <CSVLink className="csv-download" data={this.generateCsvData()}>Export as CSV</CSVLink>
                <table className="table table-striped table-responsive-sm custom-table">
                  <thead>
                    <tr>
                      <th scope="col" onClick={() => this.handleSort('shopify_number')}>ORDER</th>
                      <th scope="col" onClick={() => this.handleSort('customer_id')}>Customer</th>
                      <th scope="col">Products</th>
                      <th scope="col" onClick={() => this.handleSort('order_type')}>Type</th>
                      <th scope="col" onClick={() => this.handleSort('code')}>Label Code</th>
                      <th scope="col" onClick={() => this.handleSort('created_at')}>Created</th>
                      <th scope="col" onClick={() => this.handleSort('updated_at')}>Updated</th>
                      <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Status Changed</th>
                      <th scope="col" onClick={() => this.handleSort('status_changed_at')}>Age</th>
                      <th scope="col" onClick={() => this.handleSort('discount_code')}>Discount Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parsedOrders.map((item) => (
                      <tr
                        className={`custom-tr-selectable ${searchedOrder && item.id === searchedOrder.id ? 'highlighted' : ''}
                                   ${item.shampoo_qty > 1 || item.conditioner_qty > 1 ? 'green-highlight' : ''}
                                   ${item.customer_product_issues_count > 0 || isSGDiscountCode(item.discount_code) ? 'red-text-highlight' : ''}`}
                        key={item.id}
                        onClick={() => this.handleOrderSelect(item)}
                      >
                        <th scope="row"><ShopifyOrderLink shopifyId={item.shopify_id}>{`#${item.shopify_number}`}</ShopifyOrderLink></th>
                        <td>{item.customer && `${item.customer.first_name} ${item.customer.last_name}\n ${item.customer.email}`}</td>
                        <td>{`${item.shampoo_qty} x SH $${item.unit_costs.shampoo}\n${item.conditioner_qty} x CO $${item.unit_costs.conditioner}`}</td>
                        <td>{item.order_type}</td>
                        <td>{item.code}</td>
                        <td>{new Date(item.created_at).toLocaleString()}</td>
                        <td>{new Date(item.updated_at).toLocaleString()}</td>
                        <td>{new Date(item.status_changed_at).toLocaleString()}</td>
                        <td>{item.age}</td>
                        <td>{item.discount_code}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {currentTab !== 'pending' && (
                <Pagination
                  currentPage={pagination[currentTab].currentPage}
                  isLastPage={pagination[currentTab].isLastPage}
                  handlePageChange={this.handleFetchOrders}
                />
              )}
            </div>
          )}
          {step === 2 && (currentProductType || '').length > 0 && (
            <div>
              {/* Hidden Labels */}
              <div className="custom-hidden">
                <LabelContainer
                  onMount={this.onLabelContainerMount}
                  ref={(el) => { this.labelsContainer = el; }}
                />
                {labelsReadyForPrint && (
                  <ReactToPrint
                    trigger={() => (
                      <button
                        type="button"
                        className="btn btn-secondary"
                        id="print-trigger"
                      />
                    )}
                    content={() => this.labelsContainer}
                    onAfterPrint={() => $('style#barcodePrint').text('')}
                  />
                )}
              </div>

              {/* Formula changed */}
              {customerInfo && formulaChanged && <div className="form-group" style={{ color: 'red', fontSize: '22px' }}>Formula has changed since the last order</div>}

              {/* Customer Info */}
              {customerInfo && <CustomerInfoSection currentUser={currentUser} customerInfo={customerInfo} shouldRenderFormulationNotes shouldRenderRecommendedCocktails shouldRenderCurrentQuestionnaire shouldRenderProductIssues handleAfterCustomerInfoUpdate={this.handleCustomerInfoUpdate} />}

              <div className="form-group mt-5">
                <div className="custom-2-blocks-row custom-2-blocks-row-header">
                  <div>
                    Scalp Ingredient 1
                  </div>
                  <div>
                    Scalp Ingredient 2
                  </div>
                </div>
                <div className="custom-2-blocks-row">
                  <select
                    id="scalp_ingredient1"
                    name="scalp_ingredient1"
                    value={scalpIngredient1}
                    className="form-control"
                    disabled={currentTab !== 'pending'}
                    onChange={(e) => this.setState({ scalpIngredient1: e.target.value })}
                  >
                    {this.getUniqueObjectsFromArray([...shampoo.ingredients, ...conditioner.ingredients], 'id').filter((i) => i.for_scalp === true).map((item) => (
                      <option
                        key={item.id}
                        label={`${item.name} (#${item.id})`}
                        value={item.id}
                      >
                        {item.id}
                      </option>
                    ))}
                  </select>
                  <select
                    id="scalp_ingredient2"
                    name="scalp_ingredient2"
                    value={scalpIngredient2}
                    className="form-control"
                    disabled={currentTab !== 'pending'}
                    onChange={(e) => this.setState({ scalpIngredient2: e.target.value })}
                  >
                    {this.getUniqueObjectsFromArray([...shampoo.ingredients, ...conditioner.ingredients], 'id').filter((i) => i.for_scalp === true).map((item) => (
                      <option
                        key={item.id}
                        label={`${item.name} (#${item.id})`}
                        value={item.id}
                      >
                        {item.id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <div className="custom-2-blocks-row custom-2-blocks-row-header">
                  <div>
                    Texture Ingredient 1
                  </div>
                  <div>
                    Texture Ingredient 2
                  </div>
                </div>
                <div className="custom-2-blocks-row">
                  <select
                    id="texture_ingredient1"
                    name="texture_ingredient1"
                    value={textureIngredient1}
                    className="form-control"
                    disabled={currentTab !== 'pending'}
                    onChange={(e) => this.setState({ textureIngredient1: e.target.value })}
                  >
                    {this.getUniqueObjectsFromArray([...shampoo.ingredients, ...conditioner.ingredients], 'id').filter((i) => i.for_texture === true).map((item) => (
                      <option
                        key={item.id}
                        label={`${item.name} (#${item.id})`}
                        value={item.id}
                      >
                        {item.id}
                      </option>
                    ))}
                  </select>
                  <select
                    id="texture_ingredient2"
                    name="texture_ingredient2"
                    value={textureIngredient2}
                    className="form-control"
                    disabled={currentTab !== 'pending'}
                    onChange={(e) => this.setState({ textureIngredient2: e.target.value })}
                  >
                    {this.getUniqueObjectsFromArray([...shampoo.ingredients, ...conditioner.ingredients], 'id').filter((i) => i.for_texture === true).map((item) => (
                      <option
                        key={item.id}
                        label={`${item.name} (#${item.id})`}
                        value={item.id}
                      >
                        {item.id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Invert */}
              <div className="form-group form-check form-check-ml invert-pdf-row">
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="invertPDFLabelsColor"
                    id="invertPDFLabelsColor"
                    checked={invertPDFLabelsColor}
                    onChange={() => this.setState((prevState) => ({ invertPDFLabelsColor: !prevState.invertPDFLabelsColor }))}
                  />
                  <label className="form-check-label" htmlFor="invertPDFLabelsColor">
                    Invert PDF Labels Color
                  </label>
                </div>
                <div>
                  <a
                    className="btn btn-secondary"
                    href={`/orders/pdf_label?id=${selectedOrder.id}&invert&type=combined`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Combined PDF labels
                  </a>
                </div>
              </div>

              {/* Tabs */}
              <div className="custom-tabs mt-5">
                {orderProductTypes.includes('shampoo') && (
                  <div
                    id="tab-formula-shampoo"
                    onClick={() => this.handleTabChange('shampoo')}
                    className={currentProductType === 'shampoo' ? 'active' : ''}
                  >
                    Shampoo
                  </div>
                )}
                {orderProductTypes.includes('conditioner') && (
                  <div
                    id="tab-formula-conditioner"
                    onClick={() => this.handleTabChange('conditioner')}
                    className={currentProductType === 'conditioner' ? 'active' : ''}
                  >
                    Conditioner
                  </div>
                )}
              </div>

              {/* Label Buttons */}
              <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {labels.filter((l) => l.name.toLowerCase().includes(currentProductType)).map((label) => (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleBeforePrint(label, label.code, true, this.labelData(customerInfo.customer))}
                  >
                    {label.name}
                  </button>
                ))}
                <a
                  className="btn btn-secondary"
                  href={`/orders/pdf_label?id=${selectedOrder.id}&type=${currentProductType}${invertPDFLabelsColor ? '&invert' : ''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${currentProductName} Bottle Label PDF`}
                </a>
              </div>

              {/* ProductFormula -> create */}
              {orderProductTypes.includes(currentProductType) && (
                <form
                  id={`${currentProductType}-formula`}
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.handleProductFormulaSave();
                  }}
                >
                  <div>
                    <div className="form-group row" style={{ alignItems: 'baseline' }}>
                      <h2 className="form-group mt-5">{`${currentProductName === 'Shampoo' ? selectedOrder.shampoo_qty : selectedOrder.conditioner_qty} x ${currentProductName} Formula:`}</h2>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={currentTab !== 'pending'}
                        onClick={() => this.handleCalculateFormulation(formula, currentProductType)}
                      >
                        Reset to Recommended
                      </button>
                    </div>
                    {/* Cocktail Inputs */}
                    { this.renderCocktailInputs(currentProductType, formula, currentTab === 'pending' ? formulaCocktails : formulaCocktails.concat(archivedFormulaCocktails), 'base', 'Base', currentTab) }
                    { this.renderCocktailInputs(currentProductType, formula, currentTab === 'pending' ? formulaCocktails : formulaCocktails.concat(archivedFormulaCocktails), 'treatment', 'Treatment', currentTab) }
                    { this.renderCocktailInputs(currentProductType, formula, currentTab === 'pending' ? formulaCocktails : formulaCocktails.concat(archivedFormulaCocktails), 'environment', 'Environment', currentTab) }

                    <div className="form-group">
                      <label htmlFor="final_inci_list">
                        Final INCI List
                      </label>
                      <input
                        required
                        type="text"
                        value={formula.finalInciList}
                        name="final_inci_list"
                        className="form-control"
                        id="final_inci_list"
                        disabled={currentTab !== 'pending'}
                        onChange={(e) => this.setState({
                          [currentProductType]: { ...formula, finalInciList: e.target.value },
                        })}
                      />
                    </div>
                    <div className="form-group text-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={currentTab !== 'pending'}
                        onClick={() => this.handleFetchIngredients(formula, currentProductType)}
                      >
                        Update Ingredients & INCI
                      </button>
                    </div>
                    {formula.inci.length > 0 && this.renderINCIs(formula.inci)}
                    {formula.ingredients.length > 0 && (
                      <IngredientsTable ingredients={formula.ingredients} />
                    )}
                  </div>
                  {currentTab === 'pending' && (
                    <div className="btn-group custom-btn-continue notify-customer">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          name="notifyCustomer"
                          id="notifyCustomer"
                          checked={notifyCustomer}
                          onChange={() => this.setState({ notifyCustomer: !notifyCustomer })}
                        />
                        <label className="form-check-label" htmlFor="notifyCustomer">
                          Notify Customer
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="notifyCustomerText"
                          disabled={!notifyCustomer}
                          value={notifyCustomerText}
                          onChange={this.handleChange('notifyCustomerText')}
                        />
                      </div>
                    </div>
                  )}
                  <div className="btn-group custom-btn-continue">
                    <button
                      type="button"
                      className="btn btn-secondary btn-back"
                      onClick={() => this.handleStepChange(-1)}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-back v2"
                      disabled={publishDisabled || ((!saveClicked || !customerInfo.env_data.uv) && currentTab === 'pending')}
                      onClick={() => this.callConfirmationModal(currentTab === 'pending' ? 'Are you sure you want to publish this product formula?' : 'Are you sure you want to hide this prodct formula?')}
                    >
                      {`${currentTab === 'pending' ? 'Publish' : 'Unpublish'}`}
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-back v2"
                      disabled={(publishDisabled || !saveClicked) && currentTab === 'pending'}
                      onClick={this.handleReportPreview}
                    >
                      Report Preview
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-back v2"
                      disabled={(publishDisabled || !saveClicked) && currentTab === 'pending'}
                      onClick={() => window.open(`/customers/report?id=${customerInfo.customer.id}&order_id=${selectedOrder.id}`, '_blank').focus()}
                    >
                      PDF Report
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-back v2"
                      disabled={(publishDisabled || !saveClicked) && currentTab === 'pending'}
                      onClick={() => window.open(`/customers/report_new?id=${customerInfo.customer.id}&order_id=${selectedOrder.id}`, '_blank').focus()}
                    >
                      PDF Report [NEW]
                    </button>
                    <button
                      type="button"
                      id="print-bom"
                      className="btn btn-primary btn-back v2"
                      disabled={(publishDisabled || !saveClicked) && currentTab === 'pending'}
                      onClick={this.handlePrintBom}
                    >
                      Print BOM
                    </button>
                    {shouldShowNextBtn && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.handleTabChange('conditioner')}
                      >
                        Next
                      </button>
                    )}
                    {!shouldShowNextBtn && (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={currentTab !== 'pending'}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProductsQueue.propTypes = {
  orders: PropTypes.instanceOf(Array),
  formulaCocktails: PropTypes.instanceOf(Array),
  archivedFormulaCocktails: PropTypes.instanceOf(Array),
  isLastPage: PropTypes.bool,
  notify_text: PropTypes.string,
  currentUser: PropTypes.shape({}).isRequired,
};

ProductsQueue.defaultProps = {
  orders: [],
  formulaCocktails: [],
  archivedFormulaCocktails: [],
  isLastPage: false,
  notify_text: '',
};

export default ProductsQueue;
